const standardRadius = 4;
const mediumRadius = 6;
const bigRadius = 8;

export const regions = [
  {
    id: 'hrodnenskaia-voblasts',
    region_name: 'Гродненская область',
    className: 'hrodnenskaya',
    points:
      '196.41 146 191.41 141 191.41 136 186.41 131 181.41 131 181.41 121 171.41 111 166.41 116 156.41 116 151.41 121 151.41 126 146.41 131 146.41 151 141.41 156 141.41 166 136.41 171 141.41 176 146.41 176 151.41 181 151.41 186 151.41 191 146.41 191 141.41 196 136.41 196 131.11 191 136.41 186 136.41 181 131.41 176 126.41 181 121.41 181 111.41 191 101.41 191 96.41 196 96.41 206 91.41 211 86.41 206 81.41 211 76.41 211 71.41 216 61.41 216 56.41 211 46.41 211 41.41 216 31.41 216 26.41 211 21.41 211 21.41 221 26.41 226 26.41 246 31.41 251 31.41 261 36.41 266 36.41 271 36.41 276 41.41 281 41.41 296 46.41 301 46.41 326 51.41 326 56.41 321 61.41 326 71.41 326 76.41 331 81.41 326 81.41 316 86.41 311 91.41 306 101.41 306 101.41 311 106.41 316 121.41 316 121.41 311 126.41 306 131.41 306 136.41 301 136.41 281 141.41 276 141.41 271 146.41 266 156.41 266 171.41 266 176.41 271 181.41 271 186.41 266 191.41 266 191.41 246 186.41 241 181.41 241 181.41 236 176.41 231 176.41 226 176.41 221 186.41 211 186.41 206 181.41 206 181.41 201 171.41 201 171.41 196 166.41 196 166.41 191 171.41 186 181.41 186 191.41 176 191.41 171 196.41 166 196.41 161 201.41 156 201.41 146 196.41 146',
  },
  {
    id: 'vitsebskaia-voblasts',
    region_name: 'Витебская область',
    className: 'vitebskaya',
    points:
      '456.41 151 456.41 141 446.41 141 441.41 136 436.41 136 431.41 131 431.41 126 436.41 121 436.41 116 441.41 116 441.41 111 446.41 106 446.41 96 441.41 91 436.41 86 436.41 76 441.41 71 441.41 51 436.41 51 431.41 56 426.41 51 426.41 46 416.41 36 411.41 36 406.41 31 381.41 31 376.41 36 366.41 36 361.41 41 356.41 41 351.41 36 351.41 31 356.41 26 356.41 21 351.41 16 341.41 16 336.41 11 331.41 11 321.41 21 316.41 21 316.41 16 311.41 11 311.41 6 301.41 6 296.41 6 291.41 11 286.41 6 286.41 1 276.41 1 266.41 11 266.41 16 261.41 21 256.41 21 251.41 26 251.41 36 246.41 36 236.41 36 231.41 31 221.41 31 206.41 46 196.41 46 196.41 56 191.41 61 191.41 71 186.41 76 191.41 81 206.41 81 211.41 86 206.41 86 201.41 91 201.41 96 181.41 96 176.41 101 176.41 106 171.41 111 181.41 121 186.41 116 221.41 116 226.41 111 231.41 111 231.41 116 236.41 121 241.41 121 246.41 126 246.41 131 251.41 131 256.41 136 256.41 141 261.41 146 286.41 146 296.41 156 301.41 151 301.41 146 311.41 146 311.41 151 316.41 156 321.41 156 326.41 151 356.41 151 356.41 171 351.41 176 351.41 186 356.41 186 366.41 176 381.41 176 386.41 181 391.41 181 391.41 176 401.41 176 406.41 181 416.41 181 421.41 176 421.41 171 426.41 166 431.41 171 436.41 171 441.41 166 451.41 166 456.41 161 451.41 161 451.41 156 456.41 151',
  },
  {
    id: 'mahiliouskaia-voblasts',
    region_name: 'Могилевская область',
    className: 'mahileuskaya',
    points:
      '536.41 256 526.41 246 526.41 236 521.41 231 516.41 231 511.41 226 496.41 226 491.41 226 486.41 221 491.41 216 491.41 201 486.41 201 481.41 196 476.41 196 466.41 186 461.41 186 461.41 181 461.41 166 456.41 161 451.41 166 441.41 166 436.41 171 431.41 171 426.41 166 421.41 171 421.41 176 416.41 181 406.41 181 401.41 176 391.41 176 391.41 181 386.41 181 381.41 176 366.41 176 356.41 186 356.41 201 351.41 206 351.41 221 356.41 226 356.41 236 346.41 236 341.41 241 336.41 241 331.41 246 326.41 246 326.41 251 321.41 246 311.41 246 301.41 256 296.41 256 296.41 266 301.41 271 296.41 276 281.41 276 281.41 281 286.41 286 295.91 286 301.41 291 306.41 291 306.41 301 301.41 306 301.41 311 296.41 316 296.41 321 291.41 326 296.41 331 321.41 331 321.41 326 326.41 321 326.41 316 336.41 316 341.41 311 356.41 311 361.41 306 371.41 306 366.41 301 366.41 291 371.41 291 376.41 286 376.41 281 371.41 276 376.41 271 381.41 271 386.41 271 391.41 276 396.41 276 396.41 281 406.41 281 416.41 271 421.41 271 426.41 276 446.41 276 446.41 286 451.41 291 466.41 291 471.41 286 481.41 286 486.41 286 496.41 296 511.41 296 521.41 286 541.41 266 541.41 256 536.41 256',
  },
  {
    id: 'homelskaia-voblasts',
    region_name: 'Гомельская область',
    className: 'homelskaya',
    points:
      '486.41 381 486.41 376 476.41 366 476.41 351 481.41 346 476.41 341 476.41 336 476.41 326 471.41 321 471.41 316 461.41 306 461.41 301 466.41 296 466.41 291 451.41 291 446.41 286 446.41 276 426.41 276 421.41 271 416.41 271 406.41 281 396.41 281 396.41 276 391.41 276 386.41 271 376.41 271 371.41 276 376.41 281 376.41 286 371.41 291 366.41 291 366.41 301 371.41 306 361.41 306 356.41 311 351.41 311 341.41 311 336.41 316 326.41 316 326.41 321 321.41 326 321.41 331 301.41 331 301.41 336 296.41 341 296.41 351 291.41 356 281.41 356 276.41 351 271.41 351 271.41 356 256.41 356 251.41 351 251.41 346 246.41 346 236.41 356 236.41 361 231.41 366 236.41 371 241.41 371 246.41 376 246.41 391 251.41 396 251.41 401 246.41 406 246.41 416 246.41 436 256.41 436 256.41 446 261.41 446 266.41 441 276.41 441 286.41 431 291.41 431 291.41 436 296.41 441 301.41 441 311.41 441 311.41 451 316.41 456 321.41 456 321.41 446 326.41 441 331.41 441 336.41 436 336.41 431 341.41 431 341.41 441 346.41 446 346.41 456 356.41 456 366.41 446 371.41 446 376.41 451 386.41 451 391.41 446 396.41 446 406.41 456 406.41 461 416.41 471 421.41 466 421.41 446 416.41 441 416.41 436 421.41 431 421.41 426 426.41 421 426.41 411 441.41 396 441.41 391 451.41 391 456.41 396 461.41 396 461.41 391 466.41 386 476.41 386 481.41 391 491.41 391 491.41 386 486.41 381',
  },
  {
    id: 'minskaia-voblasts',
    region_name: 'Минская область',
    className: 'minskaya',
    points:
      '356.41 171 356.41 151 326.41 151 321.41 156 316.41 156 311.41 151 311.41 146 301.41 146 301.41 151 296.41 156 286.41 146 271.41 146 261.41 146 256.41 141 256.41 136 251.41 131 246.41 131 246.41 126 241.41 121 236.41 121 231.41 116 231.41 111 226.41 111 221.41 116 186.41 116 181.41 121 181.41 131 186.41 131 191.41 136 191.41 141 196.41 146 201.41 146 201.41 156 196.41 161 196.41 166 191.41 171 191.41 176 186.41 181 181.41 186 171.41 186 166.41 191 166.41 196 171.41 196 171.41 201 181.41 201 181.41 206 186.41 206 186.41 211 181.41 216 176.41 221 176.41 231 181.41 236 181.41 241 186.41 241 191.41 246 191.41 266 186.41 266 181.41 271 181.41 276 176.41 281 176.41 286 181.71 291 186.41 291 191.41 296 181.41 306 186.41 311 186.41 321 191.41 316 196.41 316 206.41 326 216.41 326 221.41 331 221.41 351 226.41 356 226.41 361 231.41 366 236.41 361 236.41 356 246.41 346 251.41 346 251.41 351 256.41 356 271.41 356 271.41 351 276.41 351 281.41 356 291.41 356 296.41 351 296.41 341 301.41 336 301.41 331 296.41 331 291.41 326 296.41 321 296.41 316 301.41 311 301.41 306 306.41 301 306.41 291 301.41 291 295.91 286 286.41 286 281.41 281 281.41 276 296.41 276 301.41 271 296.41 266 296.41 256 301.41 256 306.41 251 311.41 246 321.41 246 326.41 251 326.41 246 331.41 246 336.41 241 341.41 241 346.41 236 356.41 236 356.41 226 351.41 221 351.41 211 351.41 206 356.41 201 356.41 186 351.41 186 351.41 176 356.41 171',
  },
  {
    id: 'brestskaia-voblasts',
    region_name: 'Брестская область',
    className: 'brestskaya',
    points:
      '246.41 391 246.41 386 246.41 376 241.41 371 236.41 371 231.41 366 226.41 361 226.41 356 221.41 351 221.41 331 216.41 326 206.41 326 196.41 316 191.41 316 186.41 321 186.41 311 181.41 306 191.41 296 186.41 291 181.71 291 176.41 286 176.41 281 181.41 276 181.41 271 176.41 271 171.41 266 156.41 266 146.41 266 141.41 271 141.41 276 136.41 281 136.41 301 131.41 306 126.41 306 121.41 311 121.41 316 111.41 316 106.41 316 101.41 311 101.41 306 91.41 306 86.41 311 81.41 316 81.41 326 76.41 331 71.41 326 61.41 326 56.41 321 51.41 326 46.41 326 46.41 336 36.41 336 31.41 341 21.41 341 11.41 351 11.41 356 6.41 361 6.41 366 1.41 371 6.41 376 11.41 376 16.41 381 21.41 386 26.41 386 26.41 391 31.41 396 31.41 401 26.41 406 26.41 421 21.41 426 21.41 441 26.41 446 26.41 436 31.41 431 36.41 431 41.41 431 46.41 436 46.41 441 51.41 441 56.41 436 71.41 421 71.41 406 91.41 406 106.41 406 111.41 401 121.41 401 126.41 406 141.41 406 146.41 401 151.41 406 166.41 406 171.41 411 186.41 411 191.41 416 201.41 416 206.41 421 226.41 421 231.41 426 231.41 431 236.41 436 246.41 436 246.41 416 246.41 406 251.41 401 251.41 396 246.41 391',
  },
];

export const subRegions = [
  {
    id: 'hrodzenski-raion',
    region_name: 'Гродненский район',
    className: 'hrodnenskaya',
    points:
      '71.41 251 61.41 251 56.41 256 56.41 261 51.41 266 41.41 266 36.41 271 36.41 266 31.41 261 31.41 251 26.41 246 26.41 226 21.41 221 21.41 211 26.41 211 31.41 216 41.41 216 46.41 211 56.41 211 61.41 216 71.41 216 71.41 251',
  },
  {
    id: 'berastavitski-raion',
    region_name: 'Берестовицкий район',
    className: 'hrodnenskaya',
    points: '36.41 271 36.41 276 41.41 281 41.41 296 46.41 301 56.41 291 56.41 271 51.41 266 41.41 266 36.41 271',
  },
  {
    id: 'mastouski-raion',
    region_name: 'Мостовский район',
    className: 'hrodnenskaya',
    points:
      '56.41 271 61.41 271 66.41 266 71.41 266 81.41 276 81.41 281 96.41 281 101.41 276 106.41 281 111.41 276 106.41 271 106.41 261 101.41 256 96.41 261 86.41 251 81.41 256 76.41 256 71.41 251 61.41 251 56.41 256 56.41 261 51.41 266 56.41 271',
  },
  {
    id: 'vaukavyski-raion',
    region_name: 'Волковысский район',
    className: 'hrodnenskaya',
    points:
      '81.41 286 86.41 291 86.41 296 91.41 301 91.41 306 86.41 311 71.41 296 61.41 296 56.41 291 56.41 271 61.41 271 66.41 266 71.41 266 81.41 276 81.41 286',
  },
  {
    id: 'zelvenski-raion',
    region_name: 'Зельвенский район',
    className: 'hrodnenskaya',
    points:
      '111.41 276 116.41 281 111.41 286 116.41 291 111.41 296 106.41 296 101.41 301 101.41 306 91.41 306 91.41 301 86.41 296 86.41 291 81.41 286 81.41 281 96.41 281 101.41 276 106.41 281 111.41 276',
  },
  {
    id: 'svislatski-raion',
    region_name: 'Свислочский район',
    className: 'hrodnenskaya',
    points:
      '46.41 301 46.41 326 51.41 326 56.41 321 61.41 326 71.41 326 76.41 331 81.41 326 81.41 316 86.41 311 71.41 296 61.41 296 56.41 291 46.41 301',
  },
  {
    id: 'slonimski-raion',
    region_name: 'Слонимский район',
    className: 'hrodnenskaya',
    points:
      '101.41 306 101.41 311 106.41 316 121.41 316 121.41 311 126.41 306 131.41 306 136.41 301 136.41 281 126.41 281 121.41 276 116.41 281 111.41 286 116.41 291 111.41 296 106.41 296 101.41 301 101.41 306',
  },
  {
    id: 'dziatlauski-raion',
    region_name: 'Дятловский район',
    className: 'hrodnenskaya',
    points:
      '136.41 281 141.41 276 141.41 271 146.41 266 146.41 256 141.41 251 141.41 246 136.41 246 131.41 241 126.41 241 116.41 251 111.41 251 106.41 256 106.41 261 106.41 271 111.41 276 116.41 281 121.41 276 126.41 281 136.41 281',
  },
  {
    id: 'shchuchynski-raion',
    region_name: 'Щучинский район',
    className: 'hrodnenskaya',
    points:
      '111.41 251 111.41 246 106.41 241 106.41 236 101.41 231 101.41 226 96.41 226 91.41 221 91.41 211 86.41 206 81.41 211 76.41 211 71.41 216 71.41 251 76.41 256 81.41 256 86.41 251 96.41 261 101.41 256 106.41 261 106.41 256 111.41 251',
  },
  {
    id: 'voranauski-raion',
    region_name: 'Вороновский район',
    className: 'hrodnenskaya',
    points:
      '91.41 211 96.41 206 96.41 196 101.41 191 111.41 191 121.41 181 126.41 181 131.41 176 136.41 181 136.41 186 131.11 191 136.41 196 131.41 201 131.41 206 121.41 206 106.41 221 101.41 221 101.41 226 96.41 226 91.41 221 91.41 211',
  },
  {
    id: 'lidski-raion',
    region_name: 'Лидский район',
    className: 'hrodnenskaya',
    points:
      '131.41 241 136.41 236 136.41 226 141.41 221 141.41 216 131.41 206 121.41 206 106.41 221 101.41 221 101.41 226 101.41 231 106.41 236 106.41 241 111.41 246 111.41 251 116.41 251 126.41 241 131.41 241',
  },
  {
    id: 'navagrudski-raion',
    region_name: 'Новогрудский район',
    className: 'hrodnenskaya',
    points:
      '146.41 266 156.41 266 156.41 256 166.41 246 171.41 246 176.41 241 181.41 241 181.41 236 176.41 231 176.41 226 171.41 226 156.41 226 151.41 221 141.41 221 136.41 226 136.41 236 131.41 241 136.41 246 141.41 246 141.41 251 146.41 256 146.41 266',
  },
  {
    id: 'karelitski-raion',
    region_name: 'Кореличский район',
    className: 'hrodnenskaya',
    points:
      '156.41 266 171.41 266 176.41 271 181.41 271 186.41 266 191.41 266 191.41 246 186.41 241 181.41 241 176.41 241 171.41 246 166.41 246 156.41 256 156.41 266',
  },
  {
    id: 'iueuski-raion',
    region_name: 'Ивьевский район',
    className: 'hrodnenskaya',
    points:
      '136.41 196 141.41 196 146.41 191 151.41 191 151.41 186 156.41 186 161.41 191 166.41 191 166.41 196 171.41 196 171.41 201 181.41 201 181.41 206 186.41 206 186.41 211 176.41 221 176.41 226 156.41 226 151.41 221 141.41 221 141.41 216 131.41 206 131.41 201 136.41 196',
  },
  {
    id: 'ashmianski-raion',
    region_name: 'Ошмянский район',
    className: 'hrodnenskaya',
    points:
      '166.41 191 171.41 186 171.41 156 156.41 156 151.41 151 146.41 151 141.41 156 141.41 166 136.41 171 141.41 176 146.41 176 151.41 181 151.41 186 156.41 186 161.41 191 166.41 191',
  },
  {
    id: 'smargonski-raion',
    region_name: 'Сморгонский район',
    className: 'hrodnenskaya',
    points:
      '171.41 186 181.41 186 191.41 176 191.41 171 196.41 166 196.41 161 201.41 156 201.41 146 196.41 146 191.41 141 191.41 136 186.41 131 181.41 131 176.41 136 176.41 141 181.41 146 176.41 151 171.41 151 171.41 156 171.41 186',
  },
  {
    id: 'astravetski-raion',
    region_name: 'Островецкий район',
    className: 'hrodnenskaya',
    points:
      '181.41 131 181.41 121 171.41 111 166.41 116 156.41 116 151.41 121 151.41 126 146.41 131 146.41 151 151.41 151 156.41 156 171.41 156 171.41 151 176.41 151 181.41 146 176.41 141 176.41 136 181.41 131',
  },
  // vitebskaia-voblasts

  {
    id: 'pastauski-raion',
    region_name: 'Поставский район',
    className: 'vitebskaya',
    points:
      '171.41 111 176.41 106 176.41 101 181.41 96 201.41 96 201.41 91 206.41 86 236.41 86 241.41 91 241.41 101 246.41 106 246.41 111 231.41 111 226.41 111 221.41 116 186.41 116 181.41 121 171.41 111',
  },
  {
    id: 'braslauski-raion',
    region_name: 'Браславский район',
    className: 'vitebskaya',
    points:
      '211.41 86 206.41 81 191.41 81 186.41 76 191.41 71 191.41 61 196.41 56 196.41 46 206.41 46 221.41 31 231.41 31 236.41 36 246.41 36 246.41 41 236.41 51 236.41 61 241.41 66 236.41 71 226.41 71 221.41 76 221.41 86 211.41 86',
  },
  {
    id: 'sharkaushchynski-raion',
    region_name: 'Шарковщинский район',
    className: 'vitebskaya',
    points:
      '241.41 66 271.41 66 276.41 71 276.41 81 271.41 81 266.41 86 261.41 81 246.41 81 246.41 86 241.41 91 236.41 86 221.41 86 221.41 76 226.41 71 236.41 71 241.41 66',
  },
  {
    id: 'dokshytski-raion',
    region_name: 'Докшицкий район',
    className: 'vitebskaya',
    points:
      '231.41 111 231.41 116 236.41 121 241.41 121 246.41 126 246.41 131 251.41 131 256.41 136 256.41 141 261.41 146 286.41 146 286.41 136 291.41 131 296.41 131 296.41 121 281.41 106 251.41 106 246.41 111 231.41 111',
  },
  {
    id: 'hlybotski-raion',
    region_name: 'Глубокский район',
    className: 'vitebskaya',
    points:
      '286.41 111 286.41 106 291.41 101 291.41 86 296.41 81 291.41 76 286.41 81 276.41 81 271.41 81 266.41 86 261.41 81 246.41 81 246.41 86 241.41 91 241.41 101 246.41 106 246.41 111 251.41 106 281.41 106 286.41 111',
  },
  {
    id: 'miorski-raion',
    region_name: 'Миорский район',
    className: 'vitebskaya',
    points:
      '291.41 76 291.41 66 296.41 61 296.41 56 286.41 56 276.41 46 271.41 46 271.41 36 266.41 31 261.41 36 251.41 36 251.41 36 246.41 36 246.41 41 236.41 51 236.41 61 241.41 66 271.41 66 276.41 71 276.41 81 286.41 81 291.41 76',
  },
  {
    id: 'verhniadzvinski-raion',
    region_name: 'Верхнедвинский район',
    className: 'vitebskaya',
    points:
      '271.41 46 276.41 46 286.41 56 296.41 56 306.41 46 301.41 41 301.41 36 296.41 31 296.41 21 301.41 16 301.41 6 296.41 6 291.41 11 286.41 6 286.41 1 276.41 1 266.41 11 266.41 16 261.41 21 256.41 21 251.41 26 251.41 36 261.41 36 266.41 31 271.41 36 271.41 46',
  },
  {
    id: 'lepelski-raion',
    region_name: 'Лепельский район',
    className: 'vitebskaya',
    points:
      '286.41 146 296.41 156 301.41 151 301.41 146 311.41 146 316.41 141 321.41 141 326.41 136 331.41 136 331.41 131 326.41 126 336.41 116 336.41 106 326.41 106 321.41 106 316.41 111 306.41 111 296.41 121 296.41 131 291.41 131 286.41 136 286.41 146',
  },
  {
    id: 'chashnitski-raion',
    region_name: 'Чашникский район',
    className: 'vitebskaya',
    points:
      '311.41 146 311.41 151 316.41 156 321.41 156 326.41 151 356.41 151 361.41 146 361.41 141 356.41 136 356.41 126 351.41 121 351.41 116 336.41 116 326.41 126 331.41 131 331.41 136 326.41 136 321.41 141 316.41 141 311.41 146',
  },
  {
    id: 'talachynski-raion',
    region_name: 'Толочинский район',
    className: 'vitebskaya',
    points:
      '356.41 151 356.41 171 351.41 176 351.41 186 356.41 186 366.41 176 381.41 176 386.41 181 391.41 181 391.41 171 396.41 166 396.41 161 391.41 156 386.41 156 381.41 151 381.41 146 361.41 146 356.41 151',
  },
  {
    id: 'arshanski-raion',
    region_name: 'Оршанский район',
    className: 'vitebskaya',
    points:
      '391.41 176 401.41 176 406.41 181 416.41 181 421.41 176 421.41 171 426.41 166 426.41 161 416.41 151 421.41 146 421.41 141 426.41 136 426.41 131 416.41 131 406.41 131 401.41 136 401.41 141 396.41 146 381.41 146 381.41 151 386.41 156 391.41 156 396.41 161 396.41 166 391.41 171 391.41 176',
  },
  {
    id: 'dubrovenski-raion',
    region_name: 'Дубровенский район',
    className: 'vitebskaya',
    points:
      '426.41 166 431.41 171 436.41 171 441.41 166 451.41 166 456.41 161 451.41 161 451.41 156 456.41 151 456.41 141 446.41 141 441.41 136 436.41 136 431.41 131 426.41 131 426.41 136 421.41 141 421.41 146 416.41 151 426.41 161 426.41 166',
  },
  {
    id: 'sennenski-raion',
    region_name: 'Сенненский район',
    className: 'vitebskaya',
    points:
      '356.41 126 366.41 116 371.41 116 376.41 111 386.41 111 391.41 116 396.41 116 401.41 121 411.41 121 416.41 126 416.41 131 406.41 131 401.41 136 401.41 141 396.41 146 381.41 146 361.41 146 361.41 141 356.41 136 356.41 126',
  },
  {
    id: 'lioznenski-raion',
    region_name: 'Лиозненский район',
    className: 'vitebskaya',
    points:
      '431.41 131 431.41 126 436.41 121 436.41 116 441.41 116 441.41 111 446.41 106 446.41 96 441.41 91 431.41 91 421.41 101 416.41 101 411.41 106 411.41 121 416.41 126 416.41 131 431.41 131',
  },
  {
    id: 'vitsebski-raion',
    region_name: 'Витебский район',
    className: 'vitebskaya',
    points:
      '441.41 91 436.41 86 436.41 76 441.41 71 441.41 51 436.41 51 431.41 56 426.41 61 416.41 61 416.41 66 406.41 76 386.41 76 381.41 81 381.41 91 386.41 96 386.41 111 391.41 116 396.41 116 401.41 121 411.41 121 411.41 106 416.41 101 421.41 101 431.41 91 441.41 91',
  },
  {
    id: 'beshankovitski-raion',
    region_name: 'Бешенковичский район',
    className: 'vitebskaya',
    points:
      '381.41 91 376.41 91 371.41 96 371.41 101 361.41 101 356.41 106 356.41 101 351.41 101 346.41 96 346.41 91 341.41 86 341.41 101 336.41 106 336.41 116 351.41 116 351.41 121 356.41 126 366.41 116 371.41 116 376.41 111 386.41 111 386.41 96 381.41 91',
  },
  {
    id: 'shumilinski-raion',
    region_name: 'Шумилинский район',
    className: 'vitebskaya',
    points:
      '381.41 81 376.41 81 371.41 76 371.41 66 361.41 56 351.41 66 346.41 66 336.41 76 331.41 76 341.41 86 346.41 91 346.41 96 351.41 101 356.41 101 356.41 106 361.41 101 371.41 101 371.41 96 376.41 91 381.41 91 381.41 81',
  },
  {
    id: 'ushatski-raion',
    region_name: 'Ушачский район',
    className: 'vitebskaya',
    points:
      '331.41 76 321.41 86 306.41 86 301.41 91 291.41 91 291.41 101 286.41 106 286.41 111 296.41 121 306.41 111 316.41 111 321.41 106 336.41 106 341.41 101 341.41 86 331.41 76',
  },
  {
    id: 'polatski-raion',
    region_name: 'Полоцкий район',
    className: 'vitebskaya',
    points:
      '361.41 56 361.41 41 356.41 41 351.41 36 346.41 36 341.41 41 336.41 41 331.41 46 306.41 46 296.41 56 296.41 61 291.41 66 291.41 76 296.41 81 291.41 86 291.41 91 301.41 91 306.41 86 321.41 86 331.41 76 336.41 76 346.41 66 351.41 66 361.41 56',
  },
  {
    id: 'haradotski-raion',
    region_name: 'Городокский район',
    className: 'vitebskaya',
    points:
      '431.41 56 426.41 51 426.41 46 416.41 36 411.41 36 406.41 31 381.41 31 376.41 36 366.41 36 361.41 41 361.41 56 371.41 66 371.41 76 376.41 81 381.41 81 386.41 76 406.41 76 416.41 66 416.41 61 426.41 61 431.41 56',
  },
  {
    id: 'rasonski-raion',
    region_name: 'Россонский район',
    className: 'vitebskaya',
    points:
      '351.41 36 351.41 31 356.41 26 356.41 21 351.41 16 341.41 16 336.41 11 331.41 11 321.41 21 316.41 21 316.41 16 311.41 11 311.41 6 301.41 6 301.41 16 296.41 21 296.41 31 301.41 36 301.41 41 306.41 46 331.41 46 336.41 41 341.41 41 346.41 36 351.41 36',
  },

  // mahiliouskaia-voblasts

  {
    id: 'horatski-raion',
    region_name: 'Горецкий район',
    className: 'mahileuskaya',
    points:
      '456.41 161 461.41 166 461.41 181 461.41 186 456.41 191 446.41 191 441.41 196 436.41 196 431.41 191 426.41 186 421.41 186 416.41 181 421.41 176 421.41 171 426.41 166 431.41 171 436.41 171 441.41 166 451.41 166 456.41 161',
  },
  {
    id: 'drybinski-raion',
    region_name: 'Дрибинский район',
    className: 'mahileuskaya',
    points:
      '456.41 191 456.41 201 461.41 206 456.41 211 446.41 211 441.41 206 426.41 206 421.41 201 426.41 196 426.41 186 436.41 196 441.41 196 446.41 191 456.41 191',
  },
  {
    id: 'mstsislauski-raion',
    region_name: 'Мстиславский район',
    className: 'mahileuskaya',
    points:
      '456.41 211 461.41 216 461.41 226 466.41 226 471.41 221 486.41 221 491.41 216 491.41 201 486.41 201 481.41 196 476.41 196 466.41 186 461.41 186 456.41 191 456.41 201 461.41 206 456.41 211',
  },
  {
    id: 'shklouski-raion',
    region_name: 'Шкловский район',
    className: 'mahileuskaya',
    points:
      '391.41 181 391.41 186 386.41 191 386.41 196 391.41 201 401.41 201 406.41 206 416.41 206 421.41 201 426.41 196 426.41 186 421.41 186 416.41 181 406.41 181 401.41 176 391.41 176 391.41 181',
  },
  {
    id: 'kruhlianski-raion',
    region_name: 'Круглянский район',
    className: 'mahileuskaya',
    points:
      '386.41 196 371.41 196 366.41 201 356.41 201 356.41 186 366.41 176 381.41 176 386.41 181 391.41 181 391.41 186 386.41 191 386.41 196',
  },
  {
    id: 'bialynitski-raion',
    region_name: 'Белыничский район',
    className: 'mahileuskaya',
    points:
      '356.41 201 351.41 206 351.41 221 356.41 226 356.41 236 361.41 231 366.41 231 371.41 236 376.41 236 376.41 226 391.41 211 391.41 201 386.41 196 371.41 196 366.41 201 356.41 201',
  },
  {
    id: 'klichauski-raion',
    region_name: 'Кличевский район',
    className: 'mahileuskaya',
    points:
      '356.41 236 346.41 236 341.41 241 336.41 241 331.41 246 326.41 246 326.41 261 331.41 266 331.41 271 336.41 276 341.41 276 346.41 271 351.41 271 371.41 251 376.41 251 376.41 236 371.41 236 366.41 231 361.41 231 356.41 236',
  },
  {
    id: 'asipovitski-raion',
    region_name: 'Осиповичский район',
    className: 'mahileuskaya',
    points:
      '326.41 261 326.41 251 321.41 246 311.41 246 301.41 256 296.41 256 296.41 266 301.41 271 296.41 276 281.41 276 281.41 281 286.41 286 295.91 286 301.41 291 316.41 291 331.41 276 336.41 276 331.41 271 331.41 266 326.41 261',
  },
  {
    id: 'hluski-raion',
    region_name: 'Глусский район',
    className: 'mahileuskaya',
    points:
      '306.41 291 306.41 301 301.41 306 301.41 311 296.41 316 296.41 321 291.41 326 296.41 331 321.41 331 321.41 326 326.41 321 326.41 316 326.41 306 316.41 296 316.41 291 306.41 291',
  },
  {
    id: 'babruiski-raion',
    region_name: 'Бобруйский район',
    className: 'mahileuskaya',
    points:
      '326.41 316 336.41 316 341.41 311 356.41 311 361.41 306 371.41 306 366.41 301 366.41 291 361.41 286 346.41 286 336.41 276 331.41 276 316.41 291 316.41 296 326.41 306 326.41 316',
  },
  {
    id: 'kirauski-raion',
    region_name: 'Кировский район',
    className: 'mahileuskaya',
    points:
      '366.41 291 371.41 291 376.41 286 376.41 281 371.41 276 376.41 271 381.41 271 381.41 261 376.41 256 376.41 251 371.41 251 351.41 271 346.41 271 341.41 276 336.41 276 346.41 286 361.41 286 366.41 291',
  },
  {
    id: 'byhauski-raion',
    region_name: 'Быховский район',
    className: 'mahileuskaya',
    points:
      '381.41 271 386.41 271 391.41 276 396.41 276 396.41 281 406.41 281 416.41 271 421.41 271 421.41 261 426.41 256 426.41 246 421.41 241 401.41 241 396.41 236 376.41 236 376.41 256 381.41 261 381.41 271',
  },
  {
    id: 'mahiliouski-raion',
    region_name: 'Могилевский район',
    className: 'mahileuskaya',
    points:
      '421.41 241 421.41 236 416.41 231 421.41 226 421.41 221 426.41 216 431.41 211 431.41 206 426.41 206 421.41 201 416.41 206 406.41 206 401.41 201 391.41 201 391.41 211 376.41 226 376.41 236 396.41 236 401.41 241 421.41 241',
  },
  {
    id: 'chavuski-raion',
    region_name: 'Чаусский район',
    className: 'mahileuskaya',
    points:
      '461.41 226 456.41 226 451.41 231 451.41 236 446.41 241 441.41 241 436.41 246 431.41 246 426.41 251 426.41 246 421.41 241 421.41 236 416.41 231 421.41 226 421.41 221 431.41 211 431.41 206 441.41 206 446.41 211 456.41 211 461.41 216 461.41 226',
  },
  {
    id: 'cherykauski-raion',
    region_name: 'Червенский район',
    className: 'mahileuskaya',
    points:
      '451.41 236 451.41 251 456.41 256 456.41 261 476.41 261 481.41 256 481.41 246 471.41 236 466.41 236 461.41 231 461.41 226 456.41 226 451.41 231 451.41 236',
  },
  {
    id: 'krychauski-raion',
    region_name: 'Кричевский район',
    className: 'mahileuskaya',
    points:
      '481.41 246 486.41 241 491.41 241 496.41 236 496.41 226 491.41 226 486.41 221 471.41 221 466.41 226 461.41 226 461.41 231 466.41 236 471.41 236 481.41 246',
  },
  {
    id: 'klimavitski-raion',
    region_name: 'Климовичский район',
    className: 'mahileuskaya',
    points:
      '496.41 226 511.41 226 516.41 231 521.41 231 526.41 236 526.41 246 511.41 261 486.41 261 481.41 256 481.41 246 486.41 241 491.41 241 496.41 236 496.41 226',
  },
  {
    id: 'khotsimski-raion',
    region_name: 'Хотимский район',
    className: 'mahileuskaya',
    points:
      '526.41 246 536.41 256 541.41 256 541.41 266 521.41 286 516.41 281 516.41 276 511.41 271 511.41 261 526.41 246',
  },
  {
    id: 'kastsiukovitski-raion',
    region_name: 'Костюковичский район',
    className: 'mahileuskaya',
    points:
      '521.41 286 511.41 296 496.41 296 486.41 286 481.41 286 481.41 281 486.41 276 486.41 261 511.41 261 511.41 271 516.41 276 516.41 281 521.41 286',
  },
  {
    id: 'krasnapolski-raion',
    region_name: 'Краснопольский район',
    className: 'mahileuskaya',
    points:
      '481.41 286 471.41 286 466.41 291 451.41 291 446.41 286 446.41 276 456.41 266 456.41 261 476.41 261 481.41 256 486.41 261 486.41 276 481.41 281 481.41 286',
  },
  {
    id: 'slauharadski-raion',
    region_name: 'Славгородский район',
    className: 'mahileuskaya',
    points:
      '446.41 276 426.41 276 421.41 271 421.41 261 426.41 256 426.41 251 431.41 246 436.41 246 441.41 241 446.41 241 451.41 236 451.41 251 456.41 256 456.41 261 456.41 266 446.41 276',
  },

  //homelskaia-voblasts

  {
    id: 'karmianski-raion',
    region_name: 'Кормянский район',
    className: 'homelskaya',
    points:
      '451.41 291 446.41 296 441.41 296 431.41 306 426.41 306 421.41 301 416.41 296 416.41 291 426.41 281 426.41 276 446.41 276 446.41 286 451.41 291',
  },

  {
    id: 'chacherski-raion',
    region_name: 'Чечерский район',
    className: 'homelskaya',
    points:
      '466.41 291 466.41 296 461.41 301 461.41 306 456.41 311 456.41 316 451.41 316 441.41 326 441.41 331 436.41 331 431.41 326 431.41 321 416.41 306 421.41 301 426.41 306 431.41 306 441.41 296 446.41 296 451.41 291 466.41 291',
  },

  {
    id: 'rahachouski-raion',
    region_name: 'Рогачевский район',
    className: 'homelskaya',
    points:
      '416.41 306 411.41 306 411.41 316 406.41 311 401.41 311 396.41 306 371.41 306 366.41 301 366.41 291 371.41 291 376.41 286 376.41 281 371.41 276 376.41 271 386.41 271 391.41 276 396.41 276 396.41 281 406.41 281 416.41 271 421.41 271 426.41 276 426.41 281 416.41 291 416.41 296 421.41 301 416.41 306',
  },

  {
    id: 'zhlobinski-raion',
    region_name: 'Жлобинский район',
    className: 'homelskaya',
    points:
      '411.41 316 406.41 321 406.41 326 401.41 331 401.41 346 391.41 346 386.41 341 381.41 341 376.41 336 371.41 336 356.41 321 356.41 316 351.41 311 356.41 311 361.41 306 396.41 306 401.41 311 406.41 311 411.41 316',
  },

  {
    id: 'svetlahorski-raion',
    region_name: 'Светлогорский район',
    className: 'homelskaya',
    points:
      '386.41 341 381.41 346 381.41 361 376.41 356 366.41 356 361.41 361 356.41 361 351.41 356 351.41 351 346.41 346 341.41 346 341.41 341 346.41 336 341.41 331 341.41 326 336.41 321 336.41 316 341.41 311 351.41 311 356.41 316 356.41 321 371.41 336 376.41 336 381.41 341 386.41 341',
  },
  {
    id: 'aktsiabrski-raion',
    region_name: 'Октябрьский район',
    className: 'homelskaya',
    points:
      '341.41 346 336.41 351 326.41 351 321.41 346 316.41 346 311.41 351 296.41 351 296.41 341 301.41 336 301.41 331 321.41 331 321.41 326 326.41 321 326.41 316 336.41 316 336.41 321 341.41 326 341.41 331 346.41 336 341.41 341 341.41 346',
  },
  {
    id: 'petrykauski-raion',
    region_name: 'Петриковский район',
    className: 'homelskaya',
    points:
      '296.41 351 291.41 356 291.41 361 281.41 371 281.41 386 286.41 391 286.41 396 301.41 396 306.41 391 311.41 391 316.41 386 331.41 386 331.41 376 326.41 371 326.41 366 331.41 361 331.41 351 326.41 351 321.41 346 316.41 346 311.41 351 296.41 351',
  },
  {
    id: 'zhytkavitski-raion',
    region_name: 'Житковичский район',
    className: 'homelskaya',
    points:
      '291.41 356 281.41 356 276.41 351 271.41 351 271.41 356 256.41 356 251.41 351 251.41 346 246.41 346 236.41 356 236.41 361 231.41 366 236.41 371 241.41 371 246.41 376 246.41 391 251.41 396 251.41 401 246.41 406 246.41 416 256.41 406 261.41 406 266.41 401 276.41 401 281.41 396 286.41 396 286.41 391 281.41 386 281.41 371 291.41 361 291.41 356',
  },
  {
    id: 'lelchytski-raion',
    region_name: 'Лельчицкий район',
    className: 'homelskaya',
    points:
      '246.41 416 246.41 436 256.41 436 256.41 446 261.41 446 266.41 441 276.41 441 286.41 431 291.41 431 291.41 436 296.41 441 301.41 441 306.41 436 306.41 426 316.41 416 316.41 406 311.41 401 306.41 401 301.41 396 281.41 396 276.41 401 266.41 401 261.41 406 256.41 406 246.41 416',
  },
  {
    id: 'kalinkavitski-raion',
    region_name: 'Калинковичский район',
    className: 'homelskaya',
    points:
      '361.41 416 366.41 411 366.41 406 381.41 391 371.41 381 376.41 376 376.41 371 381.41 366 381.41 361 376.41 356 366.41 356 361.41 361 356.41 361 351.41 356 351.41 351 346.41 346 341.41 346 336.41 351 331.41 351 331.41 361 326.41 366 326.41 371 331.41 376 331.41 386 341.41 386 351.41 396 351.41 401 361.41 411 361.41 416',
  },
  {
    id: 'mazyrski-raion',
    region_name: 'Мозырский район',
    className: 'homelskaya',
    points:
      '316.41 416 321.41 416 326.41 411 346.41 411 351.41 411 356.41 416 361.41 416 361.41 411 351.41 401 351.41 396 341.41 386 331.41 386 316.41 386 311.41 391 306.41 391 301.41 396 306.41 401 311.41 401 316.41 406 316.41 416',
  },
  {
    id: 'elski-raion',
    region_name: 'Ельский район',
    className: 'homelskaya',
    points:
      '346.41 411 346.41 416 341.41 421 341.41 431 336.41 431 336.41 436 331.41 441 326.41 441 321.41 446 321.41 456 316.41 456 311.41 451 311.41 441 301.41 441 306.41 436 306.41 426 316.41 416 321.41 416 326.41 411 346.41 411',
  },
  {
    id: 'naraulianski-raion',
    region_name: 'Наровлянский район',
    className: 'homelskaya',
    points:
      '341.41 431 341.41 441 346.41 446 346.41 456 356.41 456 366.41 446 371.41 446 376.41 451 386.41 451 376.41 441 376.41 436 366.41 426 366.41 421 361.41 421 361.41 416 356.41 416 351.41 411 346.41 411 346.41 416 341.41 421 341.41 431',
  },
  {
    id: 'khoinitski-raion',
    region_name: 'Хойникский район',
    className: 'homelskaya',
    points:
      '386.41 451 391.41 446 396.41 446 396.41 441 391.41 436 391.41 431 386.41 426 391.41 421 391.41 416 396.41 411 396.41 406 406.41 396 406.41 391 401.41 391 396.41 386 396.41 381 391.41 381 381.41 391 366.41 406 366.41 411 361.41 416 361.41 421 366.41 421 366.41 426 376.41 436 376.41 441 386.41 451',
  },
  {
    id: 'brahinski-raion',
    region_name: 'Брагинский район',
    className: 'homelskaya',
    points:
      '396.41 446 406.41 456 406.41 461 416.41 471 421.41 466 421.41 446 416.41 441 416.41 436 421.41 431 421.41 426 416.41 421 416.41 416 411.41 411 411.41 401 406.41 396 396.41 406 396.41 411 391.41 416 391.41 421 386.41 426 391.41 431 391.41 436 396.41 441 396.41 446',
  },
  {
    id: 'rechytski-raion',
    region_name: 'Речицкий район',
    className: 'homelskaya',
    points:
      '406.41 391 416.41 391 426.41 381 421.41 376 426.41 371 426.41 366 416.41 356 416.41 351 406.41 351 401.41 346 391.41 346 386.41 341 381.41 346 381.41 366 376.41 371 376.41 376 371.41 381 381.41 391 391.41 381 396.41 381 396.41 386 401.41 391 406.41 391',
  },
  {
    id: 'loeuski-raion',
    region_name: 'Лоевский район',
    className: 'homelskaya',
    points:
      '421.41 426 426.41 421 426.41 411 441.41 396 441.41 391 436.41 391 426.41 381 416.41 391 406.41 391 406.41 396 411.41 401 411.41 411 416.41 416 416.41 421 421.41 426',
  },
  {
    id: 'buda-kashaliouski-raion',
    region_name: 'Буда-Кошелевский район',
    className: 'homelskaya',
    points:
      '416.41 351 421.41 351 426.41 346 436.41 346 441.41 341 441.41 331 436.41 331 431.41 326 431.41 321 416.41 306 411.41 306 411.41 316 406.41 321 406.41 326 401.41 331 401.41 346 406.41 351 416.41 351',
  },
  {
    id: 'homelski-raion',
    region_name: 'Гомельский район',
    className: 'homelskaya',
    points:
      '441.41 341 456.41 356 456.41 376 466.41 386 461.41 391 461.41 396 456.41 396 451.41 391 441.41 391 436.41 391 421.41 376 426.41 371 426.41 366 416.41 356 416.41 351 421.41 351 426.41 346 436.41 346 441.41 341',
  },
  {
    id: 'vetkauski-raion',
    region_name: 'Ветковский район',
    className: 'homelskaya',
    points:
      '456.41 356 456.41 351 461.41 346 466.41 346 476.41 336 476.41 326 471.41 321 471.41 316 461.41 306 456.41 311 456.41 316 451.41 316 441.41 326 441.41 331 441.41 341 456.41 356',
  },
  {
    id: 'dobrushski-raion',
    region_name: 'Добрушский район',
    className: 'homelskaya',
    points:
      '476.41 336 476.41 341 481.41 346 476.41 351 476.41 366 486.41 376 486.41 381 491.41 386 491.41 391 481.41 391 476.41 386 466.41 386 456.41 376 456.41 356 456.41 351 461.41 346 466.41 346 476.41 336',
  },

  //minskaia-voblasts

  {
    id: 'salihorski-raion',
    region_name: 'Солигорский район',
    className: 'minskaya',
    points:
      '231.41 366 226.41 361 226.41 356 221.41 351 221.41 331 216.41 326 221.41 321 226.41 321 226.41 311 246.41 311 251.41 316 256.41 316 261.41 321 261.41 331 266.41 336 266.41 341 271.41 346 271.41 351 271.41 356 256.41 356 251.41 351 251.41 346 246.41 346 236.41 356 236.41 361 231.41 366',
  },
  {
    id: 'liubanski-raion',
    region_name: 'Любанский район',
    className: 'minskaya',
    points:
      '256.41 316 266.41 306 271.41 306 276.41 311 281.41 311 286.41 316 291.41 316 296.41 321 291.41 326 296.41 331 301.41 331 301.41 336 296.41 341 296.41 351 291.41 356 281.41 356 276.41 351 271.41 351 271.41 346 266.41 341 266.41 336 261.41 331 261.41 321 256.41 316',
  },
  {
    id: 'staradarozhski-raion',
    region_name: 'Стародорожский район',
    className: 'minskaya',
    points:
      '271.41 306 271.41 296 276.41 291 276.41 286 266.41 286 266.41 281 271.41 276 271.41 271 276.41 271 281.41 276 281.41 281 286.41 286 295.91 286 301.41 291 306.41 291 306.41 301 301.41 306 301.41 311 296.41 316 296.41 321 291.41 316 286.41 316 281.41 311 276.41 311 271.41 306',
  },
  {
    id: 'puhavitski-raion',
    region_name: 'Пуховичский район',
    className: 'minskaya',
    points:
      '266.41 281 261.41 276 256.41 276 251.41 271 251.41 266 256.41 261 251.41 256 251.41 251 246.41 246 246.41 241 256.41 241 261.41 236 261.41 231 266.41 231 276.41 241 281.41 241 286.41 246 291.41 246 296.41 241 306.41 251 301.41 256 296.41 256 296.41 266 301.41 271 296.41 276 281.41 276 276.41 271 271.41 271 271.41 276 266.41 281',
  },
  {
    id: 'chervenski-raion',
    region_name: 'Червенский район',
    className: 'minskaya',
    points:
      '266.41 231 271.41 226 281.41 226 291.41 216 301.41 216 306.41 211 311.41 211 311.41 216 316.41 221 316.41 231 321.41 236 321.41 246 311.41 246 306.41 251 296.41 241 291.41 246 286.41 246 281.41 241 276.41 241 266.41 231',
  },
  {
    id: 'biarezinski-raion',
    region_name: 'Березинский район',
    className: 'minskaya',
    points:
      '311.41 211 316.41 206 326.41 206 331.41 201 341.41 201 351.41 211 351.41 221 356.41 226 356.41 236 346.41 236 341.41 241 336.41 241 331.41 246 326.41 246 326.41 251 321.41 246 321.41 236 316.41 231 316.41 221 311.41 216 311.41 211',
  },
  {
    id: 'krupski-raion',
    region_name: 'Крупский район',
    className: 'minskaya',
    points:
      '331.41 201 331.41 191 326.41 186 326.41 176 321.41 171 321.41 156 326.41 151 356.41 151 356.41 171 351.41 176 351.41 186 356.41 186 356.41 201 351.41 206 351.41 211 341.41 201 331.41 201',
  },
  {
    id: 'barysauski-raion',
    region_name: 'Борисовский район',
    className: 'minskaya',
    points:
      '306.41 211 301.41 206 301.41 201 286.41 186 286.41 176 281.41 171 286.41 166 276.41 156 271.41 151 271.41 146 286.41 146 296.41 156 301.41 151 301.41 146 311.41 146 311.41 151 316.41 156 321.41 156 321.41 171 326.41 176 326.41 186 331.41 191 331.41 201 326.41 206 316.41 206 311.41 211 306.41 211',
  },
  {
    id: 'smaliavitski-raion',
    region_name: 'Смолевичский район',
    className: 'minskaya',
    points:
      '271.41 226 271.41 216 266.41 211 266.41 206 261.41 201 266.41 196 271.41 196 281.41 186 286.41 186 301.41 201 301.41 206 306.41 211 301.41 216 291.41 216 281.41 226 271.41 226',
  },
  {
    id: 'lagoiski-raion',
    region_name: 'Логойский район',
    className: 'minskaya',
    points:
      '261.41 201 256.41 196 251.41 196 251.41 191 241.41 181 246.41 176 246.41 151 256.41 141 261.41 146 271.41 146 271.41 151 286.41 166 281.41 171 286.41 176 286.41 186 281.41 186 271.41 196 266.41 196 261.41 201',
  },
  {
    id: 'miadzelski-raion',
    region_name: 'Мядельский район',
    className: 'minskaya',
    points:
      '246.41 131 246.41 136 241.41 141 236.41 141 231.41 146 226.41 146 221.41 141 216.41 141 211.41 136 196.41 136 191.41 141 191.41 136 186.41 131 181.41 131 181.41 121 186.41 116 221.41 116 226.41 111 231.41 111 231.41 116 236.41 121 241.41 121 246.41 126 246.41 131',
  },
  {
    id: 'vileiski-raion',
    region_name: 'Вилейский район',
    className: 'minskaya',
    points:
      '196.41 166 206.41 166 211.41 171 216.41 171 221.41 176 231.41 176 236.41 181 241.41 181 246.41 176 246.41 151 256.41 141 256.41 136 251.41 131 246.41 131 246.41 136 241.41 141 236.41 141 231.41 146 226.41 146 221.41 141 216.41 141 211.41 136 196.41 136 191.41 141 196.41 146 201.41 146 201.41 156 196.41 161 196.41 166',
  },
  {
    id: 'maladzechanski-raion',
    region_name: 'Молодечненский район',
    className: 'minskaya',
    points:
      '236.41 181 236.41 191 226.41 201 211.41 201 206.41 196 201.41 196 186.41 181 191.41 176 191.41 171 196.41 166 206.41 166 211.41 171 216.41 171 221.41 176 231.41 176 236.41 181',
  },
  {
    id: 'minski-raion',
    region_name: 'Минский район',
    className: 'minskaya',
    points:
      '226.41 201 226.41 206 221.41 211 221.41 216 226.41 221 231.41 221 241.41 231 241.41 236 246.41 241 256.41 241 261.41 236 261.41 231 266.41 231 271.41 226 271.41 216 266.41 211 266.41 206 261.41 201 256.41 196 251.41 196 251.41 191 241.41 181 236.41 181 236.41 191 226.41 201',
  },
  {
    id: 'valozhynski-raion',
    region_name: 'Воложинский район',
    className: 'minskaya',
    points:
      '221.41 216 216.41 216 211.41 221 186.41 221 181.41 216 186.41 211 186.41 206 181.41 206 181.41 201 171.41 201 171.41 196 166.41 196 166.41 191 171.41 186 181.41 186 186.41 181 201.41 196 206.41 196 211.41 201 226.41 201 226.41 206 221.41 211 221.41 216',
  },
  {
    id: 'staubtsouski-raion',
    region_name: 'Столбцовский район',
    className: 'minskaya',
    points:
      '191.41 266 191.41 271 211.41 271 211.41 266 216.41 261 216.41 251 211.41 246 211.41 236 216.41 231 216.41 226 211.41 221 186.41 221 181.41 216 176.41 221 176.41 231 181.41 236 181.41 241 186.41 241 191.41 246 191.41 266',
  },
  {
    id: 'dziarzhynski-raion',
    region_name: 'Дзержинский район',
    className: 'minskaya',
    points:
      '216.41 256 221.41 256 226.41 251 236.41 251 241.41 246 241.41 241 246.41 241 241.41 236 241.41 231 231.41 221 226.41 221 221.41 216 216.41 216 211.41 221 216.41 226 216.41 231 211.41 236 211.41 246 216.41 251 216.41 256',
  },
  {
    id: 'uzdzenski-raion',
    region_name: 'Узденский район',
    className: 'minskaya',
    points:
      '211.41 266 216.41 271 226.41 271 231.41 276 236.41 271 241.41 271 246.41 266 251.41 266 256.41 261 251.41 256 251.41 251 246.41 246 246.41 241 241.41 241 241.41 246 236.41 251 226.41 251 221.41 256 216.41 256 216.41 261 211.41 266',
  },
  {
    id: 'slutski-raion',
    region_name: 'Слуцкий район',
    className: 'minskaya',
    points:
      '241.41 271 241.41 276 246.41 281 226.41 301 226.41 311 246.41 311 251.41 316 256.41 316 266.41 306 271.41 306 271.41 296 276.41 291 276.41 286 266.41 286 266.41 281 261.41 276 256.41 276 251.41 271 251.41 266 246.41 266 241.41 271',
  },
  {
    id: 'kapylski-raion',
    region_name: 'Копыльский район',
    className: 'minskaya',
    points:
      '221.41 321 211.41 311 211.41 306 206.41 301 206.41 296 211.41 291 211.41 271 211.41 266 216.41 271 226.41 271 231.41 276 236.41 271 241.41 271 241.41 276 246.41 281 226.41 301 226.41 311 226.41 321 221.41 321',
  },
  {
    id: 'niasvizhski-raion',
    region_name: 'Несвижский район',
    className: 'minskaya',
    points:
      '181.41 271 181.41 276 176.41 281 176.41 286 181.71 291 196.41 291 201.41 296 206.41 296 211.41 291 211.41 271 191.41 271 191.41 266 186.41 266 181.41 271',
  },
  {
    id: 'kletski-raion',
    region_name: 'Клецкий район',
    className: 'minskaya',
    points:
      '186.41 291 191.41 296 181.41 306 186.41 311 186.41 321 191.41 316 196.41 316 206.41 326 216.41 326 221.41 321 211.41 311 211.41 306 206.41 301 206.41 296 201.41 296 196.41 291 186.41 291',
  },

  //brestskaia-voblasts

  {
    id: 'baranavitski-raion',
    region_name: 'Барановичский район',
    className: 'brestskaya',
    points:
      '181.71 291 171.41 291 166.41 296 166.41 306 161.41 311 156.41 311 151.41 316 146.41 316 136.41 306 131.41 306 136.41 301 136.41 281 141.41 276 141.41 271 146.41 266 156.41 266 171.41 266 176.41 271 181.41 271 181.41 276 176.41 281 176.41 286 181.71 291',
  },
  {
    id: 'liahavitski-raion',
    region_name: 'Ляховичский район',
    className: 'brestskaya',
    points:
      '151.41 316 151.41 321 161.41 331 161.41 336 171.41 326 176.41 326 181.41 321 186.41 321 186.41 311 181.41 306 191.41 296 186.41 291 171.41 291 166.41 296 166.41 306 161.41 311 156.41 311 151.41 316',
  },
  {
    id: 'hantsavitski-raion',
    region_name: 'Ганцевичский район',
    className: 'brestskaya',
    points:
      '161.41 336 171.41 346 181.41 346 186.41 351 191.41 351 196.41 356 201.41 351 201.41 341 211.41 331 216.41 331 221.41 331 216.41 326 206.41 326 196.41 316 191.41 316 186.41 321 181.41 321 176.41 326 171.41 326 161.41 336',
  },
  {
    id: 'luninetski-raion',
    region_name: 'Лунинецкий район',
    className: 'brestskaya',
    points:
      '196.41 356 191.41 356 186.41 361 186.41 366 191.41 371 191.41 376 196.41 381 201.41 381 206.41 386 246.41 386 246.41 376 241.41 371 236.41 371 231.41 366 226.41 361 226.41 356 221.41 351 221.41 331 211.41 331 201.41 341 201.41 351 196.41 356',
  },
  {
    id: 'stolinski-raion',
    region_name: 'Столинский район',
    className: 'brestskaya',
    points:
      '206.41 386 201.41 391 196.41 391 191.41 396 186.41 396 181.41 401 186.41 406 186.41 411 191.41 416 201.41 416 206.41 421 226.41 421 231.41 426 231.41 431 236.41 436 246.41 436 246.41 416 246.41 406 251.41 401 251.41 396 246.41 391 246.41 386 206.41 386',
  },
  {
    id: 'pinski-raion',
    region_name: 'Пинский район',
    className: 'brestskaya',
    points:
      '171.41 346 171.41 351 166.41 356 146.41 356 146.41 371 151.41 371 156.41 376 151.41 381 151.41 386 146.41 391 146.41 401 151.41 406 166.41 406 171.41 411 186.41 411 186.41 406 181.41 401 186.41 396 191.41 396 196.41 391 201.41 391 206.41 386 201.41 381 196.41 381 191.41 376 191.41 371 186.41 366 186.41 361 191.41 356 196.41 356 191.41 351 186.41 351 181.41 346 171.41 346',
  },
  {
    id: 'ivatsevichski-raion',
    region_name: 'Ивацевичский район',
    className: 'brestskaya',
    points:
      '146.41 361 141.41 356 136.41 356 131.41 351 126.41 351 111.41 336 101.41 336 101.41 326 111.41 316 121.41 316 121.41 311 126.41 306 131.41 306 136.41 306 146.41 316 151.41 316 151.41 321 161.41 331 161.41 336 171.41 346 171.41 351 166.41 356 146.41 356 146.41 361',
  },
  {
    id: 'ivanauski-raion',
    region_name: 'Ивановский район',
    className: 'brestskaya',
    points:
      '136.41 356 131.41 356 126.41 361 126.41 366 131.41 371 126.41 376 126.41 386 121.41 391 121.41 401 126.41 406 141.41 406 146.41 401 146.41 391 151.41 386 151.41 381 156.41 376 151.41 371 146.41 371 146.41 361 141.41 356 136.41 356',
  },
  {
    id: 'biarozauski-raion',
    region_name: 'Березовский район',
    className: 'brestskaya',
    points:
      '126.41 361 111.41 361 106.41 366 96.41 366 91.41 361 91.41 356 91.41 351 86.41 346 86.41 341 91.41 336 101.41 336 111.41 336 126.41 351 131.41 351 136.41 356 131.41 356 126.41 361',
  },
  {
    id: 'pruzhanski-raion',
    region_name: 'Пружанский район',
    className: 'brestskaya',
    points:
      '91.41 356 86.41 356 81.41 361 76.41 356 71.41 356 66.41 361 61.41 361 51.41 351 51.41 346 46.41 341 46.41 336 46.41 326 51.41 326 56.41 321 61.41 326 71.41 326 76.41 331 81.41 326 81.41 316 86.41 311 91.41 306 101.41 306 101.41 311 106.41 316 111.41 316 101.41 326 101.41 336 91.41 336 86.41 341 86.41 346 91.41 351 91.41 356',
  },
  {
    id: 'kamiznetski-raion',
    region_name: 'Каменецкий район',
    className: 'brestskaya',
    points:
      '46.41 336 36.41 336 31.41 341 21.41 341 11.41 351 11.41 356 6.41 361 6.41 366 1.41 371 6.41 376 11.41 376 16.41 381 16.41 371 21.41 366 26.41 366 36.41 376 41.41 376 41.41 366 51.41 366 56.41 361 61.41 361 51.41 351 51.41 346 46.41 341 46.41 336',
  },
  {
    id: 'brestski-raion',
    region_name: 'Брестский район',
    className: 'brestskaya',
    points:
      '16.41 381 21.41 386 26.41 386 26.41 391 31.41 396 31.41 401 26.41 406 26.41 421 21.41 426 21.41 441 26.41 446 26.41 436 31.41 431 36.41 431 36.41 421 41.41 416 41.41 411 46.41 406 46.41 401 51.41 396 56.41 396 46.41 386 46.41 381 41.41 376 36.41 376 26.41 366 21.41 366 16.41 371 16.41 381',
  },
  {
    id: 'zhabinkauski-raion',
    region_name: 'Жабинковский район',
    className: 'brestskaya',
    points:
      '56.41 396 61.41 396 61.41 391 66.41 386 61.41 381 61.41 376 56.41 371 56.41 361 51.41 366 41.41 366 41.41 376 46.41 381 46.41 386 56.41 396',
  },
  {
    id: 'malarytski-raion',
    region_name: 'Малоритский район',
    className: 'brestskaya',
    points:
      '36.41 431 41.41 431 46.41 436 46.41 441 51.41 441 56.41 436 71.41 421 71.41 406 76.41 401 71.41 396 61.41 396 56.41 396 51.41 396 46.41 401 46.41 406 41.41 411 41.41 416 36.41 421 36.41 431',
  },
  {
    id: 'kobrynski-raion',
    region_name: 'Кобринский район',
    className: 'brestskaya',
    points:
      '71.41 406 91.41 406 96.41 401 96.41 396 91.41 391 91.41 366 96.41 366 91.41 361 91.41 356 86.41 356 81.41 361 76.41 356 71.41 356 66.41 361 56.41 361 56.41 371 61.41 376 61.41 381 66.41 386 61.41 391 61.41 396 71.41 396 76.41 401 71.41 406',
  },
  {
    id: 'drahichynski-raion',
    region_name: 'Дрогичинский район',
    className: 'brestskaya',
    points:
      '91.41 406 106.41 406 111.41 401 121.41 401 121.41 391 126.41 386 126.41 376 131.41 371 126.41 366 126.41 361 111.41 361 106.41 366 96.41 366 91.41 366 91.41 391 96.41 396 96.41 401 91.41 406',
  },
];

export const harady = [
  //minskaya

  {
    id: 'horad-minsk',
    city_name: 'г. Минск',
    cx: '249.41',
    cy: '217',
    r: bigRadius,
  },
  {
    id: 'horad-cherven',
    city_name: 'г. Червень',
    cx: '302.41',
    cy: '232',
    r: standardRadius,
  },
  {
    id: 'horad-berazino',
    city_name: 'г. Березино',
    cx: '333.41',
    cy: '221',
    r: standardRadius,
  },
  {
    id: 'horad-krupki',
    city_name: 'г. Крупки',
    cx: '337.41',
    cy: '175',
    r: standardRadius,
  },
  {
    id: 'horad-borisov',
    city_name: 'г. Борисов',
    cx: '304.91',
    cy: '186',
    r: standardRadius,
  },
  {
    id: 'horad-smolevichi',
    city_name: 'г. Смолевичи',
    cx: '283.91',
    cy: '204',
    r: standardRadius,
  },
  {
    id: 'horad-lagoisk',
    city_name: 'г. Логойск',
    cx: '266.41',
    cy: '183.5',
    r: standardRadius,
  },
  {
    id: 'horad-kletsk',
    city_name: 'г. Клецк',
    cx: '201.41',
    cy: '305',
    r: standardRadius,
  },
  {
    id: 'horad-niasvizh',
    city_name: 'г. Несвиж',
    cx: '201.41',
    cy: '278.5',
    r: standardRadius,
  },
  {
    id: 'horad-kapyl',
    city_name: 'г. Копыль',
    cx: '223.91',
    cy: '290',
    r: standardRadius,
  },
  {
    id: 'horad-slutsk',
    city_name: 'г. Слуцк',
    cx: '248.91',
    cy: '301',
    r: standardRadius,
  },
  {
    id: 'horad-uzda',
    city_name: 'г. Узда',
    cx: '231.41',
    cy: '262.5',
    r: standardRadius,
  },
  {
    id: 'horad-dziarzhynsk',
    city_name: 'г. Дзержинск',
    cx: '227.41',
    cy: '238.5',
    r: standardRadius,
  },
  {
    id: 'horad-staubtsi',
    city_name: 'г. Столбцы',
    cx: '202.41',
    cy: '251',
    r: standardRadius,
  },
  {
    id: 'horad-valozhyn',
    city_name: 'г. Воложин',
    cx: '196.41',
    cy: '201',
    r: standardRadius,
  },
  {
    id: 'horad-maladzechna',
    city_name: 'г. Молодечно',
    cx: '208.91',
    cy: '179.5',
    r: standardRadius,
  },
  {
    id: 'horad-vileika',
    city_name: 'г. Вилейка',
    cx: '213.91',
    cy: '158.5',
    r: standardRadius,
  },
  {
    id: 'horad-puhavitchi',
    city_name: 'г. Пуховичи',
    cx: '276.41',
    cy: '256',
    r: standardRadius,
  },
  {
    id: 'horad-starodarodzhi',
    city_name: 'г. Старые Дороги',
    cx: '287.91',
    cy: '299',
    r: standardRadius,
  },
  {
    id: 'horad-luban',
    city_name: 'г. Любань',
    cx: '275.91',
    cy: '323',
    r: standardRadius,
  },
  {
    id: 'horad-salihorsk',
    city_name: 'г. Солигорск',
    cx: '248.91',
    cy: '326',
    r: standardRadius,
  },
  {
    id: 'horad-miadzel',
    city_name: 'г. Мядель',
    cx: '212.91',
    cy: '126',
    r: standardRadius,
  },

  //vitebskaya

  {
    id: 'horad-polotsk',
    city_name: 'г. Полоцк',
    cx: '321.41',
    cy: '62',
    r: standardRadius,
  },
  {
    id: 'horad-vitsebsk',
    city_name: 'г. Витебск',
    cx: '396.41',
    cy: '96',
    r: mediumRadius,
  },
  {
    id: 'horad-rassoni',
    city_name: 'г. Россоны',
    cx: '316.41',
    cy: '31',
    r: standardRadius,
  },
  {
    id: 'horad-haradok',
    city_name: 'г. Городок',
    cx: '391.41',
    cy: '66',
    r: standardRadius,
  },
  {
    id: 'horad-ushachi',
    city_name: 'г. Ушачи',
    cx: '316.41',
    cy: '93.5',
    r: standardRadius,
  },
  {
    id: 'horad-shumilino',
    city_name: 'г. Шумилино',
    cx: '362.91',
    cy: '83',
    r: standardRadius,
  },
  {
    id: 'horad-beshenkovichi',
    city_name: 'г. Бешенковичи',
    cx: '358.91',
    cy: '110',
    r: standardRadius,
  },
  {
    id: 'horad-liozni',
    city_name: 'г. Лиозно',
    cx: '428.91',
    cy: '116',
    r: standardRadius,
  },
  {
    id: 'horad-senno',
    city_name: 'г. Сенно',
    cx: '376.41',
    cy: '133.5',
    r: standardRadius,
  },
  {
    id: 'horad-dubrovno',
    city_name: 'г. Дубровно',
    cx: '428.91',
    cy: '154.5',
    r: standardRadius,
  },
  {
    id: 'horad-orsha',
    city_name: 'г. Орша',
    cx: '410.41',
    cy: '166',
    r: standardRadius,
  },
  {
    id: 'horad-talochyn',
    city_name: 'г. Толочин',
    cx: '376.41',
    cy: '166',
    r: standardRadius,
  },
  {
    id: 'horad-chasniki',
    city_name: 'г. Чашники',
    cx: '343.91',
    cy: '141',
    r: standardRadius,
  },
  {
    id: 'horad-lepel',
    city_name: 'г. Лепель',
    cx: '311.41',
    cy: '128.5',
    r: standardRadius,
  },
  {
    id: 'horad-verhnedvinsk',
    city_name: 'г. Верхнедвинск',
    cx: '276.91',
    cy: '36.2',
    r: standardRadius,
  },
  {
    id: 'horad-miori',
    city_name: 'г. Миоры',
    cx: '256.41',
    cy: '54',
    r: standardRadius,
  },
  {
    id: 'horad-hlubokoe',
    city_name: 'г. Глубокое',
    cx: '256.41',
    cy: '96',
    r: standardRadius,
  },
  {
    id: 'horad-dokshytsi',
    city_name: 'г. Докшицы',
    cx: '266.41',
    cy: '120',
    r: standardRadius,
  },
  {
    id: 'horad-sharkovshina',
    city_name: 'г. Шарковщина',
    cx: '243.91',
    cy: '76',
    r: standardRadius,
  },
  {
    id: 'horad-braslau',
    city_name: 'г. Браслав',
    cx: '223.41',
    cy: '51',
    r: standardRadius,
  },
  {
    id: 'horad-postavi',
    city_name: 'г. Поставы',
    cx: '209.91',
    cy: '103.5',
    r: standardRadius,
  },

  //hrodnenskay

  {
    id: 'horad-hrodna',
    city_name: 'г. Гродно',
    cx: '36.41',
    cy: '236',
    r: mediumRadius,
  },
  {
    id: 'horad-astravets',
    city_name: 'г. Островец',
    cx: '162.91',
    cy: '146',
    r: standardRadius,
  },
  {
    id: 'horad-smargon',
    city_name: 'г. Сморгонь',
    cx: '183.61',
    cy: '161',
    r: standardRadius,
  },
  {
    id: 'horad-ashmiani',
    city_name: 'г. Ошмяны',
    cx: '162.91',
    cy: '171',
    r: standardRadius,
  },
  {
    id: 'horad-iueu',
    city_name: 'г. Ивье',
    cx: '151.41',
    cy: '213',
    r: standardRadius,
  },
  {
    id: 'horad-karelitchi',
    city_name: 'г. Кореличи',
    cx: '173.91',
    cy: '252',
    r: standardRadius,
  },
  {
    id: 'horad-novogrudok',
    city_name: 'г. Новогрудок',
    cx: '156.51',
    cy: '243.5',
    r: standardRadius,
  },
  {
    id: 'horad-lida',
    city_name: 'г. Лида',
    cx: '125.01',
    cy: '220',
    r: standardRadius,
  },
  {
    id: 'horad-voronovo',
    city_name: 'г. Вороново',
    cx: '120.41',
    cy: '197',
    r: standardRadius,
  },
  {
    id: 'horad-schuchin',
    city_name: 'г. Щучин',
    cx: '90.41',
    cy: '242.5',
    r: standardRadius,
  },
  {
    id: 'horad-dzyatlovo',
    city_name: 'г. Дятлово',
    cx: '128.41',
    cy: '265',
    r: standardRadius,
  },
  {
    id: 'horad-slonim',
    city_name: 'г. Слоним',
    cx: '122.91',
    cy: '297',
    r: standardRadius,
  },
  {
    id: 'horad-svisloch',
    city_name: 'г. Свислочь',
    cx: '56.41',
    cy: '303',
    r: standardRadius,
  },
  {
    id: 'horad-zelva',
    city_name: 'г. Зельва',
    cx: '96.41',
    cy: '291',
    r: standardRadius,
  },
  {
    id: 'horad-volkovisk',
    city_name: 'г. Волковыск',
    cx: '76.41',
    cy: '291',
    r: standardRadius,
  },
  {
    id: 'horad-mosti',
    city_name: 'г. Мосты',
    cx: '84.41',
    cy: '269',
    r: standardRadius,
  },
  {
    id: 'horad-berastavitsa',
    city_name: 'г. Большая Берестовица',
    cx: '48.41',
    cy: '284.5',
    r: standardRadius,
  },

  // homelskaya

  {
    id: 'horad-homel',
    city_name: 'г. Гомель',
    cx: '446.41',
    cy: '361',
    r: mediumRadius,
  },
  {
    id: 'horad-dobrysh',
    city_name: 'г. Добруш',
    cx: '464.07',
    cy: '363.5',
    r: standardRadius,
  },
  {
    id: 'horad-vetka',
    city_name: 'г. Ветка',
    cx: '456.41',
    cy: '341',
    r: standardRadius,
  },
  {
    id: 'horad-buda-koshelevo',
    city_name: 'г. Буда-Кошелево',
    cx: '418.63',
    cy: '334',
    r: standardRadius,
  },
  {
    id: 'horad-loeu',
    city_name: 'г. Лоев',
    cx: '427.91',
    cy: '403.5',
    r: standardRadius,
  },
  {
    id: 'horad-rechica',
    city_name: 'г. Речица',
    cx: '409.11',
    cy: '362.5',
    r: standardRadius,
  },
  {
    id: 'horad-brahin',
    city_name: 'г. Брагин',
    cx: '405.11',
    cy: '423.43',
    r: standardRadius,
  },
  {
    id: 'horad-khoiniki',
    city_name: 'г. Хойники',
    cx: '383.91',
    cy: '408.83',
    r: standardRadius,
  },
  {
    id: 'horad-naroulia',
    city_name: 'г. Наровля',
    cx: '355.41',
    cy: '421',
    r: standardRadius,
  },
  {
    id: 'horad-elsk',
    city_name: 'г. Ельск',
    cx: '335.07',
    cy: '417',
    r: standardRadius,
  },
  {
    id: 'horad-mazyr',
    city_name: 'г. Мозырь',
    cx: '343.91',
    cy: '396',
    r: standardRadius,
  },
  {
    id: 'horad-kalinovichi',
    city_name: 'г. Калинковичи',
    cx: '353.91',
    cy: '385',
    r: standardRadius,
  },
  {
    id: 'horad-lelchitsi',
    city_name: 'г. Лельчицы',
    cx: '295.41',
    cy: '417',
    r: standardRadius,
  },
  {
    id: 'horad-zhitkovichi',
    city_name: 'г. Житковичи',
    cx: '267.41',
    cy: '377',
    r: standardRadius,
  },
  {
    id: 'horad-petrikov',
    city_name: 'г. Петриков',
    cx: '308.91',
    cy: '378.5',
    r: standardRadius,
  },
  {
    id: 'horad-oktyabrskiy',
    city_name: 'г. Октябрьский',
    cx: '323.91',
    cy: '338.5',
    r: standardRadius,
  },
  {
    id: 'horad-svetlagork',
    city_name: 'г. Светлогорск',
    cx: '363.91',
    cy: '338.5',
    r: standardRadius,
  },
  {
    id: 'horad-zhlobin',
    city_name: 'г. Жлобин',
    cx: '387.91',
    cy: '319',
    r: standardRadius,
  },
  {
    id: 'horad-rahachou',
    city_name: 'г. Рогачев',
    cx: '391.41',
    cy: '296',
    r: standardRadius,
  },
  {
    id: 'horad-chechersk',
    city_name: 'г. Чечерск',
    cx: '438.91',
    cy: '313.5',
    r: standardRadius,
  },
  {
    id: 'horad-korma',
    city_name: 'г. Корма',
    cx: '432.91',
    cy: '289',
    r: standardRadius,
  },

  //brestskaya

  {
    id: 'horad-brest',
    city_name: 'г. Брест',
    cx: '34.41',
    cy: '391',
    r: mediumRadius,
  },
  {
    id: 'horad-baranavichy',
    city_name: 'г. Барановичи',
    cx: '161.41',
    cy: '291',
    r: standardRadius,
  },
  {
    id: 'horad-pinsk',
    city_name: 'г. Пинск',
    cx: '166.41',
    cy: '386',
    r: standardRadius,
  },
  {
    id: 'horad-drogichin',
    city_name: 'г. Дрогичин',
    cx: '113.91',
    cy: '378.5',
    r: standardRadius,
  },
  {
    id: 'horad-kobrin',
    city_name: 'г. Кобрин',
    cx: '69.91',
    cy: '374.5',
    r: standardRadius,
  },
  {
    id: 'horad-malorita',
    city_name: 'г. Малорита',
    cx: '56.41',
    cy: '420',
    r: standardRadius,
  },
  {
    id: 'horad-dzhabinka',
    city_name: 'г. Жабинка',
    cx: '51.41',
    cy: '379.5',
    r: standardRadius,
  },
  {
    id: 'horad-kamenetz',
    city_name: 'г. Каменец',
    cx: '39.41',
    cy: '360',
    r: standardRadius,
  },
  {
    id: 'horad-prudzhani',
    city_name: 'г. Пружаны',
    cx: '73.91',
    cy: '346',
    r: standardRadius,
  },
  {
    id: 'horad-bereza',
    city_name: 'г. Береза',
    cx: '107.41',
    cy: '350',
    r: standardRadius,
  },
  {
    id: 'horad-ivanovo',
    city_name: 'г. Иваново',
    cx: '138.91',
    cy: '383.5',
    r: standardRadius,
  },
  {
    id: 'horad-ivacevici',
    city_name: 'г. Ивацевичи',
    cx: '128.41',
    cy: '327',
    r: standardRadius,
  },
  {
    id: 'horad-stolin',
    city_name: 'г. Столин',
    cx: '210.41',
    cy: '406',
    r: standardRadius,
  },
  {
    id: 'horad-luninets',
    city_name: 'г. Лунинец',
    cx: '214.41',
    cy: '374.5',
    r: standardRadius,
  },
  {
    id: 'horad-hancevichi',
    city_name: 'г. Ганцевичи',
    cx: '184.5',
    cy: '330',
    r: standardRadius,
  },
  {
    id: 'horad-lyahovichi',
    city_name: 'г. Ляховичи',
    cx: '174.41',
    cy: '301',
    r: standardRadius,
  },

  //mahilouskaya

  {
    id: 'horad-mahiliou',
    city_name: 'г. Могилев',
    cx: '406.41',
    cy: '216',
    r: standardRadius,
  },
  {
    id: 'horad-babruisk',
    city_name: 'г. Бобруйск',
    cx: '341.81',
    cy: '290.43',
    r: standardRadius,
  },
  {
    id: 'horad-slavgorod',
    city_name: 'г. Славгород',
    cx: '442.91',
    cy: '261',
    r: standardRadius,
  },
  {
    id: 'horad-krasnopolie',
    city_name: 'г. Краснополье',
    cx: '468.07',
    cy: '273.5',
    r: standardRadius,
  },
  {
    id: 'horad-kostiukovichi',
    city_name: 'г. Костюковичи',
    cx: '501.41',
    cy: '276',
    r: standardRadius,
  },
  {
    id: 'horad-khotimsk',
    city_name: 'г. Хотимск',
    cx: '528.07',
    cy: '262.5',
    r: standardRadius,
  },
  {
    id: 'horad-klimovichi',
    city_name: 'г. Климовичи',
    cx: '501.41',
    cy: '243.5',
    r: standardRadius,
  },
  {
    id: 'horad-krichev',
    city_name: 'г. Кричев',
    cx: '485.41',
    cy: '232',
    r: standardRadius,
  },
  {
    id: 'horad-cherikov',
    city_name: 'г. Чериков',
    cx: '468.07',
    cy: '248',
    r: standardRadius,
  },
  {
    id: 'horad-chausi',
    city_name: 'г. Чаусы',
    cx: '441.41',
    cy: '224',
    r: standardRadius,
  },
  {
    id: 'horad-bihau',
    city_name: 'г. Быхов',
    cx: '403.91',
    cy: '256',
    r: standardRadius,
  },
  {
    id: 'horad-kirovsk',
    city_name: 'г. Кировск',
    cx: '361.41',
    cy: '274.5',
    r: standardRadius,
  },
  {
    id: 'horad-hlusk',
    city_name: 'г. Глуск',
    cx: '313.72',
    cy: '311',
    r: standardRadius,
  },
  {
    id: 'horad-asipovichi',
    city_name: 'г. Осиповичи',
    cx: '313.91',
    cy: '271',
    r: standardRadius,
  },
  {
    id: 'horad-klichev',
    city_name: 'г. Кличев',
    cx: '347.91',
    cy: '255',
    r: standardRadius,
  },
  {
    id: 'horad-belinichi',
    city_name: 'г. Белыничи',
    cx: '372.41',
    cy: '208',
    r: standardRadius,
  },
  {
    id: 'horad-khruhlae',
    city_name: 'г. Круглое',
    cx: '377.91',
    cy: '186',
    r: standardRadius,
  },
  {
    id: 'horad-shklou',
    city_name: 'г. Шклов',
    cx: '406.41',
    cy: '191.15',
    r: standardRadius,
  },
  {
    id: 'horad-mstislavl',
    city_name: 'г. Мстиславль',
    cx: '481.41',
    cy: '208',
    r: standardRadius,
  },
  {
    id: 'horad-dribin',
    city_name: 'г. Дрибин',
    cx: '446.91',
    cy: '198.5',
    r: standardRadius,
  },
  {
    id: 'horad-horki',
    city_name: 'г. Горки',
    cx: '445.41',
    cy: '179.5',
    r: standardRadius,
  },
];
