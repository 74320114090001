import React from 'react';
import { Gauge, GaugeConfig } from '@ant-design/charts';

import * as S from '../styles/MeterGaugeStyles';
import '../../styles/styles.css';

import { IndicatorsType } from '@app/components/dashboards/profile-info/components/GeneralInformation/Indicators/components/IdicatorsDescription/utils';
import { MyStyles } from '@app/styles/themes/myStyles/myStyles';

type MyComponentProps = {
  risk: string;
  name: string;
  content: number | string;
  description: string;
  type: IndicatorsType;
};

const MeterGaugePlot: React.FC<MyComponentProps> = ({ risk, name, content, description, type }) => {
  const config: GaugeConfig = {
    percent: getRiskLevel(risk),
    range: {
      color: getColorByRisk(risk),
    },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: false,
    statistic: {
      content: {
        style: {
          fontSize: '18px',
          lineHeight: '15px',
          color: '#4B535E',
        },
        formatter: () => `${content}`,
      },
    },
  };
  return (
    <S.Container>
      <S.LeftColumn>
        <S.GaugeContainer>
          <Gauge {...config} style={{ height: '100%', width: '100%', padding: 0 }} />
        </S.GaugeContainer>
        <S.Title>{name}</S.Title>
      </S.LeftColumn>
      <S.RightColumnColumn>
        <S.Description>{description}</S.Description>
        <a href={`https://analytix.by/indicators/${type}`} target="_blank" rel="noreferrer">
          <S.ButtonMoreDetails>Подробнее</S.ButtonMoreDetails>
        </a>
      </S.RightColumnColumn>
    </S.Container>
  );
};

export default MeterGaugePlot;

const getRiskLevel = (risk: string): number => {
  switch (risk.split(' ')[0]) {
    case 'Низкий':
      return 0.2;
    case 'Средний':
      return 0.5;
    case 'Высокий':
      return 1;
    default:
      return 0;
  }
};

const getColorByRisk = (risk: string): string => {
  switch (risk.split(' ')[0]) {
    case 'Низкий':
      return MyStyles.indicators.colors.green;
    case 'Средний':
      return MyStyles.indicators.colors.yellow;
    case 'Высокий':
      return MyStyles.indicators.colors.red;
    default:
      return 'white';
  }
};
