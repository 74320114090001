export const MyStyles = {
  primaryColor: '#2c86d3',
  focusColor: '#f6f2f2',

  fontSize: {
    pageTitle: '28px',
  },

  width: {
    container: '1320px',
  },

  colors: {
    placeholder: 'gray',
    pageTitle: '#000',
    hover: '#f6f2f2',
    white: '#fff',
    black: '#000',
  },

  indicators: {
    colors: {
      red: '#ffa998',
      lightYellow: '#efd77a',
      yellow: '#dde7cb',
      lightGreen: '#add191',
      green: '#40b043',
    },
  },

  news: {
    color: {
      location: '#ffd166',
      organization: '#06d6a0',
      person: '#118ab2',
      search: '#652d0f',
    },
  },

  interactiveMap: {
    color: {
      minskaya: '#edebba',
      vitebskaya: '#e4c9d2',
      homelskaya: '#c2b8d0',
      mahileuskaya: '#baccd6',
      brestskaya: '#e1bcaa',
      hrodnenskaya: '#b5c6be',
      horad: '#cf7458',
    },
  },
};
