import React from 'react';
import { Slider } from 'antd';
import styled from 'styled-components';

type Props = {
  dateRange: [number, number];
  setDateRange: (range: [number, number]) => void;
};

const SliderDate: React.FC<Props> = ({ dateRange, setDateRange }) => {
  const handleSliderChange = (value: [number, number]) => {
    const [from, to] = value;

    setDateRange([from, to]);
  };

  return (
    <Container>
      <DateContainer>
        <span>c {dateRange[0]} года</span>
        <span>-</span>
        <span>по {dateRange[1]} год</span>
      </DateContainer>
      <Slider
        min={1970}
        max={2024}
        range
        defaultValue={dateRange}
        tooltipVisible={false}
        onChange={handleSliderChange}
      />
    </Container>
  );
};

export default SliderDate;

const Container = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DateContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 10px;
`;
