import styled from 'styled-components';

export const Container = styled.div`
  min-width: calc(50% - 10px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

export const TableTitle = styled.div`
  font-size: 14px;
`;

export const TableContent = styled.div`
  font-size: 12px;
`;

export const ExpandedText = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;
