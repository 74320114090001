import styled from 'styled-components';

export const PlaceholderText = styled.div`
  font-size: 14px;
`;

export const filterStyle = {
  height: 'auto',
  width: '100%',
};
