import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  IndicatorsType,
  scrollToIndicators,
} from '@app/components/dashboards/profile-info/components/GeneralInformation/Indicators/components/IdicatorsDescription/utils';
import { useParams } from 'react-router-dom';

const IndicatorsDescription: React.FC = () => {
  const { type } = useParams();

  useEffect(() => {
    scrollToIndicators(type ? type : '');
  }, [type]);

  return (
    <Container>
      <Title>Описание индикаторов</Title>

      <Description>
        <Paragraph>
          <Bold>Индекс уровня надежности</Bold> — это скоринговый аналитический показатель, рассчитываемый на основе
          публично доступной информации о деятельности юридического лица. Он помогает оценить надежность или
          ненадежность контрагента. Индекс уровня надежности для анализа компаний использует только официальные
          источники данных, такие как регистрационные сведения, данные о деятельности и факторы риска. Для расчета
          индекса система учитывает более 30 различных факторов, что позволяет дать оценку степени благонадежности
          фирмы-партнера и погасить риски ведения бизнеса с ней.
        </Paragraph>
        <Paragraph>
          Индекс уровня надежности находится в диапазоне от 0 до 9. Чем выше значение, тем меньше риск, что компания
          может быть ненадежной. Данный инструмент помогает повысить прозрачность бизнеса, а также более точно выявлять
          фирмы, с которыми сотрудничество может быть рискованным. Индекс уровня надежности является важным инструментом
          для инвесторов, аналитиков и бизнес-партнеров, помогая им принимать обоснованные решения на основе объективных
          данных о компаниях.
        </Paragraph>
      </Description>

      <Description id={IndicatorsType.ADDRESS}>
        <Paragraph>
          <Bold>Индикатор частоты смены юридического адреса</Bold> — это количественный показатель, предназначенный для
          мониторинга стабильности и надежности компании. Он отслеживает количество изменений юридического адреса
          организации за определенный период времени. Этот индикатор может служить важным сигналом для инвесторов и
          партнеров, поскольку частые изменения адреса могут указывать на потенциальные управленческие проблемы или
          нестабильность внешней бизнес-среды.
        </Paragraph>
        <Paragraph>
          Для наглядности и удобства интерпретации результаты представлены в виде цветовой кодировки:
        </Paragraph>
        <Paragraph>
          <Green>Зеленый:</Green> Обозначает редкую смену адреса, что может свидетельствовать о низком уровне риска.
        </Paragraph>
        <Paragraph>
          <Orange>Оранжевый:</Orange> Указывает на среднюю частоту смены адреса, что требует более глубокого анализа для
          оценки потенциальных рисков.
        </Paragraph>
        <Paragraph>
          <Red>Красный:</Red> Сигнализирует о частой смене адреса, что может быть признаком высокого уровня риска и
          нестабильности.
        </Paragraph>
        <Paragraph>
          Значение индекса, отображаемое на индикаторе, представляет собой агрегированное число смены юридического
          адреса компанией за весь период функционирования компании.
        </Paragraph>
        <Paragraph>
          Этот индикатор является частью комплексной системы оценки рисков и может использоваться в сочетании с другими
          метриками для получения более полной картины финансового здоровья и устойчивости компании.
        </Paragraph>
      </Description>

      <Description id={IndicatorsType.JURIST}>
        <Paragraph>
          <Bold>Индекс юридической активности</Bold> (далее - ИЮА) представляет собой композитный показатель, который
          количественно оценивает юридическую активность компании на основе регулярности и значимости юридических
          действий, осуществляемых в государственных органах управления. ИЮА интегрирует данные о ключевых юридических
          операциях, таких как изменение юридического адреса, ротация руководства, изменения в уставных документах и
          другие юридические процедуры. Эти параметры являются ключевыми для оценки уровня корпоративного управления и
          стратегического развития организации. Индикатор использует цветовую кодировку для обозначения уровня
          юридической активности:
        </Paragraph>
        <Paragraph>
          <Green>Зеленый:</Green> Высокая активность, сигнализирующая о потенциально низком уровне риска.
        </Paragraph>
        <Paragraph>
          <Orange>Оранжевый:</Orange> Средняя активность, требующая дополнительного анализа.
        </Paragraph>
        <Paragraph>
          <Red>Красный:</Red> Низкая активность, что может указывать на повышенные риски.
        </Paragraph>
        <Paragraph>
          Значение индекса, отображаемое на индикаторе, представляет собой агрегированное число совершения юридических
          процедур за весь период функционирования компании.
        </Paragraph>
        <Paragraph>
          Использование ИЮА позволяет руководству и аналитикам эффективно мониторить юридическую динамику компании и
          принимать обоснованные управленческие решения, основанные на актуальной и наглядной информации.
        </Paragraph>
      </Description>

      <Description id={IndicatorsType.ROTATION}>
        <Paragraph>
          <Bold>Индекс управленческой ротации</Bold> — это метрика, которая измеряет частоту изменения управленческого
          состава организации (руководителей). Этот индекс служит индикатором стабильности управленческой структуры и её
          влияния на операционную эффективность предприятия. Он рассчитывается как суммарное количество замен
          руководителей компании (директоров) за определенный период времени.
        </Paragraph>
        <Paragraph>
          <Green>Низкий уровень (Зелёная зона):</Green> Отражает устойчивость управленческой команды и минимальную
          частоту организационных изменений. Предприятия с низким уровнем индекса характеризуются сохранением
          стратегической последовательности, высокой степенью лояльности сотрудников и непрерывностью бизнес-процессов.
          Такая стабильность указывает на сниженный риск в аспекте управленческой непрерывности и обеспечивает
          предсказуемость функционирования организации.
        </Paragraph>
        <Paragraph>
          <Orange>Средний уровень (Оранжевая зона):</Orange> Сигнализирует о присутствии умеренной нестабильности в
          управленческом аппарате. Организации, попадающие в этот сегмент, могут сталкиваться с периодическими сбоями в
          управлении, однако обладают способностью к адаптации к изменениям. Существует умеренный риск, связанный с
          трудностями в поддержании долгосрочной стратегической направленности.
        </Paragraph>
        <Paragraph>
          <Red>Высокий уровень (Красная зона):</Red> Индицирует значительную частоту смены управленческого состава, что
          может свидетельствовать о потенциальной нестабильности в управлении. Частые кадровые перестановки могут
          негативно сказываться на организационной культуре, процессах принятия решений и реализации стратегических
          задач. Такие изменения могут привести к снижению производительности, ухудшению морального климата среди
          сотрудников и нарушению стратегического согласования.
        </Paragraph>
        <Paragraph>
          Значение индекса, отображаемое на индикаторе, представляет собой агрегированное число смен руководителей за
          весь период функционирования компании.
        </Paragraph>
      </Description>

      <Description id={IndicatorsType.LEVEL}>
        <Paragraph>
          <Bold>Маркер уровня открытости</Bold> — это передовой инструмент, предназначенный для оценки доступности
          компании. Он анализирует и визуализирует количество доступных каналов связи, таких как телефон, электронная
          почта и веб-сайты, предоставляя потенциальным клиентам и партнерам ясное представление о том, насколько легко
          установить контакт с компанией. Индикатор использует <Bold>цветовую кодировку</Bold> для обозначения уровня
          контактности:
        </Paragraph>
        <Paragraph>
          <Green>Зеленый цвет</Green> символизирует <Bold>высокий уровень доступности</Bold>, указывая на то, что
          компания предоставляет обширный набор средств для связи.
        </Paragraph>
        <Paragraph>
          <Orange>Оранжевый цвет</Orange> отражает <Bold>средний уровень доступности</Bold>, что означает наличие
          достаточного количества каналов связи для удовлетворения стандартных потребностей.
        </Paragraph>
        <Paragraph>
          <Red>Красный цвет</Red> предупреждает о <Bold>низком уровне доступности</Bold>, сигнализируя о том, что
          компания предоставляет ограниченное количество способов для связи.
        </Paragraph>
        <Paragraph>
          Стоит отметить, что числовое значение, отображаемое на индикаторе, представляет собой общее количество
          найденных системой каналов связи.
        </Paragraph>
        <Paragraph>
          Индикатор уровня открытости помогает контрагентам оценить, насколько легко они могут связаться с компанией.
          Отсутствие ясных контактных данных может вызвать сомнения у контрагентов. Они могут начать сомневаться в
          надежности компании или даже подозревать скрытые намерения. Партнеры ожидают, что компания будет доступна для
          общения. Четкие контактные данные говорят о прозрачности и готовности к открытому общению. Компании, которые
          стремятся к прозрачности, обычно более привлекательны для контрагентов.
        </Paragraph>
      </Description>

      <Description>
        <Paragraph>
          <Bold>Индикатор уровня конкуренции</Bold> (далее - ИУК) – это ключевой инструмент для бизнес-анализа, который
          позволяет оценить интенсивность конкуренции между компаниями, зарегистрированными в конкретном городе и
          оперирующими в одной и той же сфере бизнеса. Индикатор визуализирует уровень конкуренции с помощью цветовой
          кодировки:
        </Paragraph>
        <Paragraph>
          <Red>Красный (Высокий уровень конкуренции):</Red> Если ИУК отображается красным, это указывает на интенсивную
          конкуренцию. В данном случае, компании соревнуются за ресурсы, клиентов и долю рынка. Бизнесам следует
          принимать активные меры для выживания и роста.
        </Paragraph>
        <Paragraph>
          <Orange>Оранжевый (Средний уровень конкуренции):</Orange> Оранжевый цвет указывает на умеренную конкуренцию.
          Компании имеют некоторую свободу действий, но все еще должны следить за конкурентами и адаптироваться к
          изменениям на рынке.
        </Paragraph>
        <Paragraph>
          <Green>Зеленый (Низкий уровень конкуренции):</Green> Зеленый ИУК свидетельствует о низкой конкуренции. В такой
          ситуации компании могут более свободно развиваться и экспериментировать. Однако необходимо оставаться
          бдительными и не упускать возможности для роста.
        </Paragraph>
        <Paragraph>
          Стоит отметить, что числовое значение, отображаемое на индикаторе, представляет собой общее количество
          действующих компаний, зарегистрированных в конкретном городе и оперирующих в одной и той же сфере бизнеса. Чем
          выше это число, тем более насыщенный рынок и более интенсивная конкуренция.
        </Paragraph>
        <Paragraph>
          Этот индикатор поможет бизнес-лидерам принимать обоснованные решения, а также адаптировать свои стратегии в
          зависимости от текущего уровня конкуренции.
        </Paragraph>
      </Description>

      <Description id={IndicatorsType.ECONOMIC}>
        <Paragraph>
          <Bold>Маркер репутации адреса</Bold> — это инновационный инструмент, который служит для оценки доверия к
          бизнес-адресам. Он анализирует историю использования адреса и выявляет, ассоциирован ли он с организациями,
          попавшими в негативные списки государственных органов. Индикатор визуализирует доверие и надежность через
          цветовую кодировку. <Green>Зеленый цвет</Green> символизирует чистоту и безопасность, указывая на отсутствие
          адреса в негативных реестрах государственных органов. В то время как <Red>красный цвет</Red> сигнализирует о
          потенциальных рисках, обозначая наличие компании с аналогичным адресом в реестре. Это ключевой индикатор
          призван помочь бизнес-партнерам и инвесторам минимизировать риски и обеспечить прозрачность сотрудничества.
        </Paragraph>
        <Paragraph>
          С помощью <Bold>маркера репутации адреса</Bold> вы сможете избежать связей с недобросовестными партнерами и
          уберечь свою репутацию от нежелательных ассоциаций. Этот инструмент — не просто фильтр, это залог вашей
          уверенности в принятии обоснованных решений.
        </Paragraph>
      </Description>

      <Description>
        <Paragraph>
          <Bold>Маркер уровня ликвидаций</Bold> представляет собой ключевой показатель, который отслеживает процентное
          соотношение ликвидированных предприятий к общему числу зарегистрированных компаний в конкретном городе,
          оперируя в одной сфере бизнеса. Основываясь на возрасте компаний, индикатор проводит детальный анализ,
          сопоставляя количество новых и прекративших своё существование предприятий, при этом особое внимание уделяется
          тем, чей возраст меньше или равен возрасту анализируемой компании.
        </Paragraph>
        <Paragraph>
          Данный индикатор особенно ценен для стратегического планирования и оценки рисков, так как позволяет
          предприятиям адаптироваться к меняющимся рыночным условиям, учитывая тенденции ликвидации в их секторе. С
          течением времени, увеличение возраста компании коррелирует с повышением процента ликвидаций, что служит важным
          индикатором для принятия обоснованных управленческих решений.
        </Paragraph>
        <Paragraph>
          Индикатор поможет оценить риски при принятии решений о расширении бизнеса или вложении средств. Высокий
          процент ликвидаций может быть сигналом о недостаточной устойчивости рынка.
        </Paragraph>
      </Description>
    </Container>
  );
};

export default IndicatorsDescription;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
`;

const Title = styled.h2`
  text-align: center;
`;

const Description = styled.div`
  text-align: justify;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Bold = styled.span`
  font-weight: 700;
`;

const Paragraph = styled.p`
  text-indent: 40px;
  padding: 0;
  margin: 0;
`;

const Red = styled.span`
  color: red;
  font-weight: 700;
`;

const Orange = styled.span`
  color: orange;
  font-weight: 700;
`;

const Green = styled.span`
  color: green;
  font-weight: 700;
`;
