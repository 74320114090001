export enum IndicatorsType {
  ADDRESS = 'address',
  JURIST = 'jurist',
  ROTATION = 'rotation',
  LEVEL = 'level',

  COMPETITION = 'competition',
  ECONOMIC = 'economic',
  LIQUIDATION = 'liquidation',

  KIND = 'kind',
}

export const scrollToIndicators = (type: string) => {
  const section = document.getElementById(type);
  section?.scrollIntoView({ behavior: 'smooth' });
};
