import React, { useEffect, useState } from 'react';
import { Divider, Spin } from 'antd';

import styled from 'styled-components';

import NewsSearch from '@app/components/dashboards/news/NewsSearch/NewsSearch';
import { MyStyles } from '@app/styles/themes/myStyles/myStyles';
import { useQuery } from '@tanstack/react-query';
import newsService from '@app/services/news.service';
import WordCloudTags, { IKeyWord } from '@app/components/tables/WordCloudTags/WordCloudTags';
import NewsItem from '@app/components/dashboards/news/NewsItem/NewsItem';
import { INews } from '@app/interfaces/news.interfaces';
import NewsLegends from '@app/components/dashboards/news/NewsLegends/NewsLegends';

const News: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const [params, setParams] = useState('');
  const [similarNews, setSimilarNews] = useState<INews[]>([]);
  const [similarity, setSimilarity] = useState<number | null>(null);
  const [isEnableTags, setIsEnableTags] = useState(true);
  const [keyWords, setKeyWords] = useState<IKeyWord[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const { data, isFetching } = useQuery({
    queryKey: [newsService.NEWS_KEY.COUNT_NEWS, params, page],
    queryFn: () => newsService.getAllNews(params, page),
  });

  const { data: newsData, isFetching: isFetchingSimilarity } = useQuery({
    queryKey: [newsService.NEWS_KEY.SIMILARITY, similarity],
    queryFn: () => newsService.getSimilarNews(similarity),
  });

  useEffect(() => {
    if (newsData?.data.results.length) {
      setSimilarNews(newsData.data.results);
    }
  }, [newsData]);

  useEffect(() => {
    if (data?.data.results && !isFetching) {
      setKeyWords(getKeyWords(data.data.results));
    }
  }, [data]);

  const onHandleSubmit = (query: string, fromDate: string, toDate: string) => {
    setSearchQuery(query);
    setKeyWords([]);
    const params =
      query.length === 0 || (query.length === 0 && !fromDate && !toDate)
        ? ''
        : `search=${query}&from=${fromDate}&to=${toDate}`;
    setPage(1);
    setParams(params);
  };

  const onPrevPage = () => {
    setPage((prevState) => Math.max(prevState - 1, 1));
  };

  const onNextPage = () => {
    if (!data?.data.next) return;
    setPage((prevState) => prevState + 1);
  };

  return (
    <Container>
      <div>
        <Title>Новости</Title>
        {!!keyWords.length && <WordCloudTags keyWords={keyWords} />}
        <NewsSearch onHandleSubmit={onHandleSubmit} />
        {!!data?.data.count && !!params && <CountNews>Количество найденных новостей: {data?.data.count}</CountNews>}
        <NewsLegends isEnableTags={isEnableTags} setIsEnableTags={setIsEnableTags} />
        <Divider />
      </div>
      {isFetching ? (
        <SpinnerSpace>
          <Spin size="large" tip="Загрузка данных . . ." />
        </SpinnerSpace>
      ) : (
        <>
          {data?.data.results &&
            data?.data.results.map((item, index) => (
              <NewsItem
                news={item}
                key={index}
                similarity={similarity}
                setSimilarity={setSimilarity}
                similarNews={similarNews}
                isFetchingNews={isFetchingSimilarity}
                isEnableTags={isEnableTags}
                innerNews={false}
                searchQuery={searchQuery}
              />
            ))}
          <ButtonContainer>
            <ButtonPage onClick={onPrevPage} disabled={page === 1}>
              Предыдущая страница
            </ButtonPage>
            <ButtonPage onClick={onNextPage} disabled={!data?.data.next}>
              Следующая страница
            </ButtonPage>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

export default News;

const getKeyWords = (data: INews[]): IKeyWord[] => {
  const wordsCloud = data.reduce<string[]>((acc, item) => {
    if (!item.entities) return acc;
    const entities = item.entities.map((entity) => entity.text);
    return [...acc, ...entities];
  }, []);

  const countObj: { [key: string]: number } = {};

  // Подсчитываем количество каждого элемента в массиве
  wordsCloud?.forEach((word: string) => {
    countObj[word] = (countObj[word] || 0) + 1;
  });

  return Object.entries(countObj).map(([name, value]) => ({
    value,
    name,
  }));
};

const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  margin-top: 20px;
  font-size: ${MyStyles.fontSize.pageTitle};
  color: ${MyStyles.colors.pageTitle};
  text-align: center;
`;

const CountNews = styled.div`
  text-align: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: ${MyStyles.width.container};
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonPage = styled.button`
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${MyStyles.primaryColor};
  color: #fff;

  &:disabled {
    background-color: gray;
    cursor: no-drop;
  }
`;

const SpinnerSpace = styled.div`
  flex-grow: 1;
  top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
