import React from 'react';
import KindIndicator from '@app/components/dashboards/profile-info/components/GeneralInformation/LevelOfReliability/KindIndicator';
import styled from 'styled-components';
import { httpAxios } from '@app/api/http.api';
import { DASH } from '@app/constants/enums/Dashboards';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useQuery } from '@tanstack/react-query';
import PercentIndex from '@app/components/dashboards/profile-info/components/GeneralInformation/LevelOfReliability/PercentIndex';
import PercentRating from '@app/components/dashboards/profile-info/components/GeneralInformation/LevelOfReliability/PercentRating';
import { ResponseDashboard } from '@app/interfaces/interfaces';

import * as S from '@app/components/dashboards/profile-info/styles/ProfileInfoStyles';
import { Divider, Skeleton } from 'antd';
import KindDescription from '@app/components/dashboards/profile-info/components/GeneralInformation/LevelOfReliability/KindDescription';
import KindDiagram from '@app/components/dashboards/profile-info/components/GeneralInformation/Indicators/components/KindDiagram/KindDiagram';

const getMinRating = (typeActivity: string | null, settlement: string | null) => {
  if (!typeActivity || !settlement) return;
  return httpAxios.get<ResponseDashboard>(
    DASH.BASE +
      DASH.TYPE_ACTIVITY(typeActivity) +
      DASH.ADDRESS_SETTLEMENT_ICONTAINS(settlement) +
      DASH.IS_NULL_FALSE('king') +
      DASH.PAGE_SIZE(100000) +
      DASH.ORDERING('king'),
  );
};

const LevelOfReliability: React.FC = () => {
  const statutuses = useAppSelector((state) => state.searchProfile.profile.statuses);
  const typeActivity = useAppSelector((state) => state.searchProfile.profile.types_activities[0]?.name);
  const settlement = useAppSelector((state) => state.searchProfile.profile.addresses[0]?.settlement);
  const metric_kind = useAppSelector((state) => state.searchProfile.profile.metric_king);
  const { data, isFetching } = useQuery({
    queryKey: ['minRating', typeActivity, settlement],
    queryFn: () => getMinRating(typeActivity, settlement),
    enabled: !!typeActivity && !!settlement,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
  });

  if (isFetching && statutuses[0].code === 'AT') {
    return <Skeleton active={true} />;
  }

  return (
    <>
      {Boolean(data?.data.results.length && statutuses[0].code === 'AT') && (
        <>
          <Container>
            {metric_kind[0] && (
              <>
                <S.Title style={{ textAlign: 'center' }}>Общие показатели субъекта</S.Title>
                <Top>
                  <LeftColumn>
                    <KindDescription />
                  </LeftColumn>
                  <RightColumn>
                    {metric_kind[0]?.king &&
                      data?.data?.results[0]?.king &&
                      data?.data?.results[data?.data?.results?.length - 1]?.king && (
                        <>
                          <KindDiagram
                            min={data?.data?.results[0]?.king}
                            max={data?.data?.results[data?.data?.results?.length - 1]?.king}
                            value={metric_kind[0]?.king}
                          />
                          <KindIndicator />
                        </>
                      )}
                  </RightColumn>
                </Top>
                <Divider />
              </>
            )}
            {data?.data && data?.data.results.length > 1 && (
              <>
                <RatingContainer>
                  <PercentIndex data={data?.data} />
                  <PercentRating data={data?.data} />
                </RatingContainer>
              </>
            )}
          </Container>
          <S.MyDivider />
        </>
      )}
    </>
  );
};

export default LevelOfReliability;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Top = styled.div`
  margin-right: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  @media (max-width: 800px) {
    margin-right: 0;
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  max-width: 650px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) {
    order: -1;
  }
`;

const RatingContainer = styled.div`
  display: flex;
  gap: 50px;

  @media (max-width: 1300px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
