import { MapBelarusObject } from '@app/store/types/landing/MapBelarusTypes';

export const stylesMap = {
  subregions: { fill: '#eeeeee', stroke: '#ffffff', strokeWidth: 0.5 },
  regions: { fill: 'none', stroke: '#ffffff', strokeWidth: 2 },
  circle: { fill: '#cccccc', stroke: 'none' },
};

export enum ENTITY_TYPE {
  LEGAL_ENTITY = 'Юридическое лицо',
  SOLE_TRADE = 'Индивидуальный предприниматель',
}

export const getCountFormEntityOfRegion = (
  region: string | null,
  entities: MapBelarusObject[],
  entityType: ENTITY_TYPE,
): number => {
  if (region === null) return 0;
  if (entities !== undefined) {
    for (const item of entities) {
      if (item?.address_region === region && item?.legal_form_entity_type === entityType) return item?.count_at;
    }
  }
  return 0;
};
