import React, { memo } from 'react';
import { WordCloud, WordCloudConfig } from '@ant-design/charts';

export type IKeyWord = {
  value: number;
  name: string;
};

type Props = {
  keyWords: IKeyWord[];
};

const WordCloudTags: React.FC<Props> = ({ keyWords }) => {
  const data = keyWords;

  const config: WordCloudConfig = {
    data,
    wordField: 'name',
    weightField: 'value',
    colorField: 'name',
    wordStyle: {
      fontFamily: 'Verdana',
      fontSize: [12, 32],
      rotation: 0,
    },
    random: () => 0.5,
  };

  return <WordCloud {...config} />;
};

export default memo(WordCloudTags);
