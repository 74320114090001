import styled from 'styled-components';

export const PlaceholderText = styled.div`
  font-size: 14px;
`;

export const filterStyle = {
  width: '100%',
  marginRight: '12px',
  marginLeft: '12px',
};
