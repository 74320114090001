import React from 'react';
import Checkbox from 'react-custom-checkbox';
import { CheckOutlined } from '@ant-design/icons';

import styled from 'styled-components';
import { MyStyles } from '@app/styles/themes/myStyles/myStyles';
import './styles.css';

type Props = {
  isEnableTags: boolean;
  setIsEnableTags: (value: boolean) => void;
};

const NewsLegends: React.FC<Props> = ({ isEnableTags, setIsEnableTags }) => {
  return (
    <Container>
      <ItemBox>
        <Item color={MyStyles.news.color.location}>Местоположение</Item>
        <Item color={MyStyles.news.color.organization}>Организация</Item>
        <Item color={MyStyles.news.color.person}>Персона</Item>
      </ItemBox>
      <Form>
        <Checkbox
          checked={isEnableTags}
          onChange={(value: boolean) => {
            setIsEnableTags(value);
          }}
          className={'checkbox'}
          icon={
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 10,
              }}
            >
              <CheckOutlined />
            </div>
          }
          borderWidth={1}
          borderColor={'#000'}
          borderRadius={2}
          size={16}
          label={`${isEnableTags ? 'выключить' : 'включить'} подсвечивание объектов`}
          labelStyle={{ marginLeft: 5, userSelect: 'none', cursor: 'pointer' }}
        />
        {/*<Checkbox*/}
        {/*  type={'checkbox'}*/}
        {/*  name={'news-tags'}*/}
        {/*  id={'news-tags'}*/}
        {/*  onChange={() => setIsEnableTags(!isEnableTags)}*/}
        {/*  checked={isEnableTags}*/}
        {/*/>*/}
        {/*<Label htmlFor="news-tags">{isEnableTags ? 'выключить' : 'включить'} подсвечивание объектов</Label>*/}
      </Form>
    </Container>
  );
};

export default NewsLegends;

const Container = styled.div`
  margin: 0 auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ItemBox = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

type ItemProps = {
  color: string;
};

const Item = styled.div<ItemProps>`
  padding: 5px 10px;
  background-color: ${(props) => props.color};
  color: #fff;
`;

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: flex-start;
`;

const Label = styled.label`
  user-select: none;
`;

// const Checkbox = styled.input`
//   width: 15px;
//   height: 15px;
// `;
