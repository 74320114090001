import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import './style.css';

import telegram from '../../../../assets/landing/subscribeTelegram/subscribe_telegram.jpg';
import { MyStyles } from '@app/styles/themes/myStyles/myStyles';

const SubscribeTelegramModal: React.FC = () => {
  const [isModelVisible, setIsModalVisible] = useState(false);

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleSubscribe = () => {
    window.open('https://t.me/analytix_by', '_blank');
    setIsModalVisible(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsModalVisible(true);
    }, 4000);
  }, []);

  return isModelVisible ? (
    <ModalWrapper>
      <ModalContent>
        <SubscribeTelegramContainer>
          <Image src={telegram} alt={telegram} />
          <ButtonContainer>
            <ButtonSubscribe onClick={handleSubscribe}>Подписаться</ButtonSubscribe>
            <ButtonCancel onClick={closeModal}>Закрыть</ButtonCancel>
          </ButtonContainer>
        </SubscribeTelegramContainer>
      </ModalContent>
    </ModalWrapper>
  ) : (
    <div></div>
  );
};

export default SubscribeTelegramModal;

const ModalWrapper = styled.div`
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const ModalContent = styled.div`
  width: 500px;
  height: auto;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;

  @media (max-height: 600px) {
    padding: 10px;
  }
`;

const SubscribeTelegramContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const StyledButton = styled.button`
  padding: 5px 20px;
  border: 1px solid #000;
  border-radius: 8px;
  cursor: pointer;
  background-color: #fff;
  color: black;
`;

const ButtonSubscribe = styled(StyledButton)`
  background-color: #38ade3;
  color: #fff;
  border-color: #38ade3;

  &:hover {
    background-color: #2b97c9;
  }
`;

const ButtonCancel = styled(StyledButton)`
  &:hover {
    background-color: ${MyStyles.focusColor};
  }
`;
