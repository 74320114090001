import styled from 'styled-components';
import { MyStyles } from '@app/styles/themes/myStyles/myStyles';

export const Container = styled.div`
  margin-inline: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 400px) {
    margin-inline: 4px;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

export const GaugeContainer = styled.div`
  width: 120px;
  height: 80px;

  @media (max-width: 400px) {
    width: 100px;
    height: 60px;
  }
`;

export const Title = styled.span`
  width: 120px;
  font-size: 12px;
  text-align: center;
  color: ${MyStyles.primaryColor};
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RightColumnColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.div`
  font-size: 0.9374rem;
`;

export const ButtonMoreDetails = styled.div`
  font-size: 0.9374rem;
  color: #000;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    color: ${MyStyles.primaryColor};
  }
`;
