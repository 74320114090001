import { httpAxios } from '@app/api/http.api';

import { URLS } from '@app/constants/Constants';
import { IceTradeObject, MonthCount, ResponseIceTrade, YearCount } from '@app/interfaces/iceTrade.interfaces';

class IceTradeService {
  private readonly COMPLETED = 'Состоялась';
  // private readonly NOT_COMPLETED = 'Не состоялась';

  filterCustomers(iceTrades: IceTradeObject[], name: string | null): IceTradeObject[] {
    return iceTrades.filter((item) => item.company_short_name === name);
  }

  filterParticipant(iceTrades: IceTradeObject[], name: string | null): IceTradeObject[] {
    return iceTrades.filter((item) => item.company_short_name_participant === name);
  }

  getIceTradesByUnn(id: string | null) {
    return httpAxios.get<ResponseIceTrade>(`${URLS.ICE_TRADE}legal_entity_id=${id}`);
  }

  getAllCount(iceTrade: IceTradeObject[]): number {
    return iceTrade.length || 0;
  }

  getCompletedCount(iceTrade: IceTradeObject[]): number {
    return iceTrade.filter((item) => item.purchase_status?.toLowerCase() === this.COMPLETED.toLowerCase()).length;
  }

  getNotCompletedCount(iceTrade: IceTradeObject[]): number {
    return iceTrade.filter((item) => item.purchase_status?.toLowerCase() !== this.COMPLETED.toLowerCase()).length;
  }

  getCompleted(iceTrade: IceTradeObject[]): IceTradeObject[] {
    return iceTrade.filter((item) => item.purchase_status?.toLowerCase() === this.COMPLETED.toLowerCase());
  }

  getCompletedByAge(iceTrades: IceTradeObject[]): YearCount | null {
    const result = iceTrades.filter((item) => item.purchase_status === 'Состоялась' && item.contract_date);
    if (!Boolean(result.length)) return null;
    return result.reduce<YearCount>((acc, obj) => {
      if (obj.contract_date) {
        const year = new Date(obj.contract_date).getFullYear();
        acc[year] = (acc[year] || 0) + 1;
        return acc;
      }
      return acc;
    }, {});
  }

  getCompletedByMonth(iceTrades: IceTradeObject[]): MonthCount | null {
    const lastYearDate = new Date();
    lastYearDate.setFullYear(new Date().getFullYear() - 1);
    const result = iceTrades.filter((item) => {
      if (item.contract_date && item.purchase_status === 'Состоялась') {
        const itemDate = new Date(item.contract_date);
        return itemDate >= lastYearDate;
      }
      return false;
    });

    if (!Boolean(result.length)) return null;

    return result.reduce<MonthCount>((acc, obj) => {
      if (obj.contract_date) {
        const month = new Date(obj.contract_date).getMonth() + 1;
        console.log('month');
        acc[month] = (acc[month] || 0) + 1;
        return acc;
      }
      return acc;
    }, {});
  }
}

export default new IceTradeService();
