import React, { useState } from 'react';
import styled from 'styled-components';
import { MyStyles } from '@app/styles/themes/myStyles/myStyles';
import SettlementsMap from '@app/components/dashboards/interactiveMap/SettlementsMap/SettlementsMap';
import RegionsMap from '@app/components/dashboards/interactiveMap/RegionsMap/RegionsMap';
import LegendMap from '@app/components/dashboards/interactiveMap/LegendMap/LegendMap';
import SliderDate from '@app/components/dashboards/interactiveMap/SliderDate/SliderDate';
import { MapBelarusType } from '@app/store/types/landing/MapBelarusTypes';
import { setDate, setDistrict, setRegion, setSettlement } from '@app/store/slices/search/searchFiltersSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';

enum TabsEnum {
  REGIONS = 'regions',
  SETTLEMENTS = 'settlements',
}

const InteractiveMap = () => {
  const [tab, setTab] = useState(TabsEnum.SETTLEMENTS);
  const [dateRange, setDateRange] = useState<[number, number]>([1970, new Date().getFullYear()]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleTab = (value: TabsEnum) => {
    setTab(value);
  };

  const handleClick = (value: string, type: MapBelarusType) => {
    switch (type) {
      case MapBelarusType.SETTLEMENT: {
        dispatch(setSettlement(value));
        break;
      }
      case MapBelarusType.DISTRICT: {
        dispatch(setDistrict(value));
        break;
      }
      case MapBelarusType.REGION: {
        dispatch(setRegion(value));
        break;
      }
    }
    dispatch(setDate([`${dateRange[0]}-01-01`, `${dateRange[1]}-01-01`]));
    navigate(`/legal-entity`);
  };

  return (
    <Container>
      <Tabs>
        <Tab isActive={TabsEnum.SETTLEMENTS === tab} onClick={() => handleTab(TabsEnum.SETTLEMENTS)}>
          По городам и районам
        </Tab>
        <Tab isActive={TabsEnum.REGIONS === tab} onClick={() => handleTab(TabsEnum.REGIONS)}>
          По областям
        </Tab>
      </Tabs>
      <MapContainerWrapper>
        <SliderDate dateRange={dateRange} setDateRange={setDateRange} />
        <Info></Info>
        <MapContainer>
          {tab === TabsEnum.SETTLEMENTS && <SettlementsMap handleClick={handleClick} dateRange={dateRange} />}
          {tab === TabsEnum.REGIONS && <RegionsMap handleClick={handleClick} dateRange={dateRange} />}
          <LegendMap />
        </MapContainer>
      </MapContainerWrapper>
    </Container>
  );
};

export default InteractiveMap;

const Container = styled.div`
  margin-top: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Tabs = styled.div`
  display: flex;
  gap: 20px;
`;

interface TabProps {
  isActive: boolean;
}

const Tab = styled.div<TabProps>`
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  background-color: ${(props) => (props.isActive ? MyStyles.primaryColor : '#fff')};
  color: ${(props) => (props.isActive ? '#fff' : '#000')};
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

const MapContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

const MapContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Info = styled.div``;
