import React from 'react';
import { Table } from 'antd';
import { IInvestmentAbroadObject } from '@app/components/dashboards/china/type/china.interfaces';
import styled from 'styled-components';

interface Props {
  title: string;
  keys: string[];
  data: IInvestmentAbroadObject[];
}

const ChinaTable: React.FC<Props> = ({ title, keys, data }) => {
  const columns = keys.map((key) => {
    return {
      title: splitByUppercase(key),
      dataIndex: key,
      key: key,
    };
  });

  return (
    <Container>
      <Title>{title}</Title>
      <Table dataSource={data} columns={columns} size={'small'} pagination={{ size: 'small', pageSize: 5 }} />
    </Container>
  );
};

export default ChinaTable;

const Container = styled.div`
  margin-top: 20px;
  flex-direction: column;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 24px;
  font-weight: 700;
`;

function splitByUppercase(str: string): string {
  return str
    .split(/(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join(' ');
}
