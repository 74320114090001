export interface MapBelarusSlice {
  countEntitiesOfMap: MapBelarusObject[];
  legalEntity: number;
  soleTrade: number;
  isLoadingLegalEntity: boolean;
  isLoadingSoleTrade: boolean;
}

export interface MapBelarusObject {
  legal_form_entity_type: string;
  address_full: string;
  address_region: string;
  address_settlement: string;
  count_at: number;
}

export interface ResponseCountMapBelarus {
  count: number;
}

export enum MapBelarusType {
  DISTRICT,
  SETTLEMENT,
  REGION,
}
