import React, { ReactNode } from 'react';
import { Gauge, GaugeConfig } from '@ant-design/charts';

import * as S from '@app/components/dashboards/profile-info/components/GeneralInformation/Indicators/components/styles/MeterGaugeStyles';
import { MyStyles } from '@app/styles/themes/myStyles/myStyles';

const ADDRESS_LISTED = 'Адрес значится в реестре неблагонадежных субъектов';

type MyComponentProps = {
  risk: string;
  name: string;
  description: ReactNode;
};

const MetricAddressEconomicHighRiskRegistry: React.FC<MyComponentProps> = ({ risk, name, description }) => {
  const config: GaugeConfig = {
    percent: getRiskLevel(risk),
    range: {
      color: getColorByRisk(risk),
    },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: false,
  };

  return (
    <S.Container>
      <S.LeftColumn>
        <S.GaugeContainer>
          <Gauge {...config} style={{ height: '100%', width: '100%', padding: 0 }} />
        </S.GaugeContainer>
        <S.Title>{name}</S.Title>
      </S.LeftColumn>
      <S.RightColumnColumn>
        <S.Description>{description}</S.Description>
        <a href={`https://analytix.by/indicators/econimic`} target="_blank" rel="noreferrer">
          <S.ButtonMoreDetails>Подробнее</S.ButtonMoreDetails>
        </a>
      </S.RightColumnColumn>
    </S.Container>
  );
};

export default MetricAddressEconomicHighRiskRegistry;

const getRiskLevel = (risk: string): number => {
  if (risk === ADDRESS_LISTED) {
    return 1;
  } else {
    return 0.2;
  }
};

const getColorByRisk = (risk: string): string => {
  if (risk === ADDRESS_LISTED) {
    return MyStyles.indicators.colors.red;
  } else {
    return MyStyles.indicators.colors.green;
  }
};
