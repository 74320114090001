import React from 'react';
import { Column } from '@ant-design/plots';
import { ColumnConfig } from '@ant-design/charts';

import { MonthCount } from '@app/interfaces/iceTrade.interfaces';
import * as S from '@app/components/dashboards/profile-info/components/IceTradesTest/styles/IceTradeStyles';

type IceTradesByMonthProps = {
  data: MonthCount;
};

const IceTradesByMonth: React.FC<IceTradesByMonthProps> = ({ data }) => {
  const result = Object.keys(data).map((month) => ({
    type: month,
    sales: data[parseInt(month, 10)],
  }));

  const config: ColumnConfig = {
    data: result ? result : [],
    xField: 'type',
    yField: 'sales',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    columnStyle: {
      cursor: 'pointer',
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: 'Месяц',
      },
      sales: {
        alias: 'Количество',
      },
    },
  };

  return (
    <S.Container>
      <S.Title>Сведения о количестве состоявщихся закупок за последние 12 месяцев</S.Title>
      <Column style={{ width: '100%' }} {...config} />;
    </S.Container>
  );
};

export default IceTradesByMonth;
