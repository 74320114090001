import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './DashboardPage.styles';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { References } from '@app/components/common/References/References';
import News from '@app/components/dashboards/news/News';

const NewsPage: React.FC = () => {
  const { t } = useTranslation();

  const desktopLayout = (
    <S.LeftSideCol id="desktop-content">
      <News />
      <References />
    </S.LeftSideCol>
  );

  return (
    <>
      <PageTitle>{t('common.analytix')}</PageTitle>
      {desktopLayout}
    </>
  );
};

export default NewsPage;
