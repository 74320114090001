import React from 'react';
import { IceTradeObject } from '@app/interfaces/iceTrade.interfaces';

import * as S from '../styles/IceTradeStyles';
import { Table } from 'antd';

type Props = {
  iceTrades: IceTradeObject[];
};

const ParticipantsList: React.FC<Props> = ({ iceTrades }) => {
  const groupedData = iceTrades.reduce<Record<string, { vsb_participant: string; totalPrice: number }>>((acc, item) => {
    const participant = item.company_short_name_participant;
    if (participant) {
      if (!acc[participant]) {
        acc[participant] = { vsb_participant: item.vsb_participant || '', totalPrice: 0 };
      }
      acc[participant].totalPrice += item.price_byn || 0;
    }
    return acc;
  }, {});

  const resultArray = Object.keys(groupedData).map((name) => ({
    unn: groupedData[name].vsb_participant,
    name: name,
    totalPrice: groupedData[name].totalPrice,
  }));

  const columns = [
    {
      title: <S.TableTitle>УНП</S.TableTitle>,
      dataIndex: 'unn',
      key: 'unn',
      render: (text: string) => <S.TableContent>{text}</S.TableContent>,
    },
    {
      title: <S.TableTitle>Наименование</S.TableTitle>,
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <S.TableContent>{text}</S.TableContent>,
    },
    {
      title: <S.TableTitle>Сумма</S.TableTitle>,
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (text: string) => <S.TableContent>{Number(text).toFixed(1)}</S.TableContent>,
    },
  ];

  return (
    <S.Container>
      <S.Title>Сведения об исполнителях</S.Title>
      <Table style={{ width: '100%' }} dataSource={resultArray} columns={columns} pagination={{ size: 'small' }} />
    </S.Container>
  );
};

export default ParticipantsList;
