import React, { useState } from 'react';

import * as S from '../styles/styles';

import { MapBelarusType } from '@app/store/types/landing/MapBelarusTypes';
import { harady, subRegions } from '@app/components/dashboards/mainLanding/MapBelarus/data/mapBelarusData';
import InfoWindow from '@app/components/dashboards/interactiveMap/InfoWindow/InfoWindow';
import '../styles/styles.css';
import { stylesMap } from '@app/components/dashboards/interactiveMap/utils';

type Props = {
  handleClick: (value: string, type: MapBelarusType) => void;
  dateRange: [number, number];
};

const SettlementsMap: React.FC<Props> = ({ handleClick, dateRange }) => {
  const [region, setRegion] = useState<string>('');
  const [type, setType] = useState(MapBelarusType.DISTRICT);
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseOver = function (event: React.MouseEvent<SVGElement>) {
    const region = (event.target as SVGElement).getAttribute('data-region');
    setRegion(region || '');
  };

  const handleMouseOverCircle = function (event: React.MouseEvent<SVGElement>) {
    const city = (event.target as SVGElement).getAttribute('data-city');
    const currentR = (event.target as SVGElement).getAttribute('r');
    const newR = currentR ? parseInt(currentR) + 2 : 4;
    (event.target as SVGElement).setAttribute('r', String(newR));

    setRegion(city || '');
  };

  const handleMouseOutCircle = function (event: React.MouseEvent<SVGElement>) {
    const currentR = (event.target as SVGElement).getAttribute('r');
    const newR = currentR ? parseInt(currentR) - 2 : 4;
    (event.target as SVGElement).setAttribute('r', String(newR));
  };

  const handleMouseEnter = (event: React.MouseEvent, value: string, type: MapBelarusType) => {
    setVisible(true);
    setPosition({ x: event.clientX - 100, y: event.clientY + 40 });
    setRegion(value || '');
    setType(type);
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    setPosition({ x: event.clientX - 100, y: event.clientY + 40 });
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <S.Container>
      <S.MapBelarusContainer>
        <S.ImageContainer>
          <svg
            version="1.1"
            id="Belarus"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 550 490"
            enableBackground="new 0 0 550 490"
            xmlSpace="preserve"
          >
            <style>
              {`
                        #subregions {${Object.entries(stylesMap.subregions)
                          .map(([key, value]) => `${key}:${value};`)
                          .join('')}}
                        #regions {${Object.entries(stylesMap.regions)
                          .map(([key, value]) => `${key}:${value};`)
                          .join('')}}
                        circle {${Object.entries(stylesMap.circle)
                          .map(([key, value]) => `${key}:${value};`)
                          .join('')}}
                    `}
            </style>

            <g id="regions">
              {subRegions.map((region, index) => {
                return (
                  <polygon
                    id={region.id}
                    className={region.className}
                    data-region={region.region_name}
                    points={region.points}
                    onMouseOver={handleMouseOver}
                    onMouseEnter={(event) => handleMouseEnter(event, region.region_name, MapBelarusType.DISTRICT)}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleClick(region.region_name, MapBelarusType.DISTRICT)}
                    key={index}
                  />
                );
              })}
            </g>

            <g id="harady">
              {harady.map((city, index) => {
                return (
                  <circle
                    id={city.id}
                    data-city={city.city_name}
                    cy={city.cy}
                    cx={city.cx}
                    r={city.r}
                    className="st2"
                    onMouseOver={handleMouseOverCircle}
                    onMouseOut={handleMouseOutCircle}
                    onMouseEnter={(event) => handleMouseEnter(event, city.city_name, MapBelarusType.SETTLEMENT)}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleClick(city.city_name, MapBelarusType.SETTLEMENT)}
                    key={index}
                  />
                );
              })}
            </g>
          </svg>
        </S.ImageContainer>
      </S.MapBelarusContainer>
      {visible && <InfoWindow x={position.x} y={position.y} name={region} type={type} dateRange={dateRange} />}
    </S.Container>
  );
};

export default SettlementsMap;
