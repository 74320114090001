import React from 'react';
import { Line, LineConfig } from '@ant-design/charts';

import * as S from '../styles/IceTradeStyles';

import { YearCount } from '@app/interfaces/iceTrade.interfaces';

type IceTradeByAgeProps = {
  data: YearCount;
};

const IceTradesByAge: React.FC<IceTradeByAgeProps> = ({ data }) => {
  const result = Object.keys(data).map((year) => ({
    year: parseInt(year, 10),
    value: data[parseInt(year, 10)],
  }));

  const config: LineConfig = {
    data: result ? result : [],
    xField: 'year',
    yField: 'value',
    label: {},
    point: {
      size: 5,
      shape: 'dot',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
        cursor: 'pointer',
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
    meta: {
      year: {
        alias: 'Год',
      },
      value: {
        alias: 'Количество',
      },
    },
  };

  return (
    <S.Container>
      <S.Title>Сведения о количестве состоявщихся закупок в динамике по годам</S.Title>
      <Line style={{ width: '100%' }} {...config}></Line>
    </S.Container>
  );
};

export default IceTradesByAge;
