import React from 'react';
import styled from 'styled-components';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getColorByKind } from '@app/utils/utils';

type Props = {
  min: number;
  max: number;
  value: number;
};

const KindDiagram: React.FC<Props> = ({ min, max, value }) => {
  const metric_kind = useAppSelector((state) => state.searchProfile.profile.metric_king);
  const percent = value / max;

  return (
    <Container>
      <Background>
        <Percentage
          kind_group={metric_kind[0]?.king_group ? Number(metric_kind[0]?.king_group) : 0}
          percent={percent}
        ></Percentage>
        <PercentageBackground />
        <Mask></Mask>
        <Value kind_group={metric_kind[0]?.king_group ? Number(metric_kind[0]?.king_group) : 0}>
          {metric_kind[0].king_group}
        </Value>
      </Background>
      <Min>0</Min>
      <Max>9</Max>
    </Container>
  );
};

export default KindDiagram;

const Container = styled.div`
  padding-top: 40px;
  width: 200px;
  height: 200px;
`;
const Background = styled.div`
  position: relative;
  height: 100px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 150px 150px 0 0;
  overflow: hidden;
  text-align: center;
`;

type PercentageProps = {
  kind_group: number;
  percent: number;
};

const Percentage = styled.div<PercentageProps>`
  position: absolute;
  top: 100px;
  left: -200%;
  width: 400%;
  height: 400%;
  margin-left: 100px;
  background-color: ${(props) => getColorByKind(props.kind_group)};
  transform: ${(props) => `rotate(${((props.kind_group + 1) / 10) * 180}deg)`};
  transform-origin: top center;
  z-index: 1;
`;

const PercentageBackground = styled.div`
  position: absolute;
  top: 100px;
  left: -200%;
  width: 400%;
  height: 400%;
  margin-left: 100px;
  background-color: #c4bfbf;
  transform: rotate(180deg);
  transform-origin: top center;
`;

const Mask = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  left: 20px;
  height: 80px;
  background-color: #fff;
  border-radius: 150px 150px 0 0;
  z-index: 2;
`;

type ValueProps = {
  kind_group: number;
};

const Value = styled.span<ValueProps>`
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  font-size: 48px;
  font-weight: 700;
  z-index: 3;
  color: ${(props) => getColorByKind(props.kind_group)}};
`;
const Min = styled.span`
  float: left;
`;
const Max = styled.span`
  float: right;
`;

// const getColorByKind = (kind: number | null): string => {
//   if (!kind) return 'red';
//   if (kind <= 131) {
//     return 'red';
//   } else if (kind > 131 && kind <= 190) {
//     return 'orange';
//   } else {
//     return 'green';
//   }
// };

// const getColor = (percent: number): string => {
//   if (percent > 66) {
//     return 'green';
//   } else if (percent > 33 && percent <= 66) {
//     return 'orange';
//   } else {
//     return 'red';
//   }
// };
