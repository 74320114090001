import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { URLS } from '@app/constants/Constants';
import styled from 'styled-components';

import * as S from '@app/components/dashboards/profile-info/styles/ProfileInfoStyles';

import { SeoResponse } from '@app/api/seo.api';
import LineText from '@app/components/dashboards/profile-info/components/components/Lines/LineText';
import {
  contactInformationBase64,
  currentCompetitorsBase64,
  dynamicRegistrationBase64,
  indecsBase64,
  licencesBase64,
  markersRisk,
  otherInformationBase64,
  positionBusinessBase64,
  riskOfMarket,
  statisticCompetitorsBase64,
} from '@app/components/dashboards/demo-profile-info/data/data';

const GeneralInformationSEO: React.FC = () => {
  const [subject, setSubject] = useState<SeoResponse | undefined>(undefined);

  const { unp } = useParams();

  useEffect(() => {
    if (Boolean(unp)) {
      const response = axios.get<SeoResponse[]>(URLS.SEO, {
        params: { unn: unp },
      });

      response.then((res) => {
        setSubject(res.data[0]);
      });
    }
  }, [unp]);

  return (
    <Container>
      {subject && (
        <>
          <Title>{subject?.company_full_name || subject?.company_short_name}</Title>
          <S.MyDivider />
          <S.Title>Общие показатели субъекта</S.Title>

          <Image src={indecsBase64} alt="indecs" />

          <S.MyDivider />
          <Title>Маркеры возможных рисков</Title>
          <Image src={markersRisk} alt="markers" />
          <Title>Риски рынка, которые могут повлиять на деятельность компании</Title>
          <Image src={riskOfMarket} alt="markers" />
          <S.MyDivider />
          <S.Title>Регистрационные данные</S.Title>
          <S.ContainerLineText>
            <LineText name={'УНП'} content={subject?.legal_entity_id} />
            <LineText name={'Сокращенное наименование'} content={subject.company_short_name} />
            <LineText name={'Полное наименование'} content={subject.company_full_name} />
            <LineText name={'Адресс'} content={subject.address_full} />
            <LineText name={'Вид деятельности'} content={subject.type_activity_name} />
            <LineText name={'Дата регистрации'} content={subject.company_date_registration} isDate={true} />
            <LineText name={'Возраст'} content={subject.age_short.toString()} />
            <LineText name={'Статус'} content={subject.company_status_name} />
            <LineText name={'Регистрирующий орган'} content={subject.state_body_cur_name} />
            <LineText name={'Налоговый учет'} content={subject.tax_office_name} />
          </S.ContainerLineText>
          <S.MyDivider />
          <S.Title>Контактная информация</S.Title>
          <Image src={contactInformationBase64} alt="contacts" />

          <S.MyDivider />
          <S.Title>Лицензии и иные государственные решения</S.Title>
          <Image src={licencesBase64} alt="contacts" />

          <S.MyDivider />
          <S.Title>Иная значимая информация</S.Title>
          <Image src={otherInformationBase64} alt="information" />

          <S.MyDivider />
          <S.Title>Позиция среди компаний с аналогичным видом деятельности</S.Title>
          <Image src={positionBusinessBase64} alt="position" />

          <S.MyDivider />
          <S.Title>Действующие конкуренты в населенном пункте с аналогичным видом деятельности</S.Title>
          <Image src={currentCompetitorsBase64} alt="current" />

          <S.MyDivider />
          <S.Title>Статистика регистраций конкурентов по годам</S.Title>
          <Image src={statisticCompetitorsBase64} alt="statistic" />

          <S.MyDivider />
          <S.Title>Динамика регистраций конкурентов по месяцам (данные за последние 6 месяцев)</S.Title>
          <Image src={dynamicRegistrationBase64} alt="dynamics" />
        </>
      )}
    </Container>
  );
};

export default GeneralInformationSEO;

const Container = styled.div`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
`;

const Title = styled.h1`
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 50px;
`;

const Image = styled.img`
  width: 100%;
`;
