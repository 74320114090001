import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import InfoTooltip from '@app/components/dashboards/profile-info/components/components/Buttons/InfoTooltip/InfoTooltip';
import styled from 'styled-components';
import { getColorByKind } from '@app/utils/utils';

const KindIndicator: React.FC = () => {
  const metric_kind = useAppSelector((state) => state.searchProfile.profile.metric_king);
  const [color, setColor] = useState('black');

  useEffect(() => {
    setColor(getColorByKind(Number(metric_kind[0]?.king_group)));
    // setPercent(getPercentByKind(metric_kind[0]?.king_group));
  }, [metric_kind]);

  return (
    <>
      {metric_kind[0]?.king_group && (
        <Container>
          <KindInfo>
            <Text>
              <Name>Значение индекса</Name> - <Content color={color}>{metric_kind[0].king_group}</Content>{' '}
              <InfoTooltip
                description="Индекс уровня надежности — это скоринговый аналитический показатель, рассчитываемый на основе публично доступной информации о деятельности юридического лица. Он помогает оценить надежность или ненадежность контрагента. Индекс уровня надежности для анализа компаний использует только официальные источники данных, такие как регистрационные сведения, данные о деятельности и факторы риска.
Для расчета индекса система учитывает более 30 различных факторов, что позволяет дать оценку степени благонадежности фирмы-партнера и погасить риски ведения бизнеса с ней. Индекс уровня надежности находится в диапазоне от 0 до 9. Чем выше значение, тем меньше риск, что компания может быть ненадежной.
Данный инструмент помогает повысить прозрачность бизнеса, а также более точно выявлять фирмы, с которыми сотрудничество может быть рискованным.
Индекс уровня надежности является важным инструментом для инвесторов, аналитиков и бизнес-партнеров, помогая им принимать обоснованные решения на основе объективных данных о компаниях."
              />
            </Text>
            <Text>
              <Name>Комплексная оценка</Name> - <Content color={color}>{metric_kind[0].king}</Content> баллов{' '}
              <InfoTooltip description="Комплексная оценка - это числовой показатель в виде баллов, набранных компанией при расчете индекса уровня надежности. Чем выше значение, тем более положительных факторов учтено в деятельности компании." />
            </Text>
          </KindInfo>
        </Container>
      )}
    </>
  );
};

export default KindIndicator;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const KindInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.span`
  font-size: 16px;
`;

type ContentProps = {
  color: string;
};

const Content = styled.span<ContentProps>`
  font-weight: 700;
  color: ${(props) => props.color};
`;

const Name = styled.span`
  font-weight: 550;
`;
