import { httpAxios } from '@app/api/http.api';
import { IResponseNews } from '@app/interfaces/news.interfaces';
import { URLS } from '@app/constants/Constants';

class NewsService {
  NEWS_KEY = {
    COUNT_NEWS: 'countAllNews',
    SIMILARITY: 'similarityNews',
  };

  getAllNews = async (params: string, page: number) => {
    let URL = !!params ? `${URLS.NEWS}${params}` : URLS.NEWS;
    URL += `&page=${page}`;
    return httpAxios.get<IResponseNews>(URL);
  };

  getSimilarNews = async (similarity: number | null) => {
    if (!similarity) return null;
    const URL = `${URLS.NEWS}similarity=${similarity}`;
    return httpAxios.get<IResponseNews>(URL);
  };
}

export default new NewsService();
