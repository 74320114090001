import React from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import MeterGaugePlot from '@app/components/dashboards/profile-info/components/GeneralInformation/Indicators/components/MeterGaugePlot/MeterGaugePlot';
import styled from 'styled-components';
import * as S from '@app/components/dashboards/profile-info/styles/ProfileInfoStyles';
import MetricAddressEconomicHighRiskRegistry from '@app/components/dashboards/profile-info/components/GeneralInformation/Indicators/components/MetricAddressEconomicHighRiskRegistry/MetricAddressEconomicHighRiskRegistry';
import LegendMap from '@app/components/dashboards/profile-info/components/GeneralInformation/Indicators/components/LegendMap/LegendMap';
import { IndicatorsType } from '@app/components/dashboards/profile-info/components/GeneralInformation/Indicators/components/IdicatorsDescription/utils';

const Indicators: React.FC = () => {
  const statuses = useAppSelector((state) => state.searchProfile.profile.statuses);
  const metric_address_2 = useAppSelector((state) => state.searchProfile.profile.metric_address_2);
  const metric_change_constituent_doc = useAppSelector(
    (state) => state.searchProfile.profile.metric_change_constituent_doc,
  );
  const metric_change_director = useAppSelector((state) => state.searchProfile.profile.metric_change_director);
  const metric_entity_contact = useAppSelector((state) => state.searchProfile.profile.metric_entity_contact);
  const metric_level_competition = useAppSelector((state) => state.searchProfile.profile.metric_level_competition);
  const metric_probability_liquidation = useAppSelector(
    (state) => state.searchProfile.profile.metric_probability_liquidation,
  );
  const metric_address_economic_high_risk_registry = useAppSelector(
    (state) => state.searchProfile.profile.metric_address_economic_high_risk_registry,
  );

  return (
    <>
      {statuses[0]?.code === 'AT' && (
        <Container>
          <LegendMap />
          <S.Title>Маркеры возможных рисков</S.Title>
          {metric_address_2[0]?.risk_level && (
            <MeterGaugePlot
              risk={metric_address_2[0].risk_level}
              name={'Смена адреса'}
              content={metric_address_2[0]?.count_changes}
              description={
                'Индикатор частоты смены юридического адреса — это количественный показатель, предназначенный для мониторинга стабильности и надежности компании. Он отслеживает количество изменений юридического адреса организации за определенный период времени.'
              }
              type={IndicatorsType.ADDRESS}
            />
          )}
          {metric_change_constituent_doc[0]?.risk_level && (
            <MeterGaugePlot
              risk={metric_change_constituent_doc[0].risk_level}
              name={'Юридическая активность'}
              content={metric_change_constituent_doc[0]?.count_activity}
              description={
                'Индекс юридической активности представляет собой композитный показатель, который количественно оценивает юридическую активность компании на основе регулярности и значимости юридических действий, осуществляемых в государственных органах управления.'
              }
              type={IndicatorsType.JURIST}
            />
          )}
          {metric_change_director[0]?.risk_level && (
            <MeterGaugePlot
              risk={metric_change_director[0].risk_level}
              name={'Управленческая ротация'}
              content={metric_change_director[0]?.count_changes}
              description={
                'Индекс управленческой ротации — это метрика, которая измеряет частоту изменения управленческого состава организации (руководителей). Этот индекс служит индикатором стабильности управленческой структуры и её влияния на операционную эффективность предприятия.'
              }
              type={IndicatorsType.ROTATION}
            />
          )}
          {metric_entity_contact[0]?.risk_level && (
            <MeterGaugePlot
              risk={metric_entity_contact[0].risk_level}
              name={'Открытость'}
              content={metric_entity_contact[0].sum_count}
              description={
                'Маркер уровня открытости — это передовой инструмент, предназначенный для оценки доступности компании. Он анализирует и визуализирует количество доступных каналов связи, таких как телефон, электронная почта и веб-сайты, предоставляя потенциальным клиентам и партнерам ясное представление о том, насколько легко установить контакт с компанией.'
              }
              type={IndicatorsType.LEVEL}
            />
          )}
          <S.Title>Риски рынка, которые могут повлиять на деятельность компании</S.Title>
          <TopLine>
            {metric_level_competition[0]?.level_competition && (
              <MeterGaugePlot
                risk={metric_level_competition[0].level_competition}
                name={'Уровень конкуренции'}
                content={metric_level_competition[0]?.count_lei}
                description={
                  'Индикатор уровня конкуренции – это ключевой инструмент для бизнес-анализа, который позволяет оценить интенсивность конкуренции между компаниями, зарегистрированными в конкретном городе и оперирующими в одной и той же сфере бизнеса.'
                }
                type={IndicatorsType.COMPETITION}
              />
            )}
            {metric_address_economic_high_risk_registry[0]?.address_description && (
              <MetricAddressEconomicHighRiskRegistry
                risk={metric_address_economic_high_risk_registry[0].address_description}
                name={'Репутация адреса'}
                description={
                  'Маркер репутации адреса — это инновационный инструмент, который служит для оценки доверия к бизнес-адресам. Он анализирует историю использования адреса и выявляет, ассоциирован ли он с организациями, попавшими в негативные списки государственных органов.'
                }
              />
            )}
            {metric_probability_liquidation[0]?.risk_level && (
              <MeterGaugePlot
                risk={metric_probability_liquidation[0].risk_level}
                name={'Уровень ликвидаций в секторе'}
                content={`${metric_probability_liquidation[0]?.probability_liquidation?.toFixed(2)}%`}
                description={
                  'Маркер уровня ликвидаций представляет собой ключевой показатель, который отслеживает процентное соотношение ликвидированных предприятий к общему числу зарегистрированных компаний в конкретном городе, оперируя в одной сфере бизнеса.'
                }
                type={IndicatorsType.LIQUIDATION}
              />
            )}
          </TopLine>
          <S.MyDivider />
        </Container>
      )}
    </>
  );
};

export default Indicators;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TopLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
`;
