import React, { useEffect, useState } from 'react';
import { MapBelarusType } from '@app/store/types/landing/MapBelarusTypes';
import * as S from '@app/components/dashboards/interactiveMap/styles/styles';
import { InnerContainer } from '@app/components/dashboards/mainLanding/styles/MainLandingStyles';
import { stylesMap } from '@app/components/dashboards/interactiveMap/utils';
import { regions } from '@app/components/dashboards/mainLanding/MapBelarus/data/mapBelarusData';
import InfoWindow from '@app/components/dashboards/interactiveMap/InfoWindow/InfoWindow';

type Props = {
  handleClick: (value: string, type: MapBelarusType) => void;
  dateRange: [number, number];
};

const RegionsMap: React.FC<Props> = ({ handleClick, dateRange }) => {
  const [region, setRegion] = useState<string>('');
  const [type, setType] = useState(MapBelarusType.DISTRICT);
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    console.log(region);
  }, [region]);

  const handleMouseOver = function (event: React.MouseEvent<SVGElement>) {
    const region = (event.target as SVGElement).getAttribute('data-region');
    setRegion(region || '');
  };

  const handleMouseEnter = (event: React.MouseEvent, value: string, type: MapBelarusType) => {
    setVisible(true);
    setPosition({ x: event.clientX - 100, y: event.clientY + 40 });
    setRegion(value || '');
    setType(type);
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    setPosition({ x: event.clientX - 100, y: event.clientY + 40 });
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <S.Container>
      <InnerContainer>
        <S.MapBelarusContainer>
          <S.ImageContainer>
            <svg
              version="1.1"
              id="Belarus"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 550 490"
              enableBackground="new 0 0 550 490"
              xmlSpace="preserve"
            >
              <style>
                {`
                        #subregions {${Object.entries(stylesMap.subregions)
                          .map(([key, value]) => `${key}:${value};`)
                          .join('')}}
                        #regions {${Object.entries(stylesMap.regions)
                          .map(([key, value]) => `${key}:${value};`)
                          .join('')}}
                        circle {${Object.entries(stylesMap.circle)
                          .map(([key, value]) => `${key}:${value};`)
                          .join('')}}
                    `}
              </style>

              <g id="regions">
                {regions.map((region, index) => {
                  return (
                    <polygon
                      id={region.id}
                      data-region={region.region_name}
                      points={region.points}
                      className={region.className}
                      onMouseOver={handleMouseOver}
                      onMouseEnter={(event) => handleMouseEnter(event, region.region_name, MapBelarusType.REGION)}
                      onMouseMove={handleMouseMove}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => handleClick(region.region_name, MapBelarusType.REGION)}
                      key={index}
                    />
                  );
                })}
              </g>
            </svg>
          </S.ImageContainer>
        </S.MapBelarusContainer>
      </InnerContainer>
      {visible && <InfoWindow x={position.x} y={position.y} name={region} type={type} dateRange={dateRange} />}
    </S.Container>
  );
};

export default RegionsMap;
