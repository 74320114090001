import React from 'react';
import ShowMoreText from 'react-show-more-text';
import { Table } from 'antd';

import * as S from '@app/components/dashboards/profile-info/components/IceTradesTest/styles/IceTradeStyles';

import { IceTradeObject } from '@app/interfaces/iceTrade.interfaces';
import { formatDate } from '@app/utils/utils';

type Props = {
  iceTrades: IceTradeObject[];
};

const PurchasesList: React.FC<Props> = ({ iceTrades }) => {
  const results = iceTrades.filter((item) => item.price_byn);

  console.log(iceTrades);

  const columns = [
    {
      title: <S.TableTitle>Товар</S.TableTitle>,
      dataIndex: 'purchase_subject',
      key: 'purchase_subject',
      width: '45%',
      render: (text: string) => (
        <S.TableContent>
          <ShowMoreText
            lines={3}
            more={<S.ExpandedText>развернуть</S.ExpandedText>}
            less={<S.ExpandedText>свернуть</S.ExpandedText>}
            expanded={false}
          >
            {text}
          </ShowMoreText>
        </S.TableContent>
      ),
    },
    {
      title: <S.TableTitle>Сумма</S.TableTitle>,
      dataIndex: 'price_byn',
      key: 'price_byn',
      render: (text: string) => <S.TableContent>{text}</S.TableContent>,
    },
    {
      title: <S.TableTitle>Дата договора</S.TableTitle>,
      dataIndex: 'contract_date',
      key: 'contract_date',
      render: (text: string) => <S.TableContent>{formatDate(text)}</S.TableContent>,
    },
    {
      title: <S.TableTitle>Исполнитель</S.TableTitle>,
      dataIndex: 'company_short_name_participant',
      key: 'company_short_name_participant',
      render: (text: string) => <S.TableContent>{text}</S.TableContent>,
    },
  ];

  return (
    <S.Container>
      <S.Title>Сведения о закупках</S.Title>
      <Table
        style={{ width: '100%' }}
        dataSource={results}
        columns={columns}
        pagination={{ size: 'small', pageSize: 5 }}
      />
    </S.Container>
  );
};

export default PurchasesList;
