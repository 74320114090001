import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { TOKEN_NAME, URLS } from '@app/constants/Constants';
import { readToken } from '@app/services/localStorage.service';
import { httpSearch } from '@app/api/http.api';
import { SearchType } from '@app/components/header/components/SearchInput/SearchInput';

export let searchController: AbortController | undefined;

export interface Organization {
  unn: string;
  date_reg: string;
  full_name: string;
  full_address: string;
  status_code: string;
  status_name: string;
}

interface Data {
  count: number;
  next: string | null;
  previous: string | null;
  results: Organization[];
}

interface SearchState {
  data: Data;
  unn: string;
  loading: boolean;
  error: boolean;
}

// interface SearchError {
//   detail: string;
// }

interface RequestData {
  query: string;
  searchType: SearchType;
}

const initialState: SearchState = {
  data: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  unn: '',
  loading: false,
  error: false,
};

export const doSearch = createAsyncThunk<Data, RequestData>(
  'auth/doSearch',
  async ({ query, searchType }, { rejectWithValue }) => {
    try {
      if (!!searchController) {
        searchController.abort();
      }
      searchController = new AbortController();
      const response = await httpSearch.get(URLS.SEARCH, {
        params: searchType === SearchType.OTHER ? { val: query, type: 'other' } : { val: query },
        signal: searchController.signal,
      });

      console.log(query);
      console.log(searchType);
      console.log(response.data);

      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      if (axios.isAxiosError(error)) {
        // const responseError: SearchError | undefined = error.response?.data;
        // if (responseError) {
        //   const errorMessage: string | null = responseError.detail;
        //   return rejectWithValue(errorMessage);
        // } else {
        //   return rejectWithValue('1');
        // }
        return rejectWithValue(undefined);
      }
    }
  },
);

export const doNewPage = createAsyncThunk<Data, string>(
  'auth/doNewPage',
  async (newPage: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(newPage, {
        headers: { Authorization: `${TOKEN_NAME} ${readToken()}` },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(null);
    }
  },
);

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setError: (state, action) => {
      state.data = initialState.data;
      state.error = action.payload;
    },
    setSubjectUnn: (state, action) => {
      state.unn = action.payload;
    },
    clearSearchData: (state) => {
      state.data = {
        count: 0,
        next: null,
        previous: null,
        results: [],
      };
      state.loading = false;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doSearch.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(doSearch.fulfilled, (state, action) => {
      state.data = action.payload
        ? action.payload
        : {
            count: 0,
            next: null,
            previous: null,
            results: [],
          };
      state.loading = false;
      state.error = false;
    });
    builder.addCase(doSearch.rejected, (state) => {
      state.data.results = [];
      state.error = true;
      state.loading = false;
    });
    builder.addCase(doNewPage.pending, (state) => {
      state.data = initialState.data;
      state.loading = true;
      state.error = false;
    });
    builder.addCase(doNewPage.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.loading = false;
    });
    builder.addCase(doNewPage.rejected, (state) => {
      state.data = initialState.data;
      state.error = true;
      state.loading = false;
    });
  },
});

export const { setData, setError, setSubjectUnn, clearSearchData } = searchSlice.actions;
export default searchSlice.reducer;
