import React, { ReactNode } from 'react';
import Highlighter from 'react-highlight-words';

import './styles.css';
import styled from 'styled-components';
import { MyStyles } from '@app/styles/themes/myStyles/myStyles';
import { IEntitiesNews } from '@app/interfaces/news.interfaces';

enum NewsHighlightType {
  PER = 'PER',
  ORG = 'ORG',
  LOC = 'LOC',
  SEARCH = 'SEARCH',
}

type Props = {
  content: string;
  words: IEntitiesNews[];
};

const NewsHighlight: React.FC<Props> = ({ content, words }) => {
  return (
    <Highlighter
      searchWords={words ? words?.map((item) => escapeRegExpFn(item.text)) : []}
      textToHighlight={content}
      highlightTag={({ children }: { children: ReactNode }) => {
        const word = children as string;
        const matchedWord = words.find((item) => item.text === word);
        if (matchedWord) {
          return <HighlightComponent type={matchedWord.label}>{word}</HighlightComponent>;
        }
        return <DefaultHighlight>{word}</DefaultHighlight>;
      }}
    />
  );
};

export default NewsHighlight;

const HighlightComponent: React.ComponentType<{ children: React.ReactNode; type: string }> = ({ children, type }) => {
  switch (type) {
    case 'PER':
      return <StyledTag type={NewsHighlightType.PER}>{children}</StyledTag>;
    case 'LOC':
      return <StyledTag type={NewsHighlightType.LOC}>{children}</StyledTag>;
    case 'ORG':
      return <StyledTag type={NewsHighlightType.ORG}>{children}</StyledTag>;
    case 'SEARCH':
      return <StyledTag type={NewsHighlightType.SEARCH}>{children}</StyledTag>;
    default:
      return <DefaultHighlight>{children}</DefaultHighlight>;
  }
};

type StyledTagProps = {
  type: string;
};

const StyledTag = styled.span<StyledTagProps>`
  padding: 2px;
  background-color: ${(props) => {
    switch (props.type) {
      case NewsHighlightType.LOC:
        return MyStyles.news.color.location;
      case NewsHighlightType.ORG:
        return MyStyles.news.color.organization;
      case NewsHighlightType.PER:
        return MyStyles.news.color.person;
      case NewsHighlightType.SEARCH:
        return MyStyles.news.color.search;
      default:
        return 'inherit';
    }
  }};
  color: #fff;
`;

const DefaultHighlight = styled.span`
  background-color: inherit;
`;

const escapeRegExpFn = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
