import React from 'react';

import styled from 'styled-components';
import styles from './Social.module.css';

const Social: React.FC = () => {
  return (
    <SocialContainer>
      <Title>Социальные сети</Title>
      <Socials>
        <a href="https://t.me/analytix_by" target="_blank" rel="noreferrer">
          <svg
            className={styles.svg__icon}
            fill="#188CFF"
            width="24px"
            height="24px"
            viewBox="0 0 32 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Telegram</title>
            <path d="M22.122 10.040c0.006-0 0.014-0 0.022-0 0.209 0 0.403 0.065 0.562 0.177l-0.003-0.002c0.116 0.101 0.194 0.243 0.213 0.403l0 0.003c0.020 0.122 0.031 0.262 0.031 0.405 0 0.065-0.002 0.129-0.007 0.193l0-0.009c-0.225 2.369-1.201 8.114-1.697 10.766-0.21 1.123-0.623 1.499-1.023 1.535-0.869 0.081-1.529-0.574-2.371-1.126-1.318-0.865-2.063-1.403-3.342-2.246-1.479-0.973-0.52-1.51 0.322-2.384 0.221-0.23 4.052-3.715 4.127-4.031 0.004-0.019 0.006-0.040 0.006-0.062 0-0.078-0.029-0.149-0.076-0.203l0 0c-0.052-0.034-0.117-0.053-0.185-0.053-0.045 0-0.088 0.009-0.128 0.024l0.002-0.001q-0.198 0.045-6.316 4.174c-0.445 0.351-1.007 0.573-1.619 0.599l-0.006 0c-0.867-0.105-1.654-0.298-2.401-0.573l0.074 0.024c-0.938-0.306-1.683-0.467-1.619-0.985q0.051-0.404 1.114-0.827 6.548-2.853 8.733-3.761c1.607-0.853 3.47-1.555 5.429-2.010l0.157-0.031zM15.93 1.025c-8.302 0.020-15.025 6.755-15.025 15.060 0 8.317 6.742 15.060 15.060 15.060s15.060-6.742 15.060-15.060c0-8.305-6.723-15.040-15.023-15.060h-0.002q-0.035-0-0.070 0z"></path>
          </svg>
        </a>
        {/*<svg*/}
        {/*  className={utils.svg__icon}*/}
        {/*  fill="#188CFF"*/}
        {/*  width="24px"*/}
        {/*  height="24px"*/}
        {/*  viewBox="0 0 32 32"*/}
        {/*  version="1.1"*/}
        {/*  xmlns="http://www.w3.org/2000/svg"*/}
        {/*>*/}
        {/*  <title>Instagram</title>*/}
        {/*  <path d="M12.826 11.889c0 0 0 0-0 0-0.504 0-0.912 0.408-0.912 0.912 0 0 0 0 0 0v0 6.361c0 0.001 0 0.002 0 0.004 0 0.495 0.401 0.896 0.896 0.896 0.001 0 0.003 0 0.004 0h6.373c0 0 0 0 0 0 0.499 0 0.905-0.401 0.912-0.899v-6.361c0-0.504-0.408-0.912-0.912-0.912v0h-6.361zM8.040 6.453h15.92c0.87 0.002 1.575 0.708 1.575 1.579 0 0.003 0 0.006-0 0.009v-0 15.92c0 0.003 0 0.005 0 0.008 0 0.87-0.704 1.576-1.574 1.579h-15.921c-0.87-0.002-1.575-0.708-1.575-1.579 0-0.003 0-0.006 0-0.009v0-15.92c0 0 0-0 0-0 0-0.872 0.703-1.58 1.574-1.587h0.001zM6.015 1.004c-2.768 0-5.011 2.244-5.011 5.011v0 19.969c0.009 2.764 2.247 5.002 5.010 5.011h19.97c2.766-0.004 5.007-2.245 5.011-5.011v-19.982c-0.007-2.762-2.248-4.999-5.011-4.999h-19.969z"></path>*/}
        {/*</svg>*/}
        {/*<svg*/}
        {/*  className={utils.svg__icon}*/}
        {/*  fill="#188CFF"*/}
        {/*  width="24px"*/}
        {/*  height="24px"*/}
        {/*  viewBox="0 0 32 32"*/}
        {/*  version="1.1"*/}
        {/*  xmlns="http://www.w3.org/2000/svg"*/}
        {/*>*/}
        {/*  <title>Youtube</title>*/}
        {/*  <path d="M12.932 20.459v-8.917l7.839 4.459zM30.368 8.735c-0.354-1.301-1.354-2.307-2.625-2.663l-0.027-0.006c-3.193-0.406-6.886-0.638-10.634-0.638-0.381 0-0.761 0.002-1.14 0.007l0.058-0.001c-0.322-0.004-0.701-0.007-1.082-0.007-3.748 0-7.443 0.232-11.070 0.681l0.434-0.044c-1.297 0.363-2.297 1.368-2.644 2.643l-0.006 0.026c-0.4 2.109-0.628 4.536-0.628 7.016 0 0.088 0 0.176 0.001 0.263l-0-0.014c-0 0.074-0.001 0.162-0.001 0.25 0 2.48 0.229 4.906 0.666 7.259l-0.038-0.244c0.354 1.301 1.354 2.307 2.625 2.663l0.027 0.006c3.193 0.406 6.886 0.638 10.634 0.638 0.38 0 0.76-0.002 1.14-0.007l-0.058 0.001c0.322 0.004 0.702 0.007 1.082 0.007 3.749 0 7.443-0.232 11.070-0.681l-0.434 0.044c1.298-0.362 2.298-1.368 2.646-2.643l0.006-0.026c0.399-2.109 0.627-4.536 0.627-7.015 0-0.088-0-0.176-0.001-0.263l0 0.013c0-0.074 0.001-0.162 0.001-0.25 0-2.48-0.229-4.906-0.666-7.259l0.038 0.244z"></path>*/}
        {/*</svg>*/}
      </Socials>
    </SocialContainer>
  );
};

export default Social;

const SocialContainer = styled.div`
  display: flex;
`;

const Socials = styled.div`
  margin-left: 26px;
  display: flex;
  gap: 24px;

   {
    cursor: pointer;
  }
`;

const Title = styled.span`
  font-weight: 700;
  color: #6f7482;
`;
