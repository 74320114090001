import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doLogin } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { persistIsFirstLogin, readIsFirstLogin, readToken } from '@app/services/localStorage.service';
import { LEGAL_ENTITY, SEARCH_DASHBOARD_PATH } from '@app/components/router/AppRouter';

interface LoginFormData {
  email: string;
  password: string;
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const token = useAppSelector((state) => state.auth.token);

  useEffect(() => {
    if (token === null && readToken() === null) {
      notificationController.success({
        message: 'Авторизируйтесь снова!',
      });
    }
  }, [token]);

  const [isLoading, setLoading] = useState(false);

  const handleClickHomeButton = () => {
    navigate('/');
  };

  const handleSubmit = (values: LoginFormData) => {
    setLoading(true);
    dispatch(doLogin(values))
      .unwrap()
      .then((response) => {
        if (readIsFirstLogin()) {
          navigate(SEARCH_DASHBOARD_PATH);
        } else {
          navigate(LEGAL_ENTITY);
          persistIsFirstLogin();
        }
        notificationController.success({ message: ` Добро пожаловать, ${response.user.first_name}!` });
      })
      .catch(() => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
        <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <Auth.HomeButton onClick={handleClickHomeButton}>{t('common.toHome')}</Auth.HomeButton>
        </BaseForm.Item>
        {/*<Auth.FooterWrapper>*/}
        {/*  <Auth.Text>*/}
        {/*    {t('login.noAccount')}{' '}*/}
        {/*    <Link to="/auth/sign-up">*/}
        {/*      <Auth.LinkText>{t('common.here')}</Auth.LinkText>*/}
        {/*    </Link>*/}
        {/*  </Auth.Text>*/}
        {/*</Auth.FooterWrapper>*/}
      </BaseForm>
    </Auth.FormWrapper>
  );
};
