import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doSearchProfile } from '@app/store/slices/search/searchProfileSlice';
import { Col, Row, Spin, Tabs, TabsProps } from 'antd';
import styled from 'styled-components';
import Vacancies from '@app/components/dashboards/profile-info/components/Vacancies/Vacancies';
import TabButton from '@app/components/dashboards/profile-info/components/components/Buttons/TabButton/TabButton';
import SiderMenu from '@app/components/dashboards/profile-info/components/SiderMenu/SiderMenu';
import Resumes from '@app/components/dashboards/profile-info/components/Resumes/Resumes';
import NewsProfile from '@app/components/dashboards/profile-info/components/NewsProfile/NewsProfile';
import HistoryProfile from '@app/components/dashboards/profile-info/components/HistoryProfile/HistoryProfile';
import CommercialRegisterProfile from '@app/components/dashboards/profile-info/components/CommercialRegister/CommercialRegisterProfile';
import GovernmentInspections from '@app/components/dashboards/profile-info/components/GovernmentInspection/GovernmentInspections';
import GiasPlan from '@app/components/dashboards/profile-info/components/GiasPlan/GiasPlan';
import GeneralInformationTest from '@app/components/dashboards/profile-info/components/GeneralInformation/GeneralInformationTest';
import StickyBox from 'react-sticky-box';
import IceTrades from '@app/components/dashboards/profile-info/components/IceTradesTest/IceTrades';

// const LEFT_COLUMN_SIZE = 19;
// const RIGHT_COLUMN_SIZE = 5;

enum TABS {
  GENERAL_INFORMATION = '1',
  VACANCIES = '2',
  RESUMES = '3',
  ICE_TRADES = '4',
  GIAS = '5',
  NEWS = '6',
  COMMERCIAL_REGISTER = '7',
  HISTORY = '8',
  GOVERNMENT_INSPECTION = '9',
}

const ProfileInfo: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string>(TABS.GENERAL_INFORMATION);

  const { unn } = useParams();
  const { loading } = useAppSelector((state) => state.searchProfile);
  const dispatch = useAppDispatch();

  useEffect(() => {
    try {
      if (typeof unn === 'string') {
        dispatch(doSearchProfile(unn));
      }
    } catch (error) {
      console.log(error);
    }
  }, [unn, dispatch]);

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <StickyBox offsetTop={0} offsetBottom={20} style={{ zIndex: 8 }}>
      <DefaultTabBar {...props} style={{ background: '#fff' }} />
    </StickyBox>
  );

  return (
    <ProfileContainer>
      {loading ? (
        <SpinnerSpace>
          <Spin size="large" tip="Загрузка данных . . ." />
        </SpinnerSpace>
      ) : (
        <ProfileRow>
          <LeftCol>
            <Tabs defaultActiveKey={TABS.GENERAL_INFORMATION} renderTabBar={renderTabBar}>
              <Tabs.TabPane
                tab={
                  <TabButton
                    isActive={activeKey === TABS.GENERAL_INFORMATION}
                    tabKey={TABS.GENERAL_INFORMATION}
                    handleClick={handleTabChange}
                  >
                    Основная информация
                  </TabButton>
                }
                key={TABS.GENERAL_INFORMATION}
              >
                <GeneralInformationTest />
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <TabButton
                    isActive={activeKey === TABS.VACANCIES}
                    tabKey={TABS.VACANCIES}
                    handleClick={handleTabChange}
                  >
                    Вакансии
                  </TabButton>
                }
                key={TABS.VACANCIES}
              >
                <Vacancies />
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <TabButton isActive={activeKey === TABS.RESUMES} tabKey={TABS.RESUMES} handleClick={handleTabChange}>
                    Резюме
                  </TabButton>
                }
                key={TABS.RESUMES}
              >
                <Resumes />
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <TabButton
                    isActive={activeKey === TABS.ICE_TRADES}
                    tabKey={TABS.ICE_TRADES}
                    handleClick={handleTabChange}
                  >
                    Закупки
                  </TabButton>
                }
                key={TABS.ICE_TRADES}
              >
                <IceTrades />
                {/*<IceTrades />*/}
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <TabButton isActive={activeKey === TABS.GIAS} tabKey={TABS.GIAS} handleClick={handleTabChange}>
                    Планы Гос. Закупок
                  </TabButton>
                }
                key={TABS.GIAS}
              >
                <GiasPlan />
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <TabButton isActive={activeKey === TABS.NEWS} tabKey={TABS.NEWS} handleClick={handleTabChange}>
                    Новости
                  </TabButton>
                }
                key={TABS.NEWS}
              >
                <NewsProfile />
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <TabButton
                    isActive={activeKey === TABS.COMMERCIAL_REGISTER}
                    tabKey={TABS.COMMERCIAL_REGISTER}
                    handleClick={handleTabChange}
                  >
                    Торговый реестр
                  </TabButton>
                }
                key={TABS.COMMERCIAL_REGISTER}
              >
                <CommercialRegisterProfile />
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <TabButton isActive={activeKey === TABS.HISTORY} tabKey={TABS.HISTORY} handleClick={handleTabChange}>
                    История
                  </TabButton>
                }
                key={TABS.HISTORY}
              >
                <HistoryProfile />
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <TabButton
                    isActive={activeKey === TABS.GOVERNMENT_INSPECTION}
                    tabKey={TABS.GOVERNMENT_INSPECTION}
                    handleClick={handleTabChange}
                  >
                    Проверки
                  </TabButton>
                }
                key={TABS.GOVERNMENT_INSPECTION}
              >
                <GovernmentInspections />
              </Tabs.TabPane>
            </Tabs>
          </LeftCol>
          <RightCol>
            <StickyBox>
              <SiderMenu />
            </StickyBox>
          </RightCol>
        </ProfileRow>
      )}
    </ProfileContainer>
  );
};

export default ProfileInfo;

const ProfileContainer = styled.div`
  margin: 0 auto;
  max-width: 1320px;
  width: 100%;
  flex-grow: 1;
`;

const SpinnerSpace = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfileRow = styled(Row)`
  display: flex;
`;

const LeftCol = styled(Col)`
  width: 80%;

  @media (max-width: 1150px) {
    width: 100%;
  }
`;

const RightCol = styled(Col)`
  width: 20%;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 1150px) {
    display: none;
  }
`;
