import React from 'react';

import styled from 'styled-components';
import { Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { DASH } from '@app/constants/enums/Dashboards';
import { httpAxios } from '@app/api/http.api';
import { MapBelarusType } from '@app/store/types/landing/MapBelarusTypes';

type Props = {
  x: number;
  y: number;
  name: string;
  type: MapBelarusType;
  dateRange: [number, number];
};

interface MapBelarusObject {
  group_fields: {
    legal_form_entity_type: string | null;
  };
  Count: number;
}

interface ResponseMapBelarus {
  count: number | null;
  next: string | null;
  previous: string | null;
  results: MapBelarusObject[];
}

const getMapBelarusData = async (name: string, type: MapBelarusType, dateRange: [number, number]) => {
  let URL = DASH.BASE + DASH.AGR_COUNT + DASH.GROUP_BY('legal_form_entity_type') + DASH.STATUS_AT;
  URL += `company_date_registration__gte=${dateRange[0]}-01-01&company_date_registration__lte=${dateRange[1]}-01-01&`;
  switch (type) {
    case MapBelarusType.DISTRICT: {
      URL += DASH.ADDRESS_DISTRICT_ICONTAINS(name);
      break;
    }
    case MapBelarusType.SETTLEMENT: {
      URL += DASH.ADDRESS_SETTLEMENT_ICONTAINS(name);
      break;
    }
    case MapBelarusType.REGION: {
      URL += DASH.ADDRESS_REGION_ICONTAINS(name);
      break;
    }
  }
  return httpAxios.get<ResponseMapBelarus>(URL);
};

// DASH.BASE + DASH.AGR_COUNT + DASH.GROUP_BY('legal_form_entity_type') + DASH.STATUS_AT + DASH.ADDRESS_DISTRICT_ICONTAINS(name)

const InfoWindow: React.FC<Props> = ({ x, y, name, type, dateRange }) => {
  const { data, isFetching } = useQuery({
    queryKey: ['mapBelarusData', name, type, dateRange],
    queryFn: () => getMapBelarusData(name, type, dateRange),
  });

  if (isFetching) {
    return (
      <Container x={x} y={y}>
        <Name>{name}</Name>
        <SpinnerSpace>
          <Spin size="large" tip="Загрузка данных . . ." />
        </SpinnerSpace>
      </Container>
    );
  }

  return (
    <Container x={x} y={y}>
      <Name>{name}</Name>
      <Text>
        {data?.data?.results[0]?.group_fields?.legal_form_entity_type === 'Индивидуальный предприниматель'
          ? 'ИП'
          : 'Юридические лица'}
        : {data?.data?.results[0]?.Count}
      </Text>
      <Text>
        {data?.data?.results[0]?.group_fields?.legal_form_entity_type === 'Юридическое лицо'
          ? 'ИП'
          : 'Юридические лица'}
        : {data?.data?.results[1]?.Count}
      </Text>
    </Container>
  );
};

const SpinnerSpace = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default InfoWindow;

type ContainerProps = {
  x: number;
  y: number;
};

const Container = styled.div<ContainerProps>`
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 300px;
  min-height: 50px;
  position: fixed;
  z-index: 10;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  top: ${(props) => `${props.y}px`};
  left: ${(props) => `${props.x}px`};
`;

const Name = styled.div`
  text-align: center;
`;

const Text = styled.div``;
