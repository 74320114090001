import React from 'react';
import styled from 'styled-components';
import Info from '@app/components/dashboards/profile-info/components/IceTradesTest/Info/Info';
import { useQuery } from '@tanstack/react-query';
import iceTradeService from '@app/services/icetrade.service';
import { useAppSelector } from '@app/hooks/reduxHooks';
import IceTradesByAge from '@app/components/dashboards/profile-info/components/IceTradesTest/IceTradesByAge/IceTradesByAge';
import IceTradesByMonth from '@app/components/dashboards/profile-info/components/IceTradesTest/IceTradesByMonth/IceTradesByMonth';
import { Spin } from 'antd';
import TableYears from '@app/components/dashboards/profile-info/components/IceTradesTest/TableYears/TableYears';
import ParticipantsList from '@app/components/dashboards/profile-info/components/IceTradesTest/ParticipantsList/ParticipantsList';
import PurchasesList from '@app/components/dashboards/profile-info/components/IceTradesTest/PurchasesList/PurchasesList';

const IceTrades = () => {
  const { unn, names } = useAppSelector((state) => state.searchProfile.profile);
  const { icetrade_customer, icetrade_participant } = useAppSelector((state) => state.searchProfile.profile);

  const { data, isFetching } = useQuery({
    queryKey: ['getIceTrades', unn],
    queryFn: () => iceTradeService.getIceTradesByUnn(unn),
    select: ({ data }) => data.results || [],
    enabled: !!unn,
  });

  if (isFetching) {
    return (
      <SpinnerSpace>
        <Spin size="large" tip="Загрузка данных . . ." />
      </SpinnerSpace>
    );
  }

  if (!data || !Boolean(data.length)) return <div>Данные по закупкам отсутсвуют</div>;

  console.log('data', data.length);
  console.log('customer', icetrade_customer.length);
  console.log('participant', icetrade_participant.length);

  const customers = iceTradeService.filterCustomers(data, names[0].short_name);
  const participants = iceTradeService.filterParticipant(data, names[0].short_name);

  const byAgeCustomers = iceTradeService.getCompletedByAge(customers);
  const byMonthCustomers = iceTradeService.getCompletedByMonth(customers);
  const completedCustomers = iceTradeService.getCompleted(customers);

  return (
    <Container>
      {Boolean(customers.length) && (
        <TypeContainer>
          <Title>Сведения о закупках, где компания выступает заказчиком</Title>
          <Info iceTrades={customers} />
          <ChartContainer>
            {byAgeCustomers && <IceTradesByAge data={byAgeCustomers} />}
            {byMonthCustomers && <IceTradesByMonth data={byMonthCustomers} />}
          </ChartContainer>
          <TableYears competedIceTrades={completedCustomers} />
          <ChartContainer>
            <ParticipantsList iceTrades={completedCustomers} />
            <PurchasesList iceTrades={completedCustomers} />
          </ChartContainer>
        </TypeContainer>
      )}
      {Boolean(participants.length) && (
        <TypeContainer>
          <Title>Сведения о закупках, где компания выступает исполнителем</Title>
          <Info iceTrades={participants} />
        </TypeContainer>
      )}
    </Container>
  );
};

export default IceTrades;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const TypeContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ChartContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;
`;

const SpinnerSpace = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
`;
