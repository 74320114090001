import React, { useState } from 'react';
import styled from 'styled-components';

import xiaomiData from './data/xiaomi.json';
import animationData from './data/China Animation.json';
import hunanData from './data/Hunan.json';
import beijingData from './data/Beijing.json';
import { Select } from 'antd';
import ChinaTable from '@app/components/dashboards/china/tables/ChinaTable';

const China: React.FC = () => {
  const [dataChina, setDataChina] = useState<any>(hunanData);
  const investmentAbroadKeys = ['investedPercent', 'orgType', 'creditcode', 'regCapital', 'province'];
  const operationalAlertsAdministrativePenaltyKeys = [
    'departmentName',
    'referenceNumber',
    'type',
    'content',
    'decisionDate',
  ];
  const bankruptcyReorganizationKeys = ['publishDate', 'respondent', 'caseNo', 'caseType', 'applicant'];
  const shareholdersKeys = [
    'shareholderSource',
    'amount',
    'shoudDate',
    'StockName',
    'UltBenefitPer',
    'CountryRegion',
    'StockTag',
    'StockPercent',
    'realCapiType',
    'shareType',
    'ShouldCapi',
    'creditCode',
    'CapiDate',
    'StockType',
    'ShouldCapi',
    'RealCapi',
    'shouldCapiType',
  ];
  const historicChangeKeys = ['dateOfChange', 'before', 'itemChanged', 'after'];
  const majorPersonKeys = ['staffName', 'positions'];
  const lawEnforcementKey = ['caseCode', 'caseCreateDate', 'caseStatus', 'execCourtName', 'execMoney'];
  const legalAlertsKeys = [
    'courtName',
    'caseTitle',
    'publishDate',
    'judgeTime',
    'caseNo',
    'casePersons',
    'caseReason',
    'caseType',
  ];
  const companyRegistrationInformationPart1Keys = [
    'historyNames',
    'stockNumber',
    'regCapital',
    'contactInfo',
    'regAuthority',
    'industry',
    'revokeDate',
    'v',
    'legalPersonName',
  ];
  const companyRegistrationInformationPart2Keys = [
    'revokeReason',
    'entType',
    'orgType',
    'regNumber',
    'creditCode',
    'province',
    'orgCode',
    'enName',
    'insuredEmployee',
  ];
  const companyRegistrationInformationPart3Keys = [
    'impExpCode',
    'bizStatus',
    'regAddress',
    'area',
    'stockType',
    'toDate',
    'businessScope',
    'establishDate',
    'approvedDate',
    'fromDate',
  ];
  const branchesKeys = ['creditCode', 'belongOrg', 'name', 'operName'];

  const handleChange = (value: string) => {
    switch (value) {
      case 'beijing':
        setDataChina(beijingData);
        break;
      case 'animation':
        setDataChina(animationData);
        break;
      case 'hunan':
        setDataChina(hunanData);
        break;
      case 'xiaomi':
        setDataChina(xiaomiData);
        break;
    }
  };

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
        <div>Выберите компанию: </div>
        <Select
          defaultValue="hunan"
          style={{ width: 200 }}
          onChange={handleChange}
          options={[
            { value: 'beijing', label: 'Beijing' },
            { value: 'animation', label: 'China Animation' },
            { value: 'hunan', label: 'Hunan' },
            { value: 'xiaomi', label: 'Xiaomi' },
          ]}
        />
      </div>

      {dataChina?.result?.data?.investmentAbroad?.data && (
        <ChinaTable
          title="Зарубежные инвестиции"
          keys={investmentAbroadKeys}
          data={dataChina.result.data.investmentAbroad.data}
        />
      )}

      {/*{dataChina?.result?.data?.EquityPledged?.data && (*/}
      {/*  <ChinaTable*/}
      {/*    title="EquityPledged"*/}
      {/*    keys={investmentAbroadKeys}*/}
      {/*    data={dataChina.result.data.investmentAbroad.data}*/}
      {/*  />*/}
      {/*)}*/}

      {dataChina?.result?.data?.OperationalAlertsAdministrativePenalty?.data && (
        <ChinaTable
          title="Административные взыскания"
          keys={operationalAlertsAdministrativePenaltyKeys}
          data={dataChina.result.data.OperationalAlertsAdministrativePenalty.data}
        />
      )}

      {dataChina?.result?.data?.BankruptcyReorganization?.data && (
        <ChinaTable
          title="Реорганизация в связи с банкротством"
          keys={bankruptcyReorganizationKeys}
          data={dataChina.result.data.BankruptcyReorganization.data.map((item: any) => {
            return {
              ...item,
              applicant: item.applicant[0].applicantName,
            };
          })}
        />
      )}

      {dataChina?.result?.data?.Shareholders?.data && (
        <ChinaTable title="Акционеры" keys={shareholdersKeys} data={dataChina.result.data.Shareholders.data} />
      )}

      {dataChina?.result?.data?.HistoricChange?.data && (
        <ChinaTable
          title="Исторические сведения"
          keys={historicChangeKeys}
          data={dataChina.result.data.HistoricChange.data}
        />
      )}

      <TableContainer>
        {dataChina?.result?.data?.majorPerson?.data && (
          <ChinaTable title="Должностные лица" keys={majorPersonKeys} data={dataChina.result.data.majorPerson.data} />
        )}

        {dataChina?.result?.data?.LawEnforcement?.data && (
          <ChinaTable
            title="Судебная история"
            keys={lawEnforcementKey}
            data={dataChina.result.data.LawEnforcement.data}
          />
        )}
      </TableContainer>

      {dataChina?.result?.data?.LegalAlerts?.data && (
        <ChinaTable
          title="Юридические предупреждения"
          keys={legalAlertsKeys}
          data={dataChina.result.data.LegalAlerts.data.map((item: any) => {
            return {
              ...item,
              casePersons: item.casePersons.reduce(
                (acc: string, item: any) =>
                  acc + (item.result + ' ' + item.emotion + ' ' + item.role + ' ' + item.name + ' ' + item.type + '\n'),
                '',
              ),
            };
          })}
        />
      )}

      {dataChina?.result?.data?.CompanyRegistrationInformation?.data && (
        <ChinaTable
          title="Регистрационная информация о компании"
          keys={companyRegistrationInformationPart1Keys}
          data={dataChina.result.data.CompanyRegistrationInformation.data.map((item: any) => {
            return {
              historyNames: item.historyNames[0]?.historyName,
              stockNumber: item.stockNumber,
              regCapital: item.regCapital,
              contactInfo: `${item.contactInfo.phone_number} ${item.contactInfo.webSites.toString} ${item.contactInfo.email}`,
              regAuthority: item.regAuthority,
              industry: `subIndustry: ${item.industry.subindustry}\n industry: ${item.industry.industry}\n smallCategory: ${item.industry.smallCategory}\n middleCategory: ${item.industry.middleCategory}`,
              revokeDate: item.revokeDate,
              v: item.v,
              legalPersonName: item.legalPersonName,
            };
          })}
        />
      )}

      {dataChina?.result?.data?.CompanyRegistrationInformation?.data && (
        <ChinaTable
          title=""
          keys={companyRegistrationInformationPart2Keys}
          data={dataChina.result.data.CompanyRegistrationInformation.data.map((item: any) => {
            return {
              revokeReason: item.revokeReason,
              entType: item.entType,
              orgType: item.orgType,
              regNumber: item.regNumber,
              creditCode: item.creditCode,
              province: item.province,
              orgCode: item.orgCode,
              enName: item.enName,
              insuredEmployee: item.insuredEmployee,
            };
          })}
        />
      )}

      {dataChina?.result?.data?.CompanyRegistrationInformation?.data && (
        <ChinaTable
          title=""
          keys={companyRegistrationInformationPart3Keys}
          data={dataChina.result.data.CompanyRegistrationInformation.data.map((item: any) => {
            return {
              impExpCode: item.impExpCode,
              bizStatus: item.bizStatus,
              regAddress: item.regAddress,
              area: `province - ${item.area.province}; city - ${item.area.city}; county - ${item.area.county};`,
              stockType: item.stockType,
              toDate: item.toDate,
              businessScope: item.businessScope,
              establishDate: item.establishDate,
              approvedDate: item.approvedDate,
              fromDate: item.fromDate,
            };
          })}
        />
      )}

      {Boolean(dataChina?.result?.data?.CompanyRegistrationInformation.data[0].branches.length) && (
        <ChinaTable
          title="Филиалы"
          keys={branchesKeys}
          data={dataChina.result.data.CompanyRegistrationInformation.data[0].branches}
        />
      )}
    </Container>
  );
};

export default China;

function traverseObject(obj: any, result: { key: string; value: any }[] = []): { key: string; value: any }[] {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        // Если это массив, проходим по его элементам
        value.forEach((item, index) => {
          if (typeof item === 'object' && item !== null) {
            traverseObject(item, result);
          } else {
            result.push({ key: `${key}[${index}]`, value: item });
          }
        });
        // Добавляем пустой объект после прохождения массива
        result.push({ key: '', value: '' });
      } else {
        // Если это объект, рекурсивно вызываем функцию
        traverseObject(value, result);
        // Добавляем пустой объект после прохождения объекта
        result.push({ key: '', value: '' });
      }
    } else {
      // Если это не объект и не массив, добавляем объект с ключом и значением
      result.push({ key, value });
    }
  });

  return result;
}

const Container = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TableContainer = styled.div`
  display: flex;
  gap: 50px;
`;
