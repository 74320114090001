import React, { useState } from 'react';

import styled from 'styled-components';
import { MyStyles } from '@app/styles/themes/myStyles/myStyles';

import { INews } from '@app/interfaces/news.interfaces';
import { formatDate } from '@app/utils/utils';
import NewsHighlight from '@app/components/dashboards/news/NewsItem/NewHighlight/NewsHighlight';
import { Divider, Spin } from 'antd';

type Props = {
  news: INews;
  similarity?: number | null;
  setSimilarity?: (value: number | null) => void;
  similarNews?: INews[];
  isFetchingNews?: boolean;
  isEnableTags: boolean;
  innerNews: boolean;
  searchQuery?: string;
};

const NewsItem: React.FC<Props> = ({
  news,
  similarity,
  setSimilarity,
  similarNews,
  isFetchingNews,
  isEnableTags,
  innerNews,
  searchQuery,
}) => {
  const [isTruncated, setIsTruncated] = useState(false);

  const words = news.entities;

  const handleClickOpen = () => {
    setSimilarity?.(null);
    setSimilarity?.(news.id);
  };

  const handleClickClose = () => {
    setSimilarity?.(null);
    document.getElementById(`${news.id}`)?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleNavigate = (url: string) => {
    window.open(`//${url}`, '_blank');
  };

  return (
    <Container id={`${news.id}`}>
      <Title>{news.title}</Title>
      <Content>
        {isTruncated ? (
          <NewsHighlight content={news.content + ' '} words={isEnableTags ? words : []} />
        ) : news.content.length > 500 ? (
          <NewsHighlight content={news.content.slice(0, 500) + '... '} words={isEnableTags ? words : []} />
        ) : (
          <NewsHighlight content={news.content + ' '} words={isEnableTags ? words : []} />
        )}
        {news.content.length > 500 && (
          <>
            <ButtonShow onClick={() => setIsTruncated((prevState) => !prevState)}>
              {isTruncated ? 'Скрыть' : 'Показать полностью'}
            </ButtonShow>
          </>
        )}
      </Content>
      <Bottom>
        <Date>
          Дата публикации: <Underline>{formatDate(news.date_publication)}</Underline>
        </Date>
        {news.similarity && !searchQuery && (
          <SimilarityPercent>Процент сходства: {(news.similarity * 100).toFixed(2)}%</SimilarityPercent>
        )}
        {news.plagiarism &&
          !searchQuery &&
          !innerNews &&
          (similarity === news.id ? (
            <ButtonSimilar onClick={handleClickClose}>Скрыть похожие</ButtonSimilar>
          ) : (
            <ButtonSimilar onClick={handleClickOpen}>Показать похожие</ButtonSimilar>
          ))}
      </Bottom>
      <LinkToSource onClick={() => handleNavigate(news.url)}>Ссылка на источник</LinkToSource>
      {isFetchingNews && similarity === news.id && (
        <SpinnerSpace>
          <Spin size="large" tip="Загрузка данных . . ." />
        </SpinnerSpace>
      )}
      {similarity === news.id && !!similarNews?.length && !isFetchingNews && (
        <>
          <SimilarNewsTitle>Похожие новости - {similarNews.length} шт.</SimilarNewsTitle>
          <ContainerSimilar>
            {similarNews.map((item, index) => (
              <NewsItem news={item} key={index} innerNews={true} isEnableTags={isEnableTags} />
            ))}
            <ButtonSimilar onClick={handleClickClose}>Скрыть похожие</ButtonSimilar>
          </ContainerSimilar>
        </>
      )}
      <Divider />
    </Container>
  );
};

export default NewsItem;

const Container = styled.div`
  max-width: ${MyStyles.width.container};
  margin: 0 auto 30px;
  display: flex;
  flex-direction: column;
`;

const ContainerSimilar = styled.div`
  padding-bottom: 20px;
  width: 90%;
  margin: 0 auto 30px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #000;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
`;

const Content = styled.div``;

const Bottom = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Date = styled.span`
  font-weight: 700;
`;

const SimilarityPercent = styled.div`
  font-weight: 700;
`;

const LinkToSource = styled.span`
  width: fit-content;
  text-decoration: underline;
  cursor: pointer;
  color: ${MyStyles.primaryColor};
`;

const ButtonSimilar = styled.div`
  padding: 6px 20px;
  display: flex;
  justify-content: center;
  border: 1px solid ${MyStyles.primaryColor};
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${MyStyles.primaryColor};
  color: ${MyStyles.colors.white};

  &:hover {
    background-color: ${MyStyles.colors.hover};
    color: ${MyStyles.colors.black};
    border-color: ${MyStyles.colors.black};
  }
`;

const Underline = styled.span`
  font-weight: 400;
  text-decoration: underline;
`;

const SimilarNewsTitle = styled.div`
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  text-decoration: underline;
`;

const ButtonShow = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const SpinnerSpace = styled.div`
  flex-grow: 1;
  top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
