import React, { ReactNode } from 'react';
import {
  ContainerOutlined,
  DashboardOutlined,
  FormOutlined,
  GlobalOutlined,
  SearchOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

const Title = styled.span`
  position: relative;
`;

const New = styled.span`
  position: absolute;
  color: yellow;
  top: -28px;
  right: -20px;
  font-style: italic;
`;

export interface SidebarNavigationItem {
  title: ReactNode;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: <Title>Поиск субъекта</Title>,
    key: 'searchSubject',
    // TODO use path variable
    url: '/search',
    icon: <SearchOutlined />,
  },
  {
    title: <Title>Аналитический дашборд</Title>,
    key: 'dashboards',
    // TODO use path variable
    icon: <DashboardOutlined />,
    children: [
      {
        title: <Title>Юридические лица</Title>,
        key: 'legalEntity',
        url: '/legal-entity',
      },
      {
        title: <Title>ИП</Title>,
        key: 'soleTrade',
        url: '/sole-trade',
      },
    ],
  },
  {
    title: (
      <Title>
        Новости<New>New</New>
      </Title>
    ),
    key: 'news',
    // TODO use path variable
    url: '/news',
    icon: <ContainerOutlined />,
  },
  {
    title: <Title>Обратная связь</Title>,
    key: 'feedback',
    // TODO use path variable
    url: '/feedback',
    icon: <FormOutlined />,
  },
  {
    title: (
      <Title>
        Интерактивная карта<New>New</New>
      </Title>
    ),
    key: 'interactiveMap',
    // TODO use path variable
    url: '/interactive-map',
    icon: <GlobalOutlined />,
  },
  {
    title: <Title>Китай</Title>,
    key: 'china',
    // TODO use path variable
    url: '/china',
    icon: <UserSwitchOutlined />,
  },
];

export const sidebarNavigationTest: SidebarNavigationItem[] = [
  // {
  //   title: <Title>Китай</Title>,
  //   key: 'china',
  //   // TODO use path variable
  //   url: '/china',
  //   icon: <UserSwitchOutlined />,
  // },
];
