import React from 'react';

import styled from 'styled-components';
import { MyStyles } from '@app/styles/themes/myStyles/myStyles';

const KindDescription: React.FC = () => {
  return (
    <Container>
      <Text>
        <Bold>Индекс уровня надежности</Bold> — это скоринговый аналитический показатель, рассчитываемый на основе
        публично доступной информации о деятельности юридического лица. Он помогает оценить надежность или ненадежность
        контрагента.
        <a href={`https://analytix.by/indicators/kind`} target="_blank" rel="noreferrer">
          <ButtonDetails>Подробнее</ButtonDetails>
        </a>
      </Text>
      <Text>
        <Bold>Комплексная оценка</Bold> - это числовой показатель в виде баллов, набранных компанией при расчете индекса
        уровня надежности. Чем выше значение, тем более положительных факторов учтено в деятельности компании.
        {/*<ButtonDetails onClick={handleClickKindGroup}>Подробнее</ButtonDetails>*/}
      </Text>
    </Container>
  );
};

export default KindDescription;

const Container = styled.span`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const ButtonDetails = styled.span`
  margin-left: 10px;
  font-size: 0.9374rem;
  color: #000;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${MyStyles.primaryColor};
  }
`;

const Text = styled.div`
  text-align: justify;
`;

const Bold = styled.span`
  font-weight: 700;
`;
