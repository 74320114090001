import React from 'react';
import styled from 'styled-components';
import icetradeService from '@app/services/icetrade.service';
import { IceTradeObject } from '@app/interfaces/iceTrade.interfaces';

type Props = {
  iceTrades: IceTradeObject[];
};

const Info: React.FC<Props> = ({ iceTrades }) => {
  const allCount = icetradeService.getAllCount(iceTrades);
  const completed = icetradeService.getCompletedCount(iceTrades);
  const notCompleted = icetradeService.getNotCompletedCount(iceTrades);
  const percentCompleted = (completed / allCount) * 100;
  const percentNotCompleted = (notCompleted / allCount) * 100;

  console.log(notCompleted);

  return (
    <Container>
      <Item>
        <Title>Общее количество</Title>
        <Content>{allCount}</Content>
      </Item>
      <Item>
        <Title>Признаных состоявщихся</Title>
        <Content percent={percentCompleted}>{completed}</Content>
      </Item>
      <Item>
        <Title>Признаных не состоявщихся</Title>
        <Content percent={percentNotCompleted}>{notCompleted}</Content>
      </Item>
    </Container>
  );
};

export default Info;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Item = styled.div`
  max-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 24px;
  text-align: center;
`;

type ContentProps = {
  percent?: number;
};

const Content = styled.div<ContentProps>`
  font-size: 42px;
  font-weight: 700;
  position: relative;

  &:after {
    content: '${(props) => (props.percent ? `${props.percent.toFixed()}%` : '')}';
    margin-left: 10px;
    font-weight: 400;
    font-size: 18px;
    position: absolute;
    right: -50px;
    top: 40%;
  }
`;
