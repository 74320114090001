import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './DashboardPage.styles';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { References } from '@app/components/common/References/References';
import IndicatorsDescription from '@app/components/dashboards/profile-info/components/GeneralInformation/Indicators/components/IdicatorsDescription/IndicatorsDescription';

const IndicatorsPage: React.FC = () => {
  const { t } = useTranslation();

  const desktopLayout = (
    <S.LeftSideCol id="desktop-content">
      <IndicatorsDescription />
      <References />
    </S.LeftSideCol>
  );

  // const mobileAndTabletLayout = <Row gutter={[20, 24]}></Row>;

  return (
    <>
      <PageTitle>{t('common.analytix')}</PageTitle>
      {desktopLayout}
    </>
  );
};

export default IndicatorsPage;
