import {
  MapBelarusObject,
  MapBelarusSlice,
  MapBelarusType,
  ResponseCountMapBelarus,
} from '@app/store/types/landing/MapBelarusTypes';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { REGIONS_URL } from '@app/constants/Constants';
import { DASH } from '@app/constants/enums/Dashboards';
import { httpDashboard } from '@app/api/http.api';

export let legalEntityController: AbortController | undefined;
export let soleTradeController: AbortController | undefined;

const initialState: MapBelarusSlice = {
  countEntitiesOfMap: [],
  legalEntity: 0,
  soleTrade: 0,
  isLoadingLegalEntity: false,
  isLoadingSoleTrade: false,
};

export const doGetCountEntitiesOfMap = createAsyncThunk<MapBelarusObject[]>('doGetCountEntitiesOfMap', async () => {
  try {
    const response = await axios.get(REGIONS_URL.MAP_STATISTICS);

    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const doGetCountLegalEntity = createAsyncThunk<ResponseCountMapBelarus, { value: string; type: MapBelarusType }>(
  'doGetCountLegalEntity',
  async ({ value, type }) => {
    try {
      if (!!legalEntityController) {
        console.log('abort 1');
        legalEntityController.abort();
      }
      legalEntityController = new AbortController();
      const url =
        type === MapBelarusType.DISTRICT
          ? DASH.BASE + DASH.LEGAL_ENTITY + DASH.ADDRESS_DISTRICT_ICONTAINS(value) + DASH.COUNT
          : DASH.BASE + DASH.LEGAL_ENTITY + DASH.ADDRESS_SETTLEMENT_ICONTAINS(value) + DASH.COUNT;
      const response = await httpDashboard.get(url, { signal: legalEntityController.signal });
      console.log(url);
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled');
      } else {
        console.log('Error:', error);
      }
    }
  },
);

export const doGetCountSoleTrade = createAsyncThunk<ResponseCountMapBelarus, { value: string; type: MapBelarusType }>(
  'doGetCountSoleTrade',
  async ({ value, type }) => {
    try {
      if (!!soleTradeController) {
        soleTradeController.abort();
        console.log('abort 2');
      }
      soleTradeController = new AbortController();
      const url =
        type === MapBelarusType.DISTRICT
          ? DASH.BASE + DASH.SOLE_TRADE + DASH.ADDRESS_DISTRICT_ICONTAINS(value) + DASH.COUNT
          : DASH.BASE + DASH.SOLE_TRADE + DASH.ADDRESS_SETTLEMENT_ICONTAINS(value) + DASH.COUNT;
      const response = await httpDashboard.get(url, { signal: soleTradeController.signal });

      console.log(url);

      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled');
      } else {
        console.log('Error:', error);
      }
    }
  },
);

const mapBelarusSlice = createSlice({
  name: 'mapBelarusSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetCountEntitiesOfMap.fulfilled, (state, action) => {
      state.countEntitiesOfMap = action.payload;
    });
    builder.addCase(doGetCountLegalEntity.pending, (state) => {
      state.isLoadingLegalEntity = true;
    });
    builder.addCase(doGetCountLegalEntity.fulfilled, (state, action) => {
      console.log(action.payload.count);
      state.legalEntity = action.payload.count;
      state.isLoadingLegalEntity = false;
    });
    builder.addCase(doGetCountLegalEntity.rejected, (state) => {
      state.legalEntity = 0;
      state.isLoadingLegalEntity = false;
    });
    builder.addCase(doGetCountSoleTrade.pending, (state) => {
      state.isLoadingSoleTrade = true;
    });
    builder.addCase(doGetCountSoleTrade.fulfilled, (state, action) => {
      console.log(action.payload.count);
      state.soleTrade = action.payload.count;
      state.isLoadingSoleTrade = false;
    });
    builder.addCase(doGetCountSoleTrade.rejected, (state) => {
      state.soleTrade = 0;
      state.isLoadingSoleTrade = false;
    });
  },
});

export default mapBelarusSlice.reducer;
