import { Content } from '@app/components/dashboards/dashboard/styles/DetailedInformationCompanyStyle';
import React from 'react';
import { DashboardObject } from '@app/interfaces/interfaces';

export enum DetailsTableType {
  COMPETITORS,
  RATING_ALL,
  FEEDBACK,
  ICE_TRADE_PARTICIPANTS,
}

const getColumn = (title: string, field: string, width = 'auto') => {
  const renderText = (text: string) => {
    return text ? <Content>{text}</Content> : <Content textAlign={'center'}>-</Content>;
  };

  return {
    title: title,
    dataIndex: field,
    key: field,
    width: width,
    render: renderText,
  };
};

export const getColumns = (detailed: DetailsTableType) => {
  switch (detailed) {
    case DetailsTableType.COMPETITORS: {
      return [
        getColumn('УНП', 'legal_entity_id', '10%'),
        getColumn('Сокращенное наименование', 'company_short_name', '30%'),
        {
          title: 'Индекс',
          dataIndex: 'king_group',
          key: 'king_group',
          width: '5%',
          showSorterTooltip: false,
          sorter: (a: DashboardObject, b: DashboardObject) => b.king_group - a.king_group,
          render: (text: number, record: DashboardObject) => (
            <Content style={{ color: getColorByKind(record.king), fontWeight: 700 }}>{text}</Content>
          ),
        },
        {
          title: 'Оценка',
          dataIndex: 'king',
          key: 'king',
          width: '5%',
          showSorterTooltip: false,
          sorter: (a: DashboardObject, b: DashboardObject) => b.king - a.king,
          render: (text: number) => <Content>{text}</Content>,
        },
        getColumn('Дата регистрации', 'company_date_registration', '15%'),
        getColumn('Полный адрес', 'address_full', '35%'),
      ];
    }
    case DetailsTableType.RATING_ALL:
      return [
        getColumn('Позиция', 'position'),
        getColumn('УНП', 'legal_entity_id'),
        getColumn('Сокращенное наименование', 'company_short_name'),
      ];
    case DetailsTableType.FEEDBACK:
      return [
        getColumn('Позиция', 'position'),
        getColumn('УНП', 'legal_entity_id'),
        getColumn('Сокращенное наименование', 'company_short_name'),
      ];
    case DetailsTableType.ICE_TRADE_PARTICIPANTS:
      return [getColumn('Наименование', 'name'), getColumn('Сумма', 'sum')];
  }
};

const getColorByKind = (kind: number | null): string => {
  if (!kind) return 'red';
  if (kind <= 131) {
    return 'red';
  } else if (kind > 131 && kind <= 190) {
    return 'orange';
  } else {
    return 'green';
  }
};
