import React, { useEffect, useState } from 'react';
import { ResponseDashboard } from '@app/interfaces/interfaces';

import * as S from './styles/PercentStyles';
import { MyStyles } from '@app/styles/themes/myStyles/myStyles';

type Props = {
  data: ResponseDashboard;
};

const PercentRating: React.FC<Props> = ({ data }) => {
  const [rating, setRating] = useState<number[]>([134, 196, 353, 1213, data?.results[data?.results?.length - 1]?.king]);
  const [statistic, setStatistic] = useState<number[] | undefined>(undefined);
  const [all, setAll] = useState(0);
  const [max, setMax] = useState(0);

  // const [valueMax, setValueMax] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (data && data?.results?.length > 1) {
      setAll(data.results.length);
      setMax(data?.results[data?.results?.length - 1]?.king);
      // setRating(calculateRating(max));
      setStatistic(calculateStatistic(rating, data));
    }
  }, [data]);

  return (
    <>
      {rating && statistic && (
        <S.Wrapper>
          <S.Title>Срез комплексной оценки среди конкурентов</S.Title>
          <S.Container>
            <S.Item backgroundColor={MyStyles.indicators.colors.red} fontWeight={700}>
              0 - {rating[0]}
            </S.Item>
            <S.Item backgroundColor={MyStyles.indicators.colors.lightYellow} fontWeight={700}>
              {rating[0] + 1} - {rating[1]}
            </S.Item>
            <S.Item backgroundColor={MyStyles.indicators.colors.yellow} fontWeight={700}>
              {rating[1] + 1} - {rating[2]}
            </S.Item>
            <S.Item backgroundColor={MyStyles.indicators.colors.lightGreen} fontWeight={700}>
              {rating[2] + 1} - {rating[3]}
            </S.Item>
            <S.Item backgroundColor={MyStyles.indicators.colors.green} fontWeight={700}>
              {rating[3] + 1} - {rating[3] < max ? rating[4] : 1500}
            </S.Item>

            <S.Item backgroundColor={MyStyles.indicators.colors.red}>{statistic[0]}</S.Item>
            <S.Item backgroundColor={MyStyles.indicators.colors.lightYellow}>{statistic[1]}</S.Item>
            <S.Item backgroundColor={MyStyles.indicators.colors.yellow}>{statistic[2]}</S.Item>
            <S.Item backgroundColor={MyStyles.indicators.colors.lightGreen}>{statistic[3]}</S.Item>
            <S.Item backgroundColor={MyStyles.indicators.colors.green}>{statistic[4]}</S.Item>

            <S.Item backgroundColor={MyStyles.indicators.colors.red}>{((statistic[0] / all) * 100).toFixed(1)}%</S.Item>
            <S.Item backgroundColor={MyStyles.indicators.colors.lightYellow}>
              {((statistic[1] / all) * 100).toFixed(1)}%
            </S.Item>
            <S.Item backgroundColor={MyStyles.indicators.colors.yellow}>
              {((statistic[2] / all) * 100).toFixed(1)}%
            </S.Item>
            <S.Item backgroundColor={MyStyles.indicators.colors.lightGreen}>
              {((statistic[3] / all) * 100).toFixed(1)}%
            </S.Item>
            <S.Item backgroundColor={MyStyles.indicators.colors.green}>
              {((statistic[4] / all) * 100).toFixed(1)}%
            </S.Item>
          </S.Container>
        </S.Wrapper>
      )}
    </>
  );
};

export default PercentRating;

// const calculateRating = (max: number): number[] => {
//   if (max > 1010) {
//     return [131, 190, 300, 1000, max];
//   }
//
//   const step = max / 5;
//   const ratings = [];
//
//   for (let i = 0; i < 5; i++) {
//     console.log(Number(((i + 1) * step).toFixed()));
//     ratings.push(Number(((i + 1) * step).toFixed()));
//   }
//   console.log(ratings);
//   return ratings;
// };

const calculateStatistic = (rating: number[], data: ResponseDashboard): number[] => {
  let one = 0;
  let two = 0;
  let three = 0;
  let four = 0;
  let five = 0;

  data.results.forEach((item) => {
    switch (true) {
      case item.king >= 0 && item.king <= rating[0]:
        one++;
        break;
      case item.king >= rating[0] + 1 && item.king <= rating[1]:
        two++;
        break;
      case item.king >= rating[1] + 1 && item.king <= rating[2]:
        three++;
        break;
      case item.king >= rating[2] + 1 && item.king <= rating[3]:
        four++;
        break;
      case item.king >= rating[3] + 1 && item.king <= rating[4]:
        five++;
        break;
      default:
        break;
    }
  });

  return [one, two, three, four, five];
};
