import React from 'react';
import styled from 'styled-components';

const LegendMap: React.FC = () => {
  return (
    <Container>
      <Title>Описание интерактивной карты Беларуси</Title>
      <Paragraph>
        <Text>
          Интерактивная карта Беларуси предоставляет уникальную возможность получить актуальную информацию о количестве
          действующих юридических лиц и индивидуальных предпринимателей в каждой области, районе и городе.
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          <Bold>Как использовать карту: </Bold>
          Просто наведите курсор на интересующую область, район или город, и всплывающее окно покажет актуальные данные
          о числе зарегистрированных юридических лиц и индивидуальных предпринимателях в выбранной локации.
        </Text>
      </Paragraph>
      <Title style={{ marginTop: 10 }}>Функции карты:</Title>
      <Paragraph>
        <Text>
          <Bold>- Наведение на область, район или город:</Bold> Получение информации о количестве действующих
          юридических лиц и индивидуальных предпринимателей.
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          <Bold>- Интерактивное взаимодействие:</Bold> Удобный интерфейс позволяет быстро и легко находить интересующую
          информацию.
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          <Bold>- Фильтрация по времени:</Bold>Анализируйте изменения в деловой активности, выбирая данные за различные
          периоды.
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          <Bold>- Переход на аналитический дашборд:</Bold> При нажатии на область, район или город, вас перебросит на
          аналитический дашборд с установленными параметрами.
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          <Bold>- Сравнительный анализ:</Bold> Сравнивайте данные между разными регионами, чтобы выявить тренды и
          тенденции в развитии бизнеса. Эта карта станет незаменимым инструментом для анализа деловой активности по
          регионам, помогая глубже понять распределение бизнеса по Беларуси.
        </Text>
      </Paragraph>
      <Paragraph>
        <Underline>
          Используйте ее для стратегического планирования, исследования рыночных возможностей или просто для расширения
          ваших знаний о бизнесе в стране
        </Underline>
      </Paragraph>
    </Container>
  );
};

export default LegendMap;

const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1500px) {
    width: 30%;
  }

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: #000;
`;

const Text = styled.div`
  font-size: 16px;
`;

const Underline = styled(Text)`
  text-decoration: underline;
`;

const Bold = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

const Paragraph = styled.div`
  margin-top: 10px;
`;
