import React, { ChangeEvent, FormEvent, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

import { MyStyles } from '@app/styles/themes/myStyles/myStyles';
import { DatePicker } from 'antd';
import locale from '../../dashboard/components/SearchFilters/components/DateFilter/locale/ru_RU';
import { formatDate } from '@app/utils/utils';
import moment from 'moment';
import { notificationController } from '@app/controllers/notificationController';

const { RangePicker } = DatePicker;

type Props = {
  onHandleSubmit: (query: string, fromDate: string, toDate: string) => void;
};

const NewsSearch: React.FC<Props> = ({ onHandleSubmit }) => {
  const [query, setQuery] = useState('');
  const [fromDate, setFromDate] = useState(`${new Date().getFullYear()}-01-01`);
  const [toDate, setToDate] = useState(`${formatDate(new Date().toDateString(), true)?.replaceAll('.', '-')}`);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (query.trim().length > 0 && !fromDate && !toDate) {
      notificationController.info({ message: 'Введите дату начала и окончания публикации' });
    } else if (query.length > 0 && query.length < 4) {
      notificationController.info({ message: 'Введите ключевые слова для поиска новости' });
    } else {
      onHandleSubmit(query.trim(), fromDate, toDate);
    }
  };

  const handleDatePicker = (dates: any, dateStrings: [string, string]) => {
    setFromDate(dateStrings[0]);
    setToDate(dateStrings[1]);
  };

  const onResetFilter = () => {
    setQuery('');
    setFromDate(`${new Date().getFullYear()}-01-01`);
    setToDate(`${formatDate(new Date().toDateString(), true)?.replaceAll('.', '-')}`);
    onHandleSubmit(query, fromDate, toDate);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <LeftColumn>
          <StyledInput
            placeholder={'Введите ключевые слова для поиска новостей'}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
            value={query}
          />
          <StyledRangePicker
            value={!!fromDate.length && !!toDate.length ? [moment(fromDate), moment(toDate)] : null}
            locale={locale}
            onChange={handleDatePicker}
          />
        </LeftColumn>
        <RightColumn>
          <ButtonSearch type={'submit'}>Поиск</ButtonSearch>
          <ButtonReset onClick={onResetFilter}>Сброс</ButtonReset>
        </RightColumn>
      </Form>
    </Container>
  );
};

export default NewsSearch;

const Container = styled.div`
  max-width: ${MyStyles.width.container};
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  padding: 20px 0;
`;

const Form = styled.form`
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledInput = styled(Input)`
  height: 30px;

  &::placeholder {
    color: ${MyStyles.colors.placeholder};
  }
`;

const StyledRangePicker = styled(RangePicker)`
  width: 100%;
  height: 30px;

  &::placeholder {
    color: ${MyStyles.colors.placeholder};
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonSearch = styled.button`
  height: 28px;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${MyStyles.primaryColor};
  border: 1px solid ${MyStyles.colors.placeholder};
  border-radius: 8px;
  color: #fff;

  &:hover {
    background-color: ${MyStyles.focusColor};
    color: #000;
  }
`;

const ButtonReset = styled.button`
  height: 28px;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid ${MyStyles.primaryColor};
  border-radius: 8px;
  color: ${MyStyles.primaryColor};

  &:hover {
    background-color: ${MyStyles.focusColor};
  }
`;
