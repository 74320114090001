import styled from 'styled-components';
import { MyStyles } from '@app/styles/themes/myStyles/myStyles';

export const Container = styled.div`
  position: relative;
  width: 50%;

  @media (max-width: 1500px) {
    width: 70%;
  }

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const MapBelarusContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media (max-width: 420px) {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: 420px) {
    max-width: 400px;
  }

  @media (max-width: 400px) {
    max-width: 340px;
  }

  @media (max-width: 370px) {
    max-width: 200px;
  }
`;

export const InfoContainer = styled.div`
  padding: 5px 15px 20px 15px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${MyStyles.primaryColor};
  border-radius: 20px;

  @media (max-width: 420px) {
    max-width: 100%;
  }
`;

export const InfoTitle = styled.h1`
  margin: 0 auto;
  font-size: 24px;
  font-weight: 800;
  color: transparent;
  background-image: linear-gradient(to right, #74a6ff, #2775ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  @media (max-width: 420px) {
    font-size: 20px;
  }
`;

export const SubjectContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 420px) {
    justify-content: space-between;
  }
`;

export const FormEntityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InfoContent = styled.div`
  font-size: 40px;
  color: #000;

  @media (max-width: 420px) {
    font-size: 30px;
  }
`;

export const EntityTitle = styled.h3`
  margin: 0 auto;
  font-weight: 800;
  color: transparent;
  background-image: linear-gradient(to right, #74a6ff, #2775ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 420px) {
    font-size: 14px;
  }
`;
