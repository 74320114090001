import React from 'react';
import styled from 'styled-components';

const NumberInputWrapper = styled.div`
  position: relative;
  width: 40px;
`;

const StyledNumberInput = styled.input`
  width: 40px;
  height: 25px;
  background-color: #fff;
  display: flex;
  text-align: center;
  -moz-appearance: textfield;
  outline: none;
  border: 1px solid #575454;
  border-radius: 8px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const ArrowUp = styled.div`
  position: absolute;
  right: -16px;
  top: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 16px;

  &::after {
    content: '▲';
    font-size: 10px;
  }
`;

const ArrowDown = styled.div`
  position: absolute;
  right: -16px;
  bottom: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 16px;

  &::after {
    content: '▼';
    font-size: 10px;
  }
`;

export enum InputNumberType {
  USER,
  MONTH,
}

type NumberInputProps = {
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  type: InputNumberType;
};

const NumberInput: React.FC<NumberInputProps> = ({ value, onChange, min, max, type }) => {
  const handleIncrement = (type: InputNumberType) => {
    if (Number.isNaN(value)) {
      onChange(1);
      return;
    }
    switch (type) {
      case InputNumberType.USER: {
        if (value < 150) {
          onChange(value + 1);
        }
        break;
      }
      case InputNumberType.MONTH: {
        if (value < 12) {
          onChange(value + 1);
        }
        break;
      }
    }
  };

  const handleDecrement = () => {
    if (Number.isNaN(value)) {
      onChange(1);
      return;
    }
    if (value < 2) {
      onChange(1);
    } else {
      onChange(value - 1);
    }
  };

  const handleOnChange = (count: number, type: InputNumberType) => {
    if (count === 0) onChange(0);
    switch (type) {
      case InputNumberType.USER: {
        if (count > 150) {
          onChange(150);
        } else if (count < 1) {
          onChange(1);
        } else {
          onChange(count);
        }
        break;
      }
      case InputNumberType.MONTH: {
        if (count > 12) {
          onChange(12);
        } else if (count < 1) {
          onChange(1);
        } else {
          onChange(count);
        }
        break;
      }
    }
  };

  return (
    <NumberInputWrapper>
      <StyledNumberInput
        type="number"
        min={min}
        max={max}
        value={value}
        onChange={(e) => handleOnChange(parseInt(e.target.value), type)}
      />
      <ArrowUp onClick={() => handleIncrement(type)} />
      <ArrowDown onClick={handleDecrement} />
    </NumberInputWrapper>
  );
};

export default NumberInput;
